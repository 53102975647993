import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core';
import { iRealityCapture } from '../models/port.class';
// use gloabls 
declare var window: any;
declare var Potree: any;
declare var $: any;
declare var viewer: any;

@Component({
  selector: 'app-potree',
  templateUrl: './potree.component.html',
  styleUrls: ['./potree.component.css']
})
export class PotreeComponent implements OnInit{
  assetId: string
  
  realityCapture: iRealityCapture
  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private router:Router) {
    // Enabling Css for Potree.
    document.getElementById('css-potree').removeAttribute("disabled");
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
   }

  ngOnInit() {
    this.assetId = this.activatedRoute.queryParams["_value"].asset_id
    this.getRealityCaptureData();
    console.log('ChildComponent:OnInit');
  }
 

  getRealityCaptureData = () => {
    this.apiService._get(`get-reality-capture-id/${this.assetId}`).subscribe(response => {
      this.realityCapture =  response.data;
      // load potree viewer
      this.initPotree();
    }, error => {
      // error log
      console.error(error)
    })
  }
  // potree function for load the potree viewer.
  initPotree = () => {
    window.viewer = new Potree.Viewer(document.getElementById("potree_render_area"));

    viewer.setEDLEnabled(true);
    viewer.setFOV(60);
    viewer.setPointBudget(1 * 1000 * 10000);
    document.title = "";
    viewer.setEDLEnabled(false);
    viewer.setBackground("gradient"); // ["skybox", "gradient", "black", "white"];
    viewer.setDescription(``);
    viewer.loadSettingsFromURL();
    viewer.loadGUI(() => {
      viewer.setLanguage('en');
      $("#menu_appearance").next().show();
      $("#menu_tools").next().show();
      $("#menu_scene").next().show();
      viewer.toggleSidebar();
    });
    // potree point cloud id
    Potree.loadPointCloud(this.realityCapture.url, "index", e => {
      let pointcloud = e.pointcloud;
      let material = pointcloud.material;
      viewer.scene.addPointCloud(pointcloud);
      material.pointColorType = Potree.PointColorType.RGB; // any Potree.PointColorType.XXXX 
      material.size = 1;
      material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
      material.shape = Potree.PointShape.SQUARE;
      viewer.fitToScreen();
    });

  }


}
