import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as DownloadFile from "downloadjs";

import { } from "googlemaps";
import OlMap from "ol/Map";
import OlXYZ from "ol/source/XYZ";
import OlTileLayer from "ol/layer/Tile";
import OlLayerVector from "ol/layer/Vector";
import OlLayerVectorTile from "ol/layer/VectorTile";

import Overlay from "ol/Overlay";

import MVT from "ol/format/MVT";
import * as OlTitleGrid from "ol/tilegrid";
// import * as from "ol";

import { Group as LayerGroup } from "ol/layer";
import OlOSM from "ol/source/OSM";
import OlVector from "ol/source/Vector";
import OlSourceVectorTile from "ol/source/VectorTile";
import OlView from "ol/View";
import * as OlProj from "ol/proj";
import * as Interaction from "ol/interaction";
import * as ol from "ol";
import * as OlGeom from "ol/geom";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { iMeasurement, iAnnotations } from "../models/asset.class";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import { boundingExtent } from "ol/extent";

import { createRegularPolygon, createBox } from 'ol/interaction/Draw';


import { Style, Fill, Stroke, Text, Circle } from "ol/style";

// Service
import { ToastrService } from "ngx-toastr";
import {
  ApiService,
  InternalCommService,
  UserService,
  LocationTrackingService
} from "src/app/core";
import * as service from "src/assets/json/api_service.json";

// datepicker from NGB
import {
  NgbDateStruct,
  NgbCalendar,
  NgbDate
} from "@ng-bootstrap/ng-bootstrap";

// Html to image
import htmlToImage from "dom-to-image-more";
import { userConfig } from "../models/userConfig";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { async } from "@angular/core/testing";
import { throwError } from 'rxjs';
import { DefectListComponent } from '../bottom-bar/bottom-bar-layout/defect-list/defect-list.component';
import { ViewChild } from '@angular/core';
import { DefectWizardComponent } from '../right-sidebar/defect-wizard/defect-wizard.component';
import { OverallSiteViewComponent } from '../left-sidebar/overall-site-view/overall-site-view.component';
import { RecentAssessmentComponent } from '../left-sidebar/recent-assessment/recent-assessment.component';
import { ApprovedAssessmentComponent } from '../left-sidebar/approved-assessment/approved-assessment.component';

// vector layer color script
declare const alphaColors: any;
declare var $: any;
interface filterData {
  id: string;
  name: string;
  year: number;
}

@Component({
  selector: "app-asset",
  templateUrl: "./asset.component.html",
  styleUrls: ["./asset.component.css"]
})
export class AssetComponent implements OnInit {
  // output event emitter to refresh defect wizard form
  @Output() refershForm = new EventEmitter<boolean>();
  @ViewChild(DefectListComponent, null) defectListComp: DefectListComponent;
  @ViewChild(DefectWizardComponent, null) defectWizardComp: DefectWizardComponent;
  @ViewChild(OverallSiteViewComponent, null) overallViewComp: OverallSiteViewComponent;
  @ViewChild(RecentAssessmentComponent, null) recentAssessmentComp: RecentAssessmentComponent
  @ViewChild(ApprovedAssessmentComponent, null) approvedAssessmentComp: ApprovedAssessmentComponent

  // boolean to show asset list
  reportList: Boolean = false;
  screenShot: Boolean = false;
  measurementList: Boolean = false;
  measurementAdd: Boolean = false;
  measurementEdit: Boolean = false;
  annotationList: Boolean = false;
  annotationAdd: Boolean = false;
  annotationEdit: Boolean = false;

  //filterdata
  dataToBePassedToFilter: any = {};

  // boolean to show /hide info
  info: Boolean = false;

  // boolean to move right action button
  moveRightActionBtn: Boolean = false;

  // window loader
  windowloader: Object = {
    name: "",
    loader: false
  };

  // Datepicker -- structure
  ngbDateStruct: NgbDateStruct;
  date: { year: number; month: number };

  // datepicker -- Enable Dates
  isEnabled: any;

  // Datepicker -- dates to enable
  enableDates: NgbDateStruct[] = [];

  //asset Details
  assetDetails: any;
  // Measurement form -- Declare
  measurementForm: FormGroup;
  measurementFormSubmit: Boolean = false;

  // Annotation form -- Declare
  annotationForm: FormGroup;
  annotationFormSubmit: Boolean = false;

  map: OlMap;
  sourceXYZ: OlXYZ;
  layer: OlTileLayer;
  view: OlView;
  measurements: iMeasurement[];
  annotations: iAnnotations[];
  draw: any;
  sourceOne: any;
  asset: string = this.activatedRoute.queryParams["_value"].asset_id;
  portname: string = this.activatedRoute.queryParams["_value"].port_name;
  portid: string = this.activatedRoute.queryParams["_value"].port_id;

  // drawing type
  drawingType: string = null;
  drawInteraction: any;
  escapeKeyUsage: boolean = false;

  // measurement properties
  justDrewMeasurement: any = {};
  measurementLayerGroup: any;
  annotationLayerGroup: any;
  defectLayerGroup: any;
  overlayLayerGroup: any;
  overlayLayerGroupArray: any[] = [];

  //calendar variable

  dates: any = {};
  dateReportList: any[] = [];

  // calender open modal
  calenderheader = "";

  // User Configa

  USER_CONFIG: userConfig;

  //measurement and annotation view
  annotationShowAll: boolean = true;
  measurementShowAll: boolean = true;

  // overlay data
  overlay: Array<any> = [];

  // vector layer object array
  _vlayer: Array<Object> = [];

  // vlayer index postion
  _vlayerIndex: Array<Object> = [];

  // vlayer map
  _pMapLayer: Array<any> = [];
  _vMapLayer: Array<any> = [];

  // Prepare style list
  layerStyleMap: any = {};
  layerStyleVisibility: any = {};

  // filter types & Years
  _vlayerFilterTypes: Array<any> = [];
  _vlayerFilterYears: Array<any> = [];

  // displayed vector layers
  _displayedVLayers: Array<object> = [];

  // overlay Opacity

  overlayOpacity: number = 1;

  // detect small screen
  smallScreen: Boolean = false;
  showDefectList: Boolean = true;
  showDefectWizard: Boolean = false;


  // right layout status
  rightlayoutState: any

  // ************************ layout *******************************************
  showLeftSideBar: Boolean = true;
  showRightSideBar: Boolean = false;
  showBottomBar: Boolean = false;

  coordinate: Array<any> = [];

  //recent Assessment
  showRecentAssessment: Boolean = false;

  //Approved Assessment
  showApprovedAssessment: Boolean = false;

  //reportsummary url
  reportSummaryUrl = `report-summary?${this.asset}`


  //
  reportUrl = `get-report-by-user/${this.asset}`

  //recentAssessment Url
  recentAssessmentUrl = `get-inspections-by-asset/${this.asset}`


  // generate report

  selectedFile: File;
  selectedFileUrl;
  executiveSummary;
  fileLabel: string;
  activeParams;
  enableUpload = false;
  testVar: any;



  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private toastr: ToastrService,
    private userService: UserService,
    private locationTrackingService: LocationTrackingService,
    // private interCommService: InternalCommService,
    public http: HttpClient,
    private router: Router
  ) {
    //get asset id
    this.asset = this.activatedRoute.queryParams["_value"].asset_id;
    this.portname = this.activatedRoute.queryParams["_value"].port_name;
    this.portid = this.activatedRoute.queryParams["_value"].port_id;

    let _userConfigModal = this.userService.getSession();
    this.USER_CONFIG = _userConfigModal["userConfig"];

    // disable Potree css for Laptop OL  Map problem
    // document.getElementById("css-potree").setAttribute("disabled", "disabled");
    // create measurement form
    this.measurementForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      comment: new FormControl(null, [Validators.required]),
      distance: new FormControl(null, [Validators.required]),
      measurement_coordinates: new FormControl(null, [Validators.required]),
      id: new FormControl(null),
      asset: new FormControl(this.asset, [Validators.required])
    });

    // create annotation form
    this.annotationForm = new FormGroup({
      label_name: new FormControl(null, [Validators.required]),
      id: new FormControl(null),
      coordinates: new FormControl(null, [Validators.required]),
      asset: new FormControl(this.asset, [Validators.required])
    });

    this.router.events.subscribe(async e => {
      if (e instanceof NavigationEnd) {
        let url = decodeURI(e.url);
        let qp = url.split("?")[1];
        if (qp) {
          this.activeParams = this.convQP2Obj(qp);
          let event = {
            "name": this.activeParams['inspectionName'],
            "id": this.activeParams['inspectionId']
          }
          if (this.activeParams['showInspection'] == 'show') this.openInspectionData(event)
        }
      }
    })
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.map) {
      setTimeout(() => {
        this.map.updateSize()
      }, 200);
    }
  }

  async ngOnInit() {

    let event = {
      "name": this.activatedRoute.queryParams["_value"].inspectionName,
      "id": this.activatedRoute.queryParams["_value"].inspectionId
    }
    if (this.activatedRoute.queryParams["_value"].showInspection == 'show') this.openInspectionData(event)

    await this.getAssetDetails();

    //populate calendar
    this.populateCalendar();

    //get measurements from database
    this.layerVisibility(false, false);



    this.locationTrackingService.assetData.subscribe(response => {
      console.log("reload asset", response);
      if (response["action"] == "reloadAsset") {
        console.log("reload asset");
        this.setAssetToMapContainer();
      }

      this.locationTrackingService.clearContainerKey();
    });

  }

  setMousePointer = async () => {
    console.log("setup mouse pointer starts here", this.map);

    await this.map.on("pointermove", async e => {
      let coord = e.coordinate;

      const latLng = OlProj.transform(coord, "EPSG:3857", "EPSG:4326");

      this.coordinate = latLng;
      // console.log('ssa', this.assetDetails)

      // await this.locationTrackingService.setCoordinate(latLng);
      // //console.log(OlProj.transform(coord, 'EPSG:3857', 'EPSG:4326'))
    });
  };

  setAssetToMapContainer() {
    this.locationTrackingService.setAssetData(this.assetDetails);
  }

  loadMapTiler = async () => {
    try {
      console.log("refreshloadmap tiler", this.assetDetails);
      this.setAssetToMapContainer();
      var mapExtent = await OlProj.transformExtent(
        this.assetDetails.maptiler_coordinates,
        "EPSG:4326",
        "EPSG:3857"
      );
      //console.log("asset details 1", this.assetDetails)
      // var mapMinZoom = this.map;
      // var mapMaxZoom = 23;

      //overlay setup
      var source = await new OlXYZ({
        url: `${this.assetDetails.maptiler_url}{z}/{x}/{y}.png`,
        // url: this.assetDetails.maptiler_url,
        tilePixelRatio: 1.0,
        minZoom: this.assetDetails.maptiler_min_zoom,
        maxZoom: this.assetDetails.maptiler_max_zoom,
        crossOrigin: "Anonymous"
      });

      //instance for source vector
      this.sourceOne = await new OlVector({
        wrapX: false
      });

      //instance for layer vector
      var vectorLayer = await new OlLayerVector({
        source: this.sourceOne
      });

      // instance for tile layer, to be included in map
      var layer = await new OlTileLayer({
        extent: mapExtent,
        source: source,
        crossOrigin: "Anonymous"
      });

      // layer for Openstreetmap
      var osm = await new OlTileLayer({
        source: new OlOSM()
      });

      this._pMapLayer = [
        { id: "Ortho_layer", name: "Orthomosaic", index: 1, show: true }
      ];
      //console.log('ortho', this.map)

      //creating instance for map, and add all vector layer and source layer
      this.map = await new OlMap({
        target: "map",
        layers: [osm, layer, vectorLayer],
        view: new OlView({
          //  map center coordinates
          center: OlProj.fromLonLat(this.assetDetails.maptiler_lat_lng),
          zoom: this.assetDetails.default_zoom
        })
      });
    } catch (error) {
      ////console.log("404 on maptiles")
    }

    // this.interCommService.defectList.subscribe(response => {
    //   this.removeLayers("defect");
    //   this.removeDrawnLayer("Polygon");
    //   if (response["defectList"]) this.drawDefect(response["defectList"]);
    // });
    console.log("this.map", this.map);
    this.testVar = this.map;
  };

  getAssetDetails = async () => {
    this.apiService._get(`get-asset-id/${this.asset}`).subscribe(
      async response => {
        this.assetDetails = response.asset;
        // this.interCommService.setData("assetData", this.assetDetails);
        await this.loadMapTiler();
        await this.populateCalendar();

        //get measurements from database
        await this.layerVisibility(true, true);

        // await this.initVLayer(this.assetDetails.overlay)
        await this.drawOverLay(this.assetDetails.overlay);
        this.setVisibilityOrtho(true);
        // this.assetDetails = response['asset'];

        await this.setMousePointer();
      },
      error => {
        console.error(error);
      }
    );
  };

  async checkExistingLayerAndRemoveIt() {
    //console.log("checkExistingLayerAndRemoveIt", this.map)
    const mapLayers = this.map.getLayers().getArray();
    if (mapLayers) {
      for (const layer of mapLayers) {
        if (layer && layer.get("title") == "overlay") {
          this.map.getLayers().remove(layer);
        }
      }
    }
  }

  async drawOverLay(data) {
    this.checkExistingLayerAndRemoveIt();

    const createFilterData = [];

    for (const obj of data) {
      let vlayer = obj.vlayer;
      if (vlayer && vlayer.type && vlayer.type == "DSM") {
        let filterObj: filterData = {
          id: null,
          name: obj.name,
          year: obj.year
        };
        createFilterData.push(filterObj);
        let layer = await this.createLayer(obj);
        layer.set("name", `${obj.name}-${obj.year}`);
        this.overlayLayerGroupArray.push(layer);
      } else {
        // //console.log("layer obj", obj)
        let filterObj: filterData = {
          id: obj.vlayer.id,
          name: obj.name,
          year: obj.year
        };
        createFilterData.push(filterObj);

        var colors = alphaColors(obj.vlayer.id);
        var style = {
          Polygon: new Style({
            fill: new Fill({
              color: colors.polygon
            }),
            stroke: new Stroke({
              color: colors.polygonOutline
            })
          }),
          LineString: new Style({
            stroke: new Stroke({
              color: colors.line
            })
          }),
          Point: new Style({
            image: new Circle({
              fill: new Fill({
                color: colors.circle
              }),
              radius: 2
            })
          })
        };
        style["MultiPolygon"] = style["Polygon"];
        style["MultiLineString"] = style["LineString"];
        style["MultiPoint"] = style["Point"];

        // save style with layer id
        this.layerStyleMap[obj.vlayer.id] = style;
        this.layerStyleVisibility[obj.vlayer.id] = true;

        // create layer
        let newLayer = await this.createVLayer(
          obj,
          this.overlayLayerGroupArray.length
        );
        newLayer["layer"].set("name", `${obj.name}-${obj.year}`);
        this.overlayLayerGroupArray.push(newLayer["layer"]);
        //console.log("create new layer", newLayer)
      }
    }
    //console.log("overlay filter", createFilterData);
    await this.createDataToBeSentToFilter(createFilterData);

    this.overlayLayerGroup = new LayerGroup({
      layers: this.overlayLayerGroupArray,
      title: "overlay"
    });

    this.map.addLayer(this.overlayLayerGroup);
    this.setVisibilityOrtho(false);
    this.setVisibleLayerGroupOverlay(["all"], false);

    //console.log("overlayLayerGroup", this.map)

    // await this.checkExistingLayerAndRemoveIt()
    // this.setVisibility(null)
  }

  async createDataToBeSentToFilter(data) {
    let simpleObjectForFilterData = {};
    for (const obj of data) {
      let color = "";
      // if (obj.id) //console.log("color to be sent", this.layerStyleMap[obj.id]['LineString'])
      if (obj.id)
        color = this.layerStyleMap[obj.id]["LineString"]["stroke_"]["color_"];
      if (!simpleObjectForFilterData[obj.name]) {
        simpleObjectForFilterData[obj.name] = [
          {
            id: obj.id,
            year: obj.year,
            color: color
          }
        ];
      } else {
        simpleObjectForFilterData[obj.name].push({
          id: obj.id,
          year: obj.year,
          color: color
        });
      }
    }
    simpleObjectForFilterData["Orthomosaic"] = [
      { id: null, year: null, color: "" }
    ];
    console.log("createdatatobesent", simpleObjectForFilterData);
    // this.interCommService.setFilterData(simpleObjectForFilterData);
  }

  setVisibleLayerGroupOverlay(data, status) {
    //console.log("remove VisibilityAll ", this.map)
    if (this.map) {
      let layers = this.map.getLayers().getArray();
      for (const layer of layers) {
        if (layer.values_.title === "overlay") {
          //console.log("layer group to remove", layer)
          const vLayer = layer.getLayersArray();
          for (const layer of vLayer) {
            //console.log("layer to remove", layer)
            if (!data) return;
            if (data[0] == "all") {
              layer.setVisible(status);
            } else if (data.includes(layer.get("name"))) {
              layer.setVisible(status);
            }
          }
        }
      }
    }
  }

  setVisibilityOrtho(status) {
    if (this.map) {
      let layers = this.map.getLayers().getArray();
      layers[1].setVisible(status);
    }
  }

  setVisibility(filterArray) {
    console.log("inside", filterArray);
    this.setVisibilityOrtho(false);
    this.setVisibleLayerGroupOverlay(["all"], false);
    this.setVisibleLayerGroupOverlay(filterArray, true);
    console.log("ortho layer", filterArray);
    if (filterArray && filterArray.includes("Orthomosaic")) {
      console.log("array true", filterArray);
      this.setVisibilityOrtho(true);
    }
  }

  async populateCalendar() {
    this.apiService
      ._get(`${service["GET_CALENDAR_BY_ASSET"]}/${this.asset}`)
      .subscribe(response => {
        // //console.log(response)

        if (response.status) {
          const data = response.data;
          data.forEach(dateObj => {
            // { year: 2019, month: 12, day: 10
            let date = dateObj.date.split("-");
            let calendarObj = {
              year: parseInt(date[0]),
              month: parseInt(date[1]),
              day: parseInt(date[2])
            };
            //push dates for calendar update
            this.enableDates.push(calendarObj);

            this.dates[dateObj.date] = dateObj.reports;
          });
          this.isEnabled = (
            date: NgbDateStruct,
            current: { month: number; year: number }
          ) => {
            return this.enableDates.find(x => NgbDate.from(x).equals(date))
              ? false
              : true;
          };
        } else {
          this.toastr.warning("Calendar fetch failed");
        }
      });
  }

  removeDefect() { }

  addDefect(event) {
    // this.removeLayers('defect')
    this.defectWizardComp.defectCount = this.defectListComp.defectList.length + 1;
    this.updateDrawingTypeInteraction("defect");
  }

  defectDeleted(event) {
    this.defectWizardComp.defectCount = this.defectListComp.defectList.length + 1;
    this.defectWizardComp.defectForm.reset()
    this.defectWizardComp.toggleFormControl();
  }

  undoDefect() {
    console.log(this.map)
    const source = this.map.getLayers().getArray()[2].getSource()
    const features = source.getFeatures()
    console.log("source", source);
    console.log("source", features);


    if (features.length > 0) {
      source.removeFeature(features[features.length - 1])
    }
  }

  addEscapeListener() { }

  removeEscapeListener() {
    document.onkeydown = function (evt) {
      //console.log(evt)
    };
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    //console.log("keypress escape")
    if (this.escapeKeyUsage && this.drawInteraction) {
      //console.log("keypress escape", this.drawInteraction)
      this.drawInteraction.finishDrawing();
    }
  }

  // Method to enable the drawing
  addInteraction() {
    var that = this;
    // Line string for measurement
    // Polygon for defect marking
    // Point for annotation

    if (this.drawingType == 'Box') {
      // let geometryFunction = createBox()
      this.drawInteraction = new Interaction.Draw({
        source: this.sourceOne,
        type: 'Circle',
        geometryFunction: createBox()
      })
    } else {

      this.drawInteraction = new Interaction.Draw({
        source: this.sourceOne,
        type: this.drawingType
      });
    }


    this.drawInteraction.on("drawstart", e => {
      //console.log("draw start", e)
      if (e.feature.geometryChangeKey_.target.getType() == "Polygon") {
        e.feature.setStyle(this.styleFunctionPolygon(""));
      }
    });

    this.map.addInteraction(this.drawInteraction);
    this.escapeKeyUsage = true;

    // Once the drawing gets over
    this.drawInteraction.on("drawend", async e => {
      await that.drawendMethod(e);
    });
  }

  // provided the coordinates, it will take the argument required for calculating the distance and call the method getDistanceFromLatLonInKm
  calculateDistance(coordinates) {
    let beg = 0;
    let distance = [];
    let length = coordinates.length;
    for (let i = 0; i < length - 1; i++) {
      let start = coordinates[beg];
      let end = coordinates[beg + 1];
      let distanceKm = this.getDistanceFromLatLonInKm(
        start[0],
        start[1],
        end[0],
        end[1]
      );
      distance.push(distanceKm * 1000 * 3.281);
      beg += 1;
    }
    // add all the distance value to get the distance for the whole line
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return distance.reduce(reducer);
  }

  // Provided the lat long of two points, it gives the distance between them in km
  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1); // this.deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
      Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // called when the draw ends
  async drawendMethod(e) {
    this.escapeKeyUsage = false;
    var coordinates = e.target.sketchCoords_;
    var drawCoordinates = [];
    // gets the sketch coordinate and convert it to lat and lng

    if (e.target.type_ == "Point") {
      const lonLat = OlProj.transform(coordinates, "EPSG:3857", "EPSG:4326");
      const alteredCoord = [lonLat[1], lonLat[0]];
      this.justDrewMeasurement = {
        asset: this.asset,
        coordinates: alteredCoord
      };

      // store value to annotation form
      this.annotationForm.patchValue(this.justDrewMeasurement);

      this.justDrewMeasurement = {};
    } else if (e.target.type_ == "LineString") {
      coordinates.forEach(coord => {
        var lonlat = OlProj.transform(coord, "EPSG:3857", "EPSG:4326");
        var lon = lonlat[0];
        var lat = lonlat[1];
        drawCoordinates.push([lat, lon]);
      });
      const distanceCalc = this.calculateDistance(drawCoordinates);
      this.justDrewMeasurement = {
        asset: this.asset,
        distance: distanceCalc,
        measurement_coordinates: drawCoordinates
      };

      // store value to measurement form
      this.measurementForm.patchValue(this.justDrewMeasurement);

      // clear value
      this.justDrewMeasurement = {};
    } else if (e.target.type_ == "Circle") {
      let coord = [];

      const coordinates = e.feature.getGeometry().getCoordinates()

      coordinates[0].forEach(coor => {
        var lonlat = OlProj.transform(coor, "EPSG:3857", "EPSG:4326");
        coord.push([lonlat[1], lonlat[0]]);
      });
      this.justDrewMeasurement = {
        coordinates: coord,
        defect_zoom: this.map.getView().getZoom(),
        defect_center: this.getCenter(coord)
      };
      console.log(" this.justDrewMeasurement", this.justDrewMeasurement);
      // this.interCommService.setData("defectParams", this.justDrewMeasurement);
      this.defectWizardComp.defectForm.patchValue(this.justDrewMeasurement);
      this.defectWizardComp.toggleFormControl();

      // this.interCommService.setData('coordinates', coord);
      // this.interCommService.setData('defect_center', this.getCenter(coord));
      // ////console.log("drawInteracrion", this.drawInteraction)
      // this.drawingTypeUpdate(false, false)
      this.removePolygonInteraction();

      // this.map.removeInteraction(this.drawInteraction)

      // show right and bottom layout
      // this.showRightLayout();
    }
  }

  removePolygonInteraction() {
    //interactions used to be in 9th position on this.map.getInteractions()
    const drwInteraction = this.map.getInteractions().getArray();
    // this.map.removeInteraction(drwInteraction[9])
    ////console.log("draw interaction", drwInteraction)
    const length = drwInteraction.length;
    for (let i = length - 1; i > 8; i--) {
      let interaction = drwInteraction[i];
      // remove the layer from the map
      this.map.removeInteraction(interaction);
    }
  }
  arrayPosShift(array, pos) {
    for (let i = 0; i < pos; i++) {
      const first_element = array[0]
      array.shift()
      array.push(first_element)
    }
    return array
  }


  updateDrawingTypeInteraction(type) {
    if (this.drawInteraction) this.map.removeInteraction(this.drawInteraction);
    if (type == "measurement") {
      this.drawingType = "LineString";
    } else if (type == "annotation") {
      this.drawingType = "Point";
    } else if (type == "defect") {
      this.drawingType = "Box";
    }

    this.addInteraction();
  }

  removeDrawnLayer(type) {
    try {
      //console.log("remove drawnlayer", this.map)
      let sourceVal = this.map
        .getLayers()
        .getArray()[2]
        .getSource();
      if (sourceVal) {
        let features = sourceVal.getFeatures();
        if (features) {
          features.forEach(feature => {
            //console.log("feature", feature.geometryChangeKey_.target.getType())
            if (feature.geometryChangeKey_.target.getType() == type) {
              this.map
                .getLayers()
                .getArray()[2]
                .getSource()
                .removeFeature(feature);
            }
          });
        }
      }
    } catch (error) {
      //console.log("Error when remove drawn layer", error)
    }
  }

  removeLayers(type) {
    // get all the layers on the map
    if (this.map) {
      const layers = this.map.getLayers().getArray();

      const length = layers.length;

      if (type == "measurement" || type == "annotation" || type == "defect") {
        layers.forEach((layer, i) => {
          if (i < 3) {
          } else if (layer.values_ && layer.values_.title == type) {
            this.map.removeLayer(layer);
          }
        });
      }
    }

    // //console.log("removed layers", this.map.getLayers().getArray())
  }

  // get all the measurements stored in database
  async getMeasurements(displayMarkings) {
    const servicen = `${service["GET_MEASUREMENT_BY_ASSET"]}/${this.asset}`;
    this.apiService._get(servicen).subscribe(async response => {
      this.measurements = response.data;
      this.drawMeasurement();
      this.measurementLayerGroup.setVisible(displayMarkings);
    });
  }

  async getAnnotations(displayMarkings) {
    const servicen = `${service["GET_ANNOTATION_BY_ASSET"]}/${this.asset}`;
    this.apiService._get(servicen).subscribe(async response => {
      // this.measurements = response.data
      this.annotations = response.data;
      await this.drawAnnotation();
      this.annotationLayerGroup.setVisible(displayMarkings);
    });
  }

  // usually in OpenStreetMap, it will longitude and latitude
  // method for changing its course
  changeLatLngPosition(latLng) {
    let lngLat = [];
    latLng.forEach(latLng => {
      lngLat.push([latLng[1], latLng[0]]);
    });
    // ////console.log(latLng)
    return lngLat;
  }

  // provided the list of coordinates from database, method converts the whole line with more than one coordinates to two point
  // this is basically for making it look like a single point so that displaying the text will be easier
  splitLineCoordinate(arrayOfCoordinates) {
    let beg = 0;
    let newSplittedArray = [];
    let length = arrayOfCoordinates.length;
    for (let i = 0; i < length - 1; i++) {
      let start = arrayOfCoordinates[beg];
      let end = arrayOfCoordinates[beg + 1];
      newSplittedArray.push([start, end]);
      beg += 1;
    }

    return newSplittedArray;
  }

  // method for drawing measurement on the map, provided the measurement coordinates
  drawMeasurement() {
    let layerArray = [];
    this.measurements.forEach(measurement => {
      let setOfLineCoordinate = this.splitLineCoordinate(
        measurement["measurement_coordinates"]
      );
      setOfLineCoordinate.forEach(coordinate => {
        var linestring = new LineString(this.changeLatLngPosition(coordinate));
        const distanceKm = this.calculateDistance(coordinate);
        console.warn("distanceKm", distanceKm);
        linestring.transform("EPSG:4326", "EPSG:3857");
        var feature = new ol.Feature(linestring);
        feature.setStyle(this.styleFunctionLine(distanceKm.toFixed(2)));
        var vectorSource = new OlVector();
        vectorSource.addFeature(feature);
        var vectorLayer = new OlLayerVector({
          source: vectorSource
        });
        layerArray.push(vectorLayer);
      });
    });

    this.measurementLayerGroup = new LayerGroup({
      layers: layerArray,
      title: "measurement"
    });
    //console.log("map", this.map)

    if (this.map) this.map.addLayer(this.measurementLayerGroup);

    // ////console.log(this.map)
  }

  async drawAnnotation() {
    let layerArray = [];
    this.annotations.map(annotation => {
      var point = new Point(
        OlProj.transform(
          [annotation.coordinates[1], annotation.coordinates[0]],
          "EPSG:4326",
          "EPSG:3857"
        )
      );
      var pointFeature = new ol.Feature(point);
      pointFeature.setStyle(this.styleFunctionPoint(annotation.label_name));

      var vectorSource = new OlVector({
        projection: "EPSG:4326"
      });
      vectorSource.addFeatures([pointFeature]);
      var vectorLayer = new OlLayerVector({
        source: vectorSource
      });
      layerArray.push(vectorLayer);
    });

    this.annotationLayerGroup = new LayerGroup({
      layers: layerArray,
      title: "annotation"
    });
    //console.log("map", this.map)
    if (this.map) this.map.addLayer(this.annotationLayerGroup);
  }

  drawDefect(defectList) {
    this.removeVectorLayer()
    this.removeLayers('defect')

    console.log("drawDefect Metho", defectList);

    let defectLayer = [];

    ////console.log("defect list -------", defectList)
    defectList.map((response, index) => {
      let defectCoordinates = response.coordinates
      console.log("defect list map 1", defectCoordinates);

      defectCoordinates = [...defectCoordinates, defectCoordinates[0]]
      // let defectCoordinates = [[51.39599653505692, -3.256940930059433], [51.39597017707874, -3.2569302012233736], [51.395953441846586, -3.2570113380460746], [51.39597645278923, -3.257018714120865]]
      console.log("defect list map 2", defectCoordinates);

      defectCoordinates.reverse()

      let coords = this.arrayPosShift(defectCoordinates, 2)

      var polygon = new OlGeom.Polygon([
        this.changeLatLngPosition(defectCoordinates)
      ]);
      polygon.transform("EPSG:4326", "EPSG:3857");

      var feature = new ol.Feature(polygon);
      feature.setStyle(this.styleFunctionPolygon((index + 1).toString()))

      // // Create vector source and the feature to it.
      var vectorSource = new OlVector();
      vectorSource.addFeature(feature);

      // // Create vector layer attached to the vector source.
      var vectorLayer = new OlLayerVector({
        source: vectorSource
      });

      // ////console.log(feature)
      // ////console.log(vectorLayer)

      //group defect layer

      defectLayer.push(vectorLayer);

      // // Add the vector layer to the map.

      // // map.removeLayer(vectorLayer)
    });

    this.defectLayerGroup = new LayerGroup({
      layers: defectLayer,
      title: "defect"
    });

    if (this.map) this.map.addLayer(this.defectLayerGroup);
  }

  deleteMeasurement(id) {
    ////console.log("delete this id", id)
    const api = `${service["DELETE_MEASUREMENT"]}/${id}`;
    ////console.log("measurement ", api)
    this.apiService._delete(api).subscribe(response => {
      ////console.log(response)
      this.removeLayers("measurement");
      this.layerVisibility(true, false);
      this.drawingTypeUpdate(true, false);
      if (response.status) this.toastr.success("Deleted Successfully");
    });
  }

  async layerVisibility(measure, annotation) {
    await this.getMeasurements(measure);
    await this.getAnnotations(annotation);
  }

  drawingTypeUpdate(measure, annotation) {
    if (measure) {
      this.drawingType = "LineString";
      this.map.removeInteraction(this.drawInteraction);
      this.addInteraction();
    } else if (annotation) {
      this.drawingType = "Point";
      this.map.removeInteraction(this.drawInteraction);
      this.addInteraction();
    } else this.map.removeInteraction(this.drawInteraction);
  }

  deleteAnnotation(id) {
    // ////console.log("delete this id", id)
    const api = `${service["DELETE_ANNOTATION"]}/${id}`;
    // ////console.log("measurement ", api)
    this.apiService._delete(api).subscribe(response => {
      this.removeLayers("annotation");
      this.layerVisibility(false, true);
      this.drawingTypeUpdate(false, true);
      if (response.status) this.toastr.success("Deleted Successfully");
    });
  }

  async selectDefect(defect) {
    let defectNewCoord = []
    for (const def of defect.coordinates) {
      //console.log("***************", defect)
      defectNewCoord.push(OlProj.transform([def[1], def[0]], "EPSG:4326", "EPSG:3857"))
    }
    // defectNewCoord.push(OlProj.transform([defect.coordinates[0][1], defect.coordinates[0][0]], "EPSG:4326", "EPSG:3857"))
    //console.log("coordinates -- ", coordinates)
    var ext = boundingExtent(defectNewCoord);
    this.map.getView().fit(ext, this.map.getSize(), { duration: 100 });
    this.map.getView().setZoom(this.map.getView().getZoom() - 1)
    this.map.updateSize()
    console.log("map", this.map, OlProj.transform([defect.defect_center[1], defect.defect_center[0]], "EPSG:4326", "EPSG:3857"));

  }

  selectMeasurement(measurement) {
    console.log("measurement", measurement)
    let defectNewCoord = []
    for (const def of measurement.measurement_coordinates) {
      //console.log("***************", defect)
      defectNewCoord.push(OlProj.transform([def[1], def[0]], "EPSG:4326", "EPSG:3857"))
    }
    //console.log("coordinates -- ", coordinates)
    var ext = boundingExtent(defectNewCoord);
    this.map.getView().fit(ext, this.map.getSize(), { duration: 100 });
    this.map.getView().setZoom(this.map.getView().getZoom() - 1)
    // console.log("map", this.map, OlProj.transform([defect.defect_center[1], defect.defect_center[0]], "EPSG:4326", "EPSG:3857"));

    // const center = this.getCenter(measurement.measurement_coordinates);
    // this.map.getView().setCenter(OlProj.fromLonLat([center[1], center[0]]));
    // this.map.getView().setZoom(20);
  }

  selectAnnotation(annotation) {
    // this.map
    //   .getView()
    //   .setCenter(
    //     OlProj.fromLonLat([
    //       annotation.coordinates[1],
    //       annotation.coordinates[0]
    //     ])
    //   );
    // this.map.getView().setZoom(20);
    let defectNewCoord = [OlProj.transform([annotation.coordinates[1], annotation.coordinates[0]], "EPSG:4326", "EPSG:3857")]
    var ext = boundingExtent(defectNewCoord);
    this.map.getView().fit(ext, this.map.getSize(), { duration: 100 });
    this.map.once('rendercomplete', e => {
      this.map.getView().setZoom(this.map.getView().getZoom() - 6)
    })

  }

  getCenter(arr) {
    var x = arr.map(x => x[0]);
    var y = arr.map(x => x[1]);
    var cx = (Math.min(...x) + Math.max(...x)) / 2;
    var cy = (Math.min(...y) + Math.max(...y)) / 2;
    return [cx, cy];
  }

  // Function for styling the feature
  styleFunctionLine(textToDisplay) {
    return [
      new Style({
        fill: new Fill({
          color: "rgba(255,255,255,1)"
        }),
        stroke: new Stroke({
          color: "red",
          width: 4
        }),
        text: new Text({
          font: "18px Calibri,sans-serif",
          fill: new Fill({ color: "#faff00" }),
          stroke: new Stroke({
            color: "#666666",
            width: 2
          }),
          text: `${textToDisplay.toString()} ft`
        })
      })
    ];
  }

  styleFunctionPolygon(textToDisplay) {
    return [
      new Style({
        fill: new Fill({
          color: "rgba(255,255,255,0.2)"
        }),
        stroke: new Stroke({
          color: "red",
          width: 2
        }),
        text: new Text({
          font: "26px Calibri,sans-serif",
          fill: new Fill({ color: "#f1c40f" }),
          stroke: new Stroke({
            color: "#f1c40f",
            width: 1
          }),
          text: textToDisplay,
          textAlign: 'end',
          textBaseline: 'alphabetic',
          offsetX: 0,
          offsetY: 0,
          placement: 'line',

        })
      })
    ];
  }

  styleFunctionPoint(textToDisplay) {
    return [
      new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({ color: "yellow" }),
          stroke: new Stroke({
            color: "yellow",
            width: 3
          })
        }),

        text: new Text({
          font: "15px Calibri,sans-serif",
          textAlign: "left",
          fill: new Fill({ color: "#FFF" }),
          stroke: new Stroke({
            color: "yellow",
            width: 1
          }),
          text: `   ${textToDisplay}`
        })
      })
    ];
  }

  measurementToggle() {
    this.measurementList = !this.measurementList;
    this.annotationList = false;
    if (this.drawInteraction) this.map.removeInteraction(this.drawInteraction);
    this.updateDrawingTypeInteraction("measurement");
    // this.measurementLayerGroup.setVisible(this.measurementList)
    // this.annotationLayerGroup.setVisible(this.annotationList)
  }
  annotationToggle() {
    this.annotationList = !this.annotationList;
    this.measurementList = false;
    if (this.drawInteraction) this.map.removeInteraction(this.drawInteraction);
    this.updateDrawingTypeInteraction("annotation");
    // this.measurementLayerGroup.setVisible(this.annotationList)
    // this.annotationLayerGroup.setVisible(this.measurementList)
  }

  // toggle asset card with respective to selected action
  showAssetCardOf(assetAction) {
    // toggle list based asset actio
    this.reportList = assetAction == "reportList" ? !this.reportList : false;
    this.measurementList =
      assetAction == "measurementList" ? !this.measurementList : false;
    this.annotationList =
      assetAction == "annotationList" ? !this.annotationList : false;
    this.info = assetAction == "info" ? !this.info : false;

    // clear reset
    this.measurementAdd = false;
    this.measurementEdit = false;
    this.annotationAdd = false;
    this.annotationEdit = false;

    // layer & interaction
    this.map.removeInteraction(this.drawInteraction);
    // this.measurementLayerGroup.setVisible(this.measurementList);
    // this.annotationLayerGroup.setVisible(this.annotationList);
    // if (assetAction == 'measurementList' || this.measurementShowAll) {
    //   this.toggleShowAll('measurementShowAll');
    // }
    // if (assetAction == 'annotationList' || this.annotationShowAll) {
    //   this.toggleShowAll('annotationShowAll');
    // }
  }

  // measurement add
  addMeasurement() {
    // clear all and set to new Measurement form
    this.measurementForm.reset();

    this.reportList = false;
    this.measurementList = false;
    this.measurementAdd = true;
    this.measurementEdit = false;
    this.annotationList = false;
    this.annotationAdd = false;
    this.annotationEdit = false;

    // set cursor as measurement
    this.map.removeInteraction(this.drawInteraction);
    this.updateDrawingTypeInteraction("measurement");
    ////console.log(this.map)
  }

  // measurement Edit
  editMeasurement(data) {
    // clear all and set to edit Measurement form by updating value
    console.log(data, this.measurementForm)
    data = { ...data, asset: this.asset }
    this.measurementForm.reset();
    this.measurementForm.patchValue(data);
    console.log(data, this.measurementForm)

    this.reportList = false;
    this.measurementList = false;
    this.measurementAdd = false;
    this.measurementEdit = true;
    this.annotationList = false;
    this.annotationAdd = false;
    this.annotationEdit = false;

    // set cursor as measurement
    this.map.removeInteraction(this.drawInteraction);
    this.updateDrawingTypeInteraction("measurement");
  }

  // save measurement
  saveMeasurement() {
    // api call for storing the measurement in database
    console.log("meaurement", this.measurementForm.value);

    this.apiService
      ._post(service["CREATE_MEASUREMENT"], this.measurementForm.value)
      .subscribe(async response => {
        console.log("response from measurement", response)
        // after the measurement, remove the existing the layers from the map
        // get the latest measurements from the table and populate it on the map
        this.removeDrawnLayer("LineString");
        await this.removeLayers("measurement");
        this.layerVisibility(true, false);
        this.showAssetCardOf("measurementList");
      });
  }

  // Annotation add
  addAnnotation() {
    // clear all and set to new annotation form
    this.annotationForm.reset();

    this.reportList = false;
    this.measurementList = false;
    this.measurementAdd = false;
    this.measurementEdit = false;
    this.annotationList = false;
    this.annotationAdd = true;
    this.annotationEdit = false;

    // set cursor as measurement
    this.map.removeInteraction(this.drawInteraction);
    this.updateDrawingTypeInteraction("annotation");
  }

  // annotation Edit
  editAnnotation(data) {
    // clear all and set to edit annotation form by updating value
    this.annotationForm.reset();
    data = { ...data, asset: this.asset }
    this.annotationForm.patchValue(data);


    this.reportList = false;
    this.measurementList = false;
    this.measurementAdd = false;
    this.measurementEdit = false;
    this.annotationList = false;
    this.annotationAdd = false;
    this.annotationEdit = true;

    // set cursor as measurement
    this.map.removeInteraction(this.drawInteraction);
    this.updateDrawingTypeInteraction("annotation");
  }

  // save annotation
  saveAnnotation() {
    this.apiService
      ._post(service["CREATE_ANNOTATION"], this.annotationForm.value)
      .subscribe(async response => {
        this.removeDrawnLayer("Point");
        await this.removeLayers("annotation");
        this.layerVisibility(false, true);

        this.showAssetCardOf("annotationList");
      });
  }


  refreshRecentAssessment() {
    this.recentAssessmentComp.getRecentAssessmentData()
  }

  refreshApprovedAssessment() {
    this.approvedAssessmentComp.getInspections()
  }

  // hide all asset card
  hideAllAssetCard() {
    this.reportList = false;
    this.measurementList = false;
    this.annotationList = false;
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // Datepicker -- selected date
  onDateSelect(date) {
    const selectedDate = this.formatDate(
      `${date.year}-${date.month}-${date.day}`
    );

    this.calenderheader = `Report - ${date.day} / ${date.month} / ${date.year}`;

    this.dateReportList = this.dates[selectedDate];

    $("#calenderModal").modal("show");

  }

  // whenuserselects save for the first\
  async beforeScreenShot() {

    this.loaderEvent({ name: 'Positioning defect', status: true })
    if (this.defectWizardComp.defectForm.value) {
      const defect = {
        coordinates: this.defectWizardComp.defectForm.value.coordinates,
        defect_center: await this.getCenter(this.defectWizardComp.defectForm.value.coordinates)
      }

      const latLng = [defect.defect_center[1], defect.defect_center[0]]

      await this.overallViewComp.changePointCoordinate(latLng)
      // setTimeout(() => {
      // return
      //   this.coordinate = latLng;
      // }, 100);
      // console.log("coordinar",this.coordinate);

      // return

      let defectNewCoord = []

      for (const def of defect.coordinates) {
        //console.log("***************", defect)
        defectNewCoord.push(OlProj.transform([def[1], def[0]], "EPSG:4326", "EPSG:3857"))
      }
      //console.log("coordinates -- ", coordinates)
      var ext = boundingExtent(defectNewCoord);
      this.map.getView().fit(ext, this.map.getSize(), { duration: 100 });
      this.map.getView().setZoom(this.map.getView().getZoom() - 1)
      this.map.getView().setCenter(OlProj.transform([defect.defect_center[1], defect.defect_center[0]], "EPSG:4326", "EPSG:3857"))
      this.map.once('rendercomplete', async e => {
        await setTimeout(async () => {
          await this.overallViewComp.changePointCoordinate(latLng)
        }, 1000);

        this.defectWizardComp.saveScreenshot(true);
      })
      this.map.renderSync()
    }
  }




  downloadFile(url) {
    // FileSaver.saveAs(url, 'file.pdf');
    DownloadFile(url);
  }

  // mover right action button to alon with right panel
  onMoveRightActionButton(data) {
    this.moveRightActionBtn = data;
  }

  // toggle Show all -- markings in asset map

  toggleShowAll(toggleWhat) {
    //console.log("toggleWhat", toggleWhat);
    if (toggleWhat === "annotationShowAll") {
      this.annotationShowAll = !this.annotationShowAll;
      this.annotationLayerGroup.setVisible(this.annotationShowAll);
    }
    if (toggleWhat === "measurementShowAll") {
      this.measurementShowAll = !this.measurementShowAll;
      this.measurementLayerGroup.setVisible(this.measurementShowAll);
    }
  }

  // array of object duplicate remove
  getUnique(arr, comp) {
    const unique = arr
      .map(e => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e])
      .map(e => arr[e]);

    return unique;
  }

  checkExistingLayers() {
    // if (this._pMapLayer) this._pMapLayer = []
    // this.clearVectorLayer()
    if (this._vMapLayer) this._vMapLayer = [];
    if (this.layerStyleMap) this.layerStyleMap = [];
    if (this.layerStyleVisibility) this.layerStyleVisibility = [];
    if (this._vlayerFilterTypes) this._vlayerFilterTypes = [];

    // this.interCommService.deleteOverlayFilters("Primarylayer");
    // this.interCommService.deleteOverlayFilters("Types");
    // this.interCommService.deleteOverlayFilters("Years");
    console.log("remove existing ", this.map);
  }


  // create primary layer (DSM)
  createLayer(data) {
    const _pData = data;

    var mapExtent = OlProj.transformExtent(
      _pData.vlayer.map_extent,
      "EPSG:4326",
      "EPSG:3857"
    );
    var mapMinZoom = _pData.vlayer.min_zoom;
    var mapMaxZoom = _pData.vlayer.max_zoom;

    var layer = new OlTileLayer({
      extent: mapExtent,
      // preload: Infinity,
      cacheSize: 1,
      source: new OlXYZ({
        url: `${_pData.vlayer.url}{z}/{x}/{y}.png`,
        tilePixelRatio: 1.0,
        minZoom: mapMinZoom,
        maxZoom: mapMaxZoom,
        crossOrigin: "Anonymous"
      })
    });


    return layer;
  }

  // create vector layer
  async createVLayer(data, index) {
    let returnData = {};
    var mapExtent = OlProj.transformExtent(
      this.assetDetails.maptiler_coordinates,
      "EPSG:4326",
      "EPSG:3857"
    );
    // var index = this.map.getLayers().getArray().length - 1;

    returnData = {
      id: data.vlayer.id,
      name: data.name,
      year: data.year,
      index: index,
      type: "Vectorlayer",
      layer: new OlLayerVectorTile({
        source: new OlSourceVectorTile({
          format: new MVT(),
          tileGrid: new OlTitleGrid.createXYZ({
            minZoom: data.vlayer.minzoom,
            maxZoom: data.vlayer.maxzoom,
            tileSize: 512
          }),

          tilePixelRatio: 8,
          url: `${data.url}{z}/{x}/{y}.pbf`
        }),
        extent: mapExtent,
        style: (feature, resolution) => {
          if (!this.layerStyleVisibility[data.vlayer.id]) return null;
          var style = this.layerStyleMap[data.vlayer.id][feature.getType()];
          return [style];
        }
      })
    };
    return returnData;
  }


  hideBottomandSideBar() {
    this.removeLayers('defect')
    this.showBottomBar = false
    this.showRightSideBar = false
    this.removeLayers('defect')
  }





  updateOpacity() {
    if (this.map) {
      const layers = this.map.getLayers().getArray();
      layers.forEach((layer, i) => {
        if (i < 3) {
        } else if (
          layer.values_ &&
          layer.values_.title != "measurement" &&
          layer.values_.title != "annotation" &&
          layer.values_.title != "defect"
        ) {
          //console.log("layer", layer);
          if (layer && this.overlayOpacity) {
            layer.setOpacity(this.overlayOpacity);
          }
        }
      });
    }
  }

  backpage() {
    this.router.navigate(["home/port"], {
      queryParams: {
        port_name: this.portname,
        port_id: this.portid
      }, queryParamsHandling: "merge"
    });
  }

  // ********************************** Layout **************************************

  // toggle layout - left and bottom
  toggleLayout() {

    // toggle left layout
    this.showLeftSideBar = !this.showLeftSideBar;

    // decide bottom bar to show or hide
    this.showBottomBar = (this.showRightSideBar) ? this.showLeftSideBar : false

    // update map size
    setTimeout(() => {
      this.map.updateSize();
    }, 500);
  }

  // toggle assessment mode
  toggleAssessmentMode(event) {
    console.log("toggle assessment mode", event)

    this.removeLayers('defect')

    this.defectWizardComp.defectForm.reset()

    // this.recentAssessmentComp.getRecentAssessmentData()

    // toggle right layout
    this.showRightSideBar = event;
    this.showBottomBar = event;

    // toggle defect wizard form

    let _qp = this.activeParams;
    console.log("_qp", _qp);
    if (_qp["inspection_id"]) {
      delete _qp["inspection_id"];
      delete _qp["inspection_name"];
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: _qp
    });
    this.defectListComp.defectList = [];
    this.defectListComp.inspectionDetails = {};
    this.defectWizardComp.inspectionDetails = { id: null };


    // update map size
    setTimeout(() => {
      this.map.updateSize();
    }, 500);
  }

  // show/hide recent assessment
  toggleRecentAssessmentMode(event) {
    this.showRecentAssessment = event;
  }

  // show/hide Approved assessment
  toggleApprovedAssessmentMode(event) {
    this.showApprovedAssessment = event;
  }

  openInspectionData(event) {
    console.warn("getInspectionData", event);
    this.showBottomBar = true;
    this.showRightSideBar = true;
    // this.inspectionDetails = event;
    console.warn("event", event);

    setTimeout(() => {
      this.defectListComp.getDefectlist();
      this.defectWizardComp.defectForm.reset();
      this.defectWizardComp.toggleFormControl();
      this.defectWizardComp.imageList = false;
      this.defectWizardComp.screenShot = false;
    }, 200);
  }

  getInspectionData(event) {
    console.warn("getInspectionData", event);
    this.showBottomBar = true;
    this.showRightSideBar = true;
    // this.inspectionDetails = event;
    console.warn("event", event);

    this.router.navigate(["home/port/asset"], {
      queryParams: {
        inspection_name: event["name"],
        inspection_id: event["id"]
      }, queryParamsHandling: "merge"
    });
    setTimeout(() => {
      this.defectListComp.getDefectlist();
      this.defectWizardComp.defectForm.reset();
      this.defectWizardComp.toggleFormControl();
      this.defectWizardComp.imageList = false;
      this.defectWizardComp.screenShot = false;
    }, 200);
  }


  // generate report model


  viewDraftReport = (event) => {
    $("#reportModal").modal("show");
  }
  onFileChanged(event) {
    this.windowloader = {
      name: "Cover Image in Uploading..",
      loader: true
    };

    this.selectedFile = event.target.files[0];
    this.fileLabel = this.selectedFile.name;
    // console.log("Selected File " + this.selectedFile.name);
    const formData = new FormData();
    formData.append("file", this.selectedFile, "file");
    this.apiService._post("upload-file", formData).subscribe(
      response => {
        this.windowloader["loader"] = false;
        this.enableUpload = true;
        this.selectedFileUrl = response.data[0].url;
        // console.log(response.data[0].url);
      },
      err => {
        this.windowloader["loader"] = false;
        console.log(err);
      }
    );
  }

  convQP2Obj(qp) {
    // split by &
    if (qp) {
      let qpArr = qp.split("&");
      let qpObj = {};
      for (let i = 0; i < qpArr.length; i++) {
        if (qpArr[i]) {
          let _qpObjArr = qpArr[i].split("=");
          qpObj[_qpObjArr[0]] = _qpObjArr[1].replace(/%20/gi, " ");
        }
      }

      return qpObj;
    }
  }

  uploadImage() {
    let postData = {
      id: this.activeParams.inspection_id,
      report_cover_image: this.selectedFileUrl,
      summary_text: this.executiveSummary
    };
    this.windowloader = {
      name: "Generating Report..",
      loader: true
    };

    this.apiService._post("generate-report", postData).subscribe(
      response => {
        // console.log(response);
        // this.internalCommService.setData("defectAction","reload");
        this.windowloader["loader"] = false;
        if (response.status) {
          $("#reportModal").modal("hide");
          this.toastr.success(response.message);
        } else {
          this.toastr.success(response.message);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getMaptarget(event) {
    // return this.map.getTargetElement()
    this.defectWizardComp.map = this.map.getTargetElement();
    this.defectWizardComp.miniMap = this.overallViewComp.map.getTargetElement();
  }

  openBottomView(event) {
    // console.clear();
    // console.log("openBottomView", event)
    this.showBottomBar = true;
    this.defectListComp.getDefectlist();
    this.refreshRecentAssessment()


  }

  removeVectorLayer() {
    if (this.map) {
      const sources = this.map.getLayers().getArray()[2].getSource()
      const features = sources.getFeatures()
      if (features) {
        for (const feature of features) {
          sources.removeFeature(feature)
        }
      }
      // console.log("this.map")
    }
  }

  updateDefectIndex = (event) => {
    this.defectWizardComp.defectCount = this.defectListComp.defectList.length + 1;
    console.log(this.defectListComp.defectList)

    this.drawDefect(this.defectListComp.defectList)
  }
  selectedDefectData(event) {
    // console.log("okokook", event);
    this.defectWizardComp.imageList = false;
    this.defectWizardComp.screenShot = false;

    this.defectWizardComp.defectForm.patchValue(event);

    this.defectWizardComp.getScreenshotList('true');
    this.defectWizardComp.getImageList('true');
    console.log(this.defectWizardComp.defectForm);
    // this.defectWizardComp.disableForm = false;
    this.defectWizardComp.toggleFormControl();
    this.defectWizardComp.defectCount = event.defectIndex + 1;
    this.selectDefect(event);
  }

  loaderEvent = (event) => {
    this.windowloader["loader"] = event.status
    this.windowloader["name"] = event.name
  }
}
