import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { } from 'googlemaps';
import { ApiService } from 'src/app/core';
import { iPortField, iOrthoMosaic, iRealityCapture, iPanoramic } from '../models/port.class';
import * as mapStyle from "src/assets/json/mapStyle.json";
var MarkerWithLabel = require('markerwithlabel')(google.maps);

import { InternalCommService } from 'src/app/core';
import { stat } from 'fs';


enum ViewType {
  TOP_VIEW = 'Top View',
  STREET_VIEW = 'Pano View',
  THREE_D_VIEW = '3D View',
  GROUND_VIEW = 'Ground View'
}


@Component({
  selector: 'app-port',
  templateUrl: './port.component.html',
  styleUrls: ['./port.component.css']
})
export class PortComponent implements OnInit {
  @ViewChild('map', null) mapElement: any;
  map: google.maps.Map;
  port: iPortField;
  portId: string;
  proj: any
  orthomosaiImage: iOrthoMosaic[]
  realityCapture: iRealityCapture[]
  panoramic: iPanoramic[]
  polygonCreated: any = {}
  floatingButton: any[] = []


  groundViewMarkerStatus: boolean = false
  groundViewMarkers: any[] = []
  groundDetails: Object = {};

  showGallery: boolean = false;
  expandGallery: Boolean = false;
  galleryImages: Array<object> = [];
  galleryClosBtn: Boolean = false;
  activeParams;
  windowloader: Object = {
    name: "",
    loader: false
  };

  // port page background image
  bgImgUrl = ""

  //notification url
  notificationUrl: string
  //recentUpload url
  recentUploadsUrl: string
  //reportsummary url
  reportSummaryUrl: string
  //siteInformation url
  siteInformationUrl: string
  //recentAssessment Url
  recentAssessmentUrl: string

  constructor(private router: Router,
    private internalCommService: InternalCommService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService, ) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.layoutMonitor();
        this.getAllAssetDetails();

      }
    });

    this.activatedRoute.queryParamMap.subscribe(params => {
      this.activeParams = { ...params['params'] };
    })
    console.log('routercalled', this.activeParams)
  }

  async ngOnInit() {
    // console.log("router", this.activatedRoute.queryParams["_value"].port_id)
    this.portId = this.activatedRoute.queryParams["_value"].port_id;

    this.getAllAssetDetails();
    // this.getSitedetailandMap();
    this.portId = this.activatedRoute.queryParams["_value"].port_id

    // getting my notification port url
    this.notificationUrl = `get-notifications?${this.portId}`
    // getting recentUpload port url
    this.recentUploadsUrl = `recent-uploads?${this.portId}`
    // getting reportsummary port url
    this.reportSummaryUrl = `get-reports-by-port/${this.portId}`
    // getting siteInformation port url
    this.siteInformationUrl = `get-assets/${this.portId}`
    // getting recentAssessment port url
    this.recentAssessmentUrl = `get-inspections-by-asset/${this.portId}`

    this.getSitedetailandMap();


    this.layoutMonitor();
  }


  siteData(data){
    console.log("site url",data);
    this.bgImgUrl = data['landing_image'];
  }


  setUpMap() {
    let mapProperties = {
      center: new google.maps.LatLng(this.port.latitude, this.port.longitude),
      zoom: 10,
      tilt: 0,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      streetViewControl: false

    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);

    this.map.addListener('click', function (e) {
      console.log("coordinates", e.latLng.lat(), e.latLng.lng())
    });


    var styledMapType: any = new google.maps.StyledMapType(mapStyle['homeMapStyle'])
    //setting up styles
    this.map.mapTypes.set('styled_map', styledMapType);

    //add styles to map
    // this.map.setMapTypeId('styled_map');

    // setting map zoom level after map is idle.
    setTimeout(() => {
      var listener = google.maps.event.addListener(this.map, "idle", () => {
        this.map.setZoom(this.port.map_zoom);
        google.maps.event.removeListener(listener);
      });
      this.map.setZoom(this.port.map_zoom);
    }, 1000);
  }

  async getSitedetailandMap() {
    this.windowloader = {
      name: "Fetching Information..",
      loader: true
    };
    this.apiService._get(`get-port/${this.portId}`).subscribe(async response => {
      this.port = response
      console.log("response", this.port)
      // this.setUpMap()
      this.getAllAssetDetails()
    })
  }


  drawGroundViewMarker(markers) {
    console.log("markers", markers)
    var that = this
    markers.map(groundMarker => {
      var labelT = '';
      labelT = `<div><div ${mapStyle['johore']}><img src=assets/images/m-yes.png><div ${mapStyle['homeMarkerOverlayStyle']}>${groundMarker.name}</div></div></div>`
      var marker = new MarkerWithLabel({
        icon: {
          labelOrigin: new google.maps.Point(90, 48),
          url: "www.google.com"
        },
        position: new google.maps.LatLng(groundMarker.latitude, groundMarker.longitude),
        // raiseOnDrag: true,
        labelContent: labelT,
        labelAnchor: new google.maps.Point(48, 32),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1 },
        zIndex: 999999,
      })

      marker.id = groundMarker.id

      marker.setMap(this.map)
      google.maps.event.addListener(marker, "click", function (e) {
        that.markerClicked(groundMarker)
      });
      this.groundViewMarkers.push(marker)
    })

  }


  markerClicked(data) {
    console.log("marker data", data)
    let groundImageData = data.ground_images
    let imageArrayForProps = []

    if (groundImageData) {
      for (const groundImage of groundImageData) {

        imageArrayForProps.push({
          small: groundImage.thumb_url,
          medium: groundImage.url,
          big: groundImage.url,
          description: groundImage.name
        })
      }
    }

    console.log(imageArrayForProps);
    this.galleryImages = imageArrayForProps;
    this.showGallery = true;
    this.galleryClosBtn = true;
  }

  removeGroundViewMarker() {
    if (this.groundViewMarkers) {

      for (let i = 0; i < this.groundViewMarkers.length; i++) {
        this.groundViewMarkers[i].setMap(null);
      }
    }
    this.groundViewMarkers = []
  }


  clearDefectDataBeforeNavigating() {
    this.internalCommService.setDefectList([]);

    this.internalCommService.deleteData("defectWizardForm");
    this.internalCommService.deleteData("screenShots");
    this.internalCommService.deleteData("imageUploads");
    this.internalCommService.deleteData("defectParams");

    // show right layout
    let _rightLayoutData = this.internalCommService.getdata("rightLayout");
    _rightLayoutData = _rightLayoutData ? _rightLayoutData : {};
    _rightLayoutData["show"] = false;
    _rightLayoutData["defectWizardShow"] = false;
    _rightLayoutData["filterLayout"] = false;
    this.internalCommService.setData("rightLayout", _rightLayoutData);

    // show bottom layout
    let _bottomLayoutData = this.internalCommService.getdata("bottomLayout");
    _bottomLayoutData = _bottomLayoutData ? _bottomLayoutData : {};
    _bottomLayoutData["show"] = false;
    this.internalCommService.setData("bottomLayout", _bottomLayoutData);
  }

  selectFloatingButton(data) {

    console.log("floating button ---- ", data)
    if (data.label == ViewType['TOP_VIEW']) {
      this.clearDefectDataBeforeNavigating()
      this.router.navigate(['home/port/asset'], { queryParams: { asset_name: data.data.name, asset_id: data.data.id }, queryParamsHandling: "merge" })

    }
    else if (data.label == ViewType['THREE_D_VIEW']) {
      this.router.navigate(['home/port/potree'], { queryParams: { asset_name: data.data.name, asset_id: data.data.id }, queryParamsHandling: "merge" })
    }
    else if (data.label == ViewType['GROUND_VIEW']) {
      // this.router.navigate(['home/port/potree'], { queryParams: { asset_name: data.data.name, asset_id: data.data.id }, queryParamsHandling: "merge" })
      // this.groundViewMarkerStatus = !this.groundViewMarkerStatus
      // console.log("data when user clicks on floating", data, status)
      // if (this.groundViewMarkerStatus) {
      //   this.drawGroundViewMarker(data.data)
      // }
      // else {
      //   this.removeGroundViewMarker()
      // }

      this.routeToGround();

    }
    else if (data.label == ViewType['STREET_VIEW']) {
      this.router.navigate(['home/port/panoramic'], { queryParams: { asset_name: data.data.name, asset_id: data.data.id }, queryParamsHandling: "merge" })
    }
  }


  getAllAssetDetails() {
    
    console.log("get all assets");
    this.apiService._get(`get-assets/${this.portId}`).subscribe(async response => {
      this.windowloader['loader'] = false
      console.log("get all asset details", response)
      this.orthomosaiImage = response["ortho"];
      this.groundDetails = response["ground"];

      if (this.orthomosaiImage) this.floatingButton.push({ label: ViewType['TOP_VIEW'], data: this.orthomosaiImage[0] })
      this.realityCapture = response["rc"]
      if (this.realityCapture) this.floatingButton.push({ label: ViewType['THREE_D_VIEW'], data: this.realityCapture[0] })
      this.panoramic = response["panoramic"]
      if (this.panoramic) this.floatingButton.push({ label: ViewType['STREET_VIEW'], data: this.panoramic[0] })
      if (response['ground']) this.floatingButton.push({ label: ViewType['GROUND_VIEW'], data: response['ground'] })
      await this.setUpOrthomosaicImage()
      await this.setUpRealityCapture()
      this.setUpPanoramic()
      // if(this.activeParams["isGround"]){
      //   this.floatingButton.map((data)=>{
      //     if(data.label ==  ViewType['GROUND_VIEW']){
      //       this.selectFloatingButton(data)
      //     }
      //   })
     
      // }
    })
  }




  createOverlay(percent, imageUrl, mapBounds) {
    // //console.log("createoverlay", percent);
    var that = this

    var pix4tiler = new google.maps.ImageMapType({

      getTileUrl: function (coord, zoom) {
        that.proj = that.map.getProjection();
        //console.log("zoom ", zoom)
        var tileSize = 256 / Math.pow(2, zoom);
        var tileBounds = new google.maps.LatLngBounds(
          that.proj.fromPointToLatLng(new google.maps.Point(coord.x * tileSize, (coord.y + 1) * tileSize)), that.proj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * tileSize, coord.y * tileSize)));
        if (mapBounds.intersects(tileBounds) && (zoom >= 12) && (zoom <= 22)) {

          return imageUrl + zoom + "/" + coord.x + "/" + (Math.pow(2, zoom) - coord.y - 1) + ".png";
        } else
          return null
      },
      tileSize: new google.maps.Size(256, 256),
      opacity: parseFloat(percent) / 100.0
    });
    // map.setMapTypeId('satellite');
    return pix4tiler;
  }



  HomeControl(controlDiv, map, percent, imageUrl, mapBounds) {
    controlDiv.style.padding = '5px';
    var controlUI = document.createElement('DIV');
    controlUI.style.backgroundColor = 'white';
    controlUI.style.borderStyle = 'solid';
    controlUI.style.borderWidth = '2px';
    controlUI.style.cursor = 'pointer';
    controlUI.style.textAlign = 'center';
    controlUI.className = "opacity_changer";
    controlUI.title = 'opacity of the orthomosaic';
    // controlDiv.appendChild(controlUI);
    var controlText = document.createElement('DIV');
    controlText.style.fontFamily = 'Arial,sans-serif';
    controlText.style.fontSize = '12px';
    controlText.style.paddingLeft = '4px';
    controlText.style.paddingRight = '4px';
    controlText.innerHTML = percent;
    controlUI.appendChild(controlText);
    google.maps.event.addDomListener(controlUI, 'click', function () {
      map.overlayMapTypes.clear();
      var pix4tiler = this.createOverlay(percent, imageUrl, mapBounds);
      map.overlayMapTypes.insertAt(0, pix4tiler);
    });
  }



  label(controlDiv) {
    controlDiv.style.padding = '5px';
    var controlUI = document.createElement('DIV');
    controlUI.style.backgroundColor = 'white';
    controlUI.style.borderStyle = 'solid';
    controlUI.style.borderWidth = '2px';
    controlUI.style.cursor = 'pointer';
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Orthomosaic opacity:';
    controlUI.className = "opacity_changer";
    // controlDiv.appendChild(controlUI);
    var controlText = document.createElement('DIV');
    controlText.style.fontFamily = 'Arial,sans-serif';
    controlText.style.fontSize = '12px';
    controlText.style.paddingLeft = '4px';
    controlText.style.paddingRight = '4px';
    controlText.innerHTML = 'Orthomosaic opacity:';
    // controlUI.appendChild(controlText);
  }



  async setUpOrthomosaicImage() {


    var that = this
    // that.map.setZoom(8)
    console.log("ortho", this.orthomosaiImage)

    this.orthomosaiImage.map((orthodetail, index) => {

      //marker setup
      var labelT = '';
      labelT = `<div><div ${mapStyle['johore']}><img src=assets/images/m-yes.png><div ${mapStyle['homeMarkerOverlayStyle']}>${orthodetail.name}</div></div></div>`
      var marker = new MarkerWithLabel({
        icon: {
          labelOrigin: new google.maps.Point(90, 48),
          url: "www.google.com"
        },
        position: new google.maps.LatLng(orthodetail.latitude, orthodetail.longitude),
        // raiseOnDrag: true,
        labelContent: labelT,
        labelAnchor: new google.maps.Point(48, 32),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1 },
        zIndex: 999999,
      })

      marker.id = orthodetail.id

      // marker.setMap(this.map)

      // google.maps.event.addListener(marker, "click", function (e) {
      //   that.routeSomeWhere(orthodetail.name, orthodetail.id)
      // });




      if (orthodetail.imageUrl) {
        var url = orthodetail.imageUrl;

        console.log('orthomosaic', orthodetail, orthodetail.image_coordinates[0][0], orthodetail.image_coordinates[0][1], orthodetail.image_coordinates[1][0], orthodetail.image_coordinates[1][1])
        var mapBounds1 = new google.maps.LatLngBounds(
          // [[51.39079349708432, -3.264569028391198], [51.39312298147395, -3.2584601021362]]
          new google.maps.LatLng(orthodetail.image_coordinates[0][0], orthodetail.image_coordinates[0][1]), new google.maps.LatLng(orthodetail.image_coordinates[1][0], orthodetail.image_coordinates[1][1]))
        this.map.fitBounds(mapBounds1);

        var pix4tiler1 = this.createOverlay('80', url, mapBounds1);
        this.map.overlayMapTypes.insertAt(1, pix4tiler1);
        var homeControlDiv = document.createElement('DIV');
        var homeControl2 = this.HomeControl(homeControlDiv, this.map, '100%', url, mapBounds1);


        var homeControlDiv5 = document.createElement('DIV');
        var homeControl5 = this.label(homeControlDiv5);
        homeControlDiv["index"] = 1;
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(homeControlDiv);
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(homeControlDiv5);
      }
    })
  }



  setUpRealityCapture() {
    var that = this
    this.realityCapture.map(rc => {
      var labelT = '';
      labelT = `<div${mapStyle['state1']}><div ${mapStyle['johore']}><img src=assets/images/m-rc.png><div ${mapStyle['homeMarkerOverlayStyle']}>${rc.name}</div></div></div>`
      var marker = new MarkerWithLabel({
        icon: {
          labelOrigin: new google.maps.Point(90, 48),
          url: "www.google.com"
        },
        position: new google.maps.LatLng(rc.latitude, rc.longitude),
        // raiseOnDrag: true,
        labelContent: labelT,
        labelAnchor: new google.maps.Point(48, 32),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1 },
        zIndex: 999999,
      })

      marker.id = rc.id

      // marker.setMap(this.map)

      // google.maps.event.addListener(marker, "click", function (e) {
      //   that.routeRc(rc.name, rc.id)
      // });

    })
  }

  routeAsset(name, id) {
    this.router.navigate(['home/port/asset'], { queryParams: { asset_name: name, asset_id: id }, queryParamsHandling: "merge" })
  }

  routeRc(name, id) {
    this.router.navigate(['home/port/potree'], { queryParams: { asset_name: name, asset_id: id }, queryParamsHandling: "merge" })
  }

  routePanoramic(name, id) {
    this.router.navigate(['home/port/panoramic'], { queryParams: { asset_name: name, asset_id: id }, queryParamsHandling: "merge" })
  }

  routeToGround() {
    this.router.navigate(['home/port/ground'], { queryParams: { asset_name: this.groundDetails['asset_name'], asset_id: this.groundDetails['asset_id'] }, queryParamsHandling: "merge" })
  }


  setUpPanoramic() {
    var that = this
    console.log("panoramic", this.panoramic)
    this.recursiveMarker(this.panoramic, 'parent')

  }



  recursiveMarker(arrayOfObjects: iPanoramic[], hierarchy) {
    var that = this
    var icon;
    hierarchy === 'child' ? icon = "assets/images/yellow.png" : icon = "assets/images/red.png"

    arrayOfObjects.forEach((markerdata, index) => {
      // var labelT = "<div  class=state><div onmouseover='markerClick(" + i + ")' class=johore><img src=" + icon + "></div></div>"
      var labelT;
      hierarchy === 'child' ? labelT = "<div class=state2><div class=johore1><img src=" + icon + "></div></div>" : labelT = "<div  class=state1><div class=johore1><img class='red' src=" + icon + "></div><div class=overlay360>" + markerdata.name + "</div></div>"
      var marker = new MarkerWithLabel({
        icon: {
          labelOrigin: new google.maps.Point(15, 25),
          url: ""
        },
        position: new google.maps.LatLng(markerdata.latitude, markerdata.longitude),
        labelContent: labelT,
        labelAnchor: new google.maps.Point(15, 25),
        labelStyle: {
          // opacity: 0.75
        },
        zIndex: 999999,
      })

      if (hierarchy == 'parent') {
        console.log("adding listener")
        google.maps.event.addListener(marker, "mouseover", function (e) {
          console.log("Mouse Over");
          that.mouseOverMarker(markerdata.id)
        });

        google.maps.event.addListener(marker, "mouseout", function (e) {
          console.log("Mouse Out");
          that.mouseOutMarker(markerdata.id)
        });

        google.maps.event.addListener(marker, "click", function (e) {
          that.routePanoramic(markerdata.name, markerdata.id);

        });

      }


      marker.setMap(this.map)


      markerdata.marker = marker
      console.log(markerdata.child)
      if (markerdata.child) {
        this.createPolygon(markerdata)
        this.recursiveMarker(markerdata.child, 'child')

      }
    })

  }


  createPolygon(parentObject: iPanoramic) {
    console.log("create Polygon", parentObject)
    let latlng = []
    let latLngObject = []
    latlng.push({
      lat: parentObject.latitude,
      lng: parentObject.longitude
    })
    parentObject.child.forEach(child => {
      latlng.push({
        lat: child.latitude,
        lng: child.longitude
      })
    })


    console.log(latlng)
    var polygon = new google.maps.Polygon({
      paths: latlng,
      strokeColor: '#FF0000',
      strokeOpacity: 0.4,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.1
    });
    this.polygonCreated[parentObject.id] = polygon
  }




  mouseOverMarker(i) {
    // console.log("Mouse Over", polygonCreated[i],i)
    this.polygonCreated[i].setMap(this.map)
  }

  mouseOutMarker(i) {
    // console.log("Mouse Out", i)
    this.polygonCreated[i].setMap(null)

  }

  // monitor side bar
  layoutMonitor() {
    this.internalCommService.currentData.subscribe(async response => {
      if (response['layoutToggle']) {
        this.expandGallery = response['layoutToggle']['image-gallery-expand']
      }
    })
  }



}