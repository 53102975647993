import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ApiService, SocketService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

//interfact for mynotification response
interface IMyNotification {
  status: Boolean;
  data: any[];
  isLoadMore: Boolean;
  dates: any[]
  unReadCount: number
}

//state to maintain date 

interface IDateState {
  previousDate: string[];
  currentDate: string[];
  skip: number;
  dateRange: any;
  iteration: number
}

@Component({
  selector: 'app-my-notifications',
  templateUrl: './my-notifications.component.html',
  styleUrls: ['./my-notifications.component.css']
})
export class MyNotificationsComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private socketService: SocketService,
    private router: Router
  ) { }


  //contains all notification data
  myNotificationData: IMyNotification

  // url from parent component to fetch data for mynotification
  @Input() fetchUrl: string

  //card toggle
  showCardBody: Boolean = true;

  // active calendar dates 
  activeCalendarDates: any[] = []

  //select from and to date
  fromDate: any;
  toDate: any;

  myNotification = false;



  // init data for mynotification data
  myNotificationsDate: IDateState = {
    skip: 0,
    currentDate: [],
    previousDate: [],
    dateRange: "",
    iteration: 0
  }


  async ngOnInit() {
    // get notification 
    await this.getNotifications()

    // socket call for notification

    this.socketService.listenSocket().subscribe(data => {
      console.log("socket 1", data);
      if ((data["type"] = "NOTIFICATION")) {
        this.myNotificationData.data = [];
        this.paginateNotification
      }
      this.toastr.info(data["message"] || `New ${data["type"]}`);
    });

  }

  // API call to update read status 
  async updateNotificationStatus(data) {
    if (data.status == "UNREAD") {
      await this.apiService
        ._get(`update-notification?id=${data["id"]}`)
        .subscribe(response => {
          if (response.status) {
            data.status = "READ";
            let unReadCount = this.myNotificationData.unReadCount - 1;
            this.myNotificationData.unReadCount =
              unReadCount > 0 ? unReadCount : 0;
          }
        });
    }
  }


  displayMyNotification(date, pop: any) {
    pop.close()
    this.myNotification = true;
    this.fromDate = date.fromDate;
    this.toDate = date.toDate;
    this.myNotificationCalendarApply()
  }

  // when user clicks on apply button on calendar
  myNotificationCalendarApply() {
    this.myNotificationsDate.skip = 0
    this.paginateNotification()
  }


  // get notification
  // called initially on ngonit
  async getNotifications() {
    if (this.fetchUrl) {
      // get My Notifications details
      this.apiService._get(this.fetchUrl).subscribe(response => {
        // validatte response status
        if (!response.status)
          return this.toastr.warning(
            "Some Error in Fetching notification details"
          );
        this.activeCalendarDates = response.dates
        this.myNotificationsDate.iteration += 1
        this.myNotificationData = response

      });
    }

  }


  // logic to setup skip and iteration count
  validateMyNotificationState(data) {
    if (this.myNotificationsDate.iteration == 0) {
      this.myNotificationsDate.skip = 0
      this.myNotificationsDate.iteration += 1
      if (this.fromDate != "" && this.toDate != "") {
        return []
      }
      else {
        this.myNotificationsDate.skip = data.length
        return data
      }
    }
    else if (this.myNotificationsDate.currentDate[0] !== this.myNotificationsDate.previousDate[0] || this.myNotificationsDate.currentDate[1] !== this.myNotificationsDate.previousDate[1]) {
      this.myNotificationsDate.skip = 0
      this.myNotificationsDate.iteration += 1
      return []
    }
    else {
      this.myNotificationsDate.skip = data.length
      return data
    }

  }


  // called when user click on calendar close
  close(pop: any) {
    pop.close()
  }
  open(pop: any) {
    pop.open()
  }

  // Pagination for notification happens here
  paginateNotification() {
    this.myNotificationsDate.currentDate = [this.fromDate, this.toDate]
    var pervArray = this.myNotificationData.data;

    pervArray = this.validateMyNotificationState(pervArray)

    if (this.myNotificationsDate.skip == 0) {
      pervArray = []
    }

    console.log(this.fromDate, this.toDate)
    const skip = this.myNotificationsDate.skip
    const limit = 10;
    var url = "";

    if (this.fromDate && this.fromDate != "" && this.toDate != "") {
      url = `${this.fetchUrl}skip=${skip}&limit=${limit}&fromDate=${this.fromDate}&toDate=${this.toDate}`
    } else {

      url = `${this.fetchUrl}skip=${skip}&limit=${limit}`
    }

    // get Recent uploads details
    this.apiService
      ._get(url)
      .subscribe(response => {
        // validatte response status
        if (!response.status)
          return this.toastr.warning("Some Error in Fetching details");

        // set notification datails
        this.activeCalendarDates = response.dates

        console.log("response", response)
        this.myNotificationData.unReadCount = response.unReadCount;
        this.myNotificationData.data = [
          ...pervArray,
          ...response.data
        ];
        console.log(this.myNotificationData)
        //add skip
        this.myNotificationsDate.previousDate = [this.fromDate, this.toDate]
        this.myNotificationData.isLoadMore = response["isLoadMore"];
      });
  }

  // date reset
  resetDate(data) {
    if (data == 'true') {
      this.fromDate = ""
      this.toDate = ""
      this.myNotification = false
      this.paginateNotification()
    }
  }

  // Report Section

  openReport(data) {
    // window.open(data['report'][0]['report_url'], '_blank')
    // this.internalCommService.setData('pdfUrl', data['report_url']);
    this.openReportToNewPage(data['report_url']);
  }

  openReportToNewPage = (pdf_url) => {
    // open(`/view-report?document=${window.btoa(pdf_url)}`, 'PDF REPORT', this.newWindowParam);
    window.open(`/view-report?document=${window.btoa(pdf_url)}`, '_blank');
  }

  // Redirect page
  redirectPage = (data, page) => {
    if (page === "asset") {
      this.router.navigate(["home/port/asset"], {
        queryParams: {
          port_name: data.portName,
          port_id: data.portId,
          asset_name: data.assetName,
          asset_id: data.assetId
        }
      });
    }
    if (page === "approve") {
      this.router.navigate(["home/port/asset"], {
        queryParams: {
          port_name: data.portName,
          port_id: data.portId,
          asset_name: data.assetName,
          asset_id: data.assetId,
          is_approve: true
        }
      });
    } else if (page === "port") {
      this.router.navigate(["home/port"], {
        queryParams: { port_name: data.portName, port_id: data.portId }
      });
    }
  };

}
