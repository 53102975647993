import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InternalCommService {

    // Observable object source
    private dataSource = new BehaviorSubject({});
    private buttonSource = new BehaviorSubject({});
    private defectSource = new BehaviorSubject({});
    private defectFormSource = new BehaviorSubject({});
    private reloadViewInspectionSource = new BehaviorSubject({});
    private overlayFilterSource = new BehaviorSubject({});
    private filterColorSectionSource = new BehaviorSubject({});
    private filterDataSource = new BehaviorSubject({});
    private selectedFilteredDataSource = new BehaviorSubject({});
    private rawImagesSource = new BehaviorSubject({});
    private defectNumberSource = new BehaviorSubject({});


    // Observable object stream
    defectnum = this.defectNumberSource.asObservable();
    currentData = this.dataSource.asObservable();

    buttonSelected = this.buttonSource.asObservable();

    defectFormData = this.defectFormSource.asObservable();
    overlayFilterData = this.overlayFilterSource.asObservable();
    filterColorSectionData = this.filterColorSectionSource.asObservable();

    reloadViewInspection = this.reloadViewInspectionSource.asObservable();

    defectList = this.defectSource.asObservable()
    filterData = this.filterDataSource.asObservable()

    selectedFilteredData = this.selectedFilteredDataSource.asObservable();

    rawImagesData = this.rawImagesSource.asObservable();

    setDefectNo(number) {
        this.defectnum = number;
        this.defectNumberSource.next(this.defectnum);
    }

    getDefectNo() {
        return this.defectnum;
    }

    setDefectList(list) {
        this.defectList['defectList'] = list
        this.defectSource.next(this.defectList)
    }

    getDefectList() {
        return this.defectList['defectList']
    }

    getDefectListCount() {
        let list = this.defectList['defectList'];
        return list.length;
    }
    getDefectListIndex(id) {
        let index = this.defectList['defectList'].findIndex(x => x.id == id);
        return index;
    }

    // to pass filter data from overlay map to filter component
    setFilterData(filter) {
        this.filterData['filter'] = filter
        // console.log("inter comm service",this.filterData)

        this.filterDataSource.next(this.filterData)
    }

    getFilterData() {
        return this.filterData['filter']
    }


    selectButton(key: string, data: string) {
        // clone previous data
        // let _data = this.buttonSelected;
        // this.buttonSelected
        // add new data
        this.buttonSelected[key] = data;

        // pass data and set current data updated

        this.buttonSource.next(this.buttonSelected);
    }

    whichButton(key) {
        return this.buttonSelected[key];
    }


    // set data
    setData(type: string, data: any) {

        // clone previous data
        let _data = this.currentData;

        // add new data
        _data[type] = data;

        // pass data and set current data updated
        this.dataSource.next(_data);

    }

    // get data
    getdata(type: string) {
        return this.currentData[type];
    }

    // remove data
    deleteData(type: string) {
        delete this.currentData[type];
    }

    /****************** Image upload & Screenshot ******************/

    // add image url as array 
    addImage(type: string, data: Array<string>) {
        if (data) {
            // clone previous data
            console.log("data addimage", data)
            let _data = this.currentData;

            //validate new array
            if (!_data[type]) {
                _data[type] = []
            }

            // add new image url
            _data[type].push(...data);

            // pass data and set current data updated
            this.dataSource.next(_data);
        }


    }

    // remove image url
    removeImage(type: string, index: number) {
        console.log(this.currentData[type])
        // remove url from specified source
        if (this.currentData[type]) {
            this.currentData[type].splice(index, 1);
        }
    }

    updateImageInDefectwizardFormSS(data) {

        // clone previous data
        let _data = this.currentData;

        _data['defectWizardForm']['defect_image'] = [];

        _data['defectWizardForm']['defect_image'].push(...data)

        // pass data and set current data updated
        this.dataSource.next(_data);
    }
    updateImageInDefectwizardFormIU(data) {

        // clone previous data
        let _data = this.currentData;

        _data['defectWizardForm']['uploaded_image'] = [];

        _data['defectWizardForm']['uploaded_image'].push(...data)

        // pass data and set current data updated
        this.dataSource.next(_data);
    }

    // trigger screenshot
    triggerScreenshot() {
        // set true to take screen shot
        this.currentData['takeScreenShot'] = true;
    }

    // trigger screenshot
    endScreenshot() {

        // set true to take screen shot
        this.currentData['takeScreenShot'] = false;
    }

    /*******************************Defect for submit ******************************* */
    defectWizardFormSubmit(value: boolean) {
        this.defectFormData['submit'] = value;
        this.defectFormSource.next(this.defectFormData);
    }

    /*******************************View Inspection reload ******************************* */
    reloadViewInspectionSet(value: boolean) {
        this.reloadViewInspection['reload'] = value;
        this.reloadViewInspectionSource.next(this.reloadViewInspection);
    }
    // ---------------------------- Overlay Filter -------------------------

    // add filters
    addOverlayFilters(type: string, data: Array<any>) {

        let _data = this.overlayFilterData;

        if (!_data[type]) {
            _data[type] = [];
        }

        _data[type].push(...data);
        console.log(_data)
        this.overlayFilterSource.next(_data);
    }

    // remove filters
    deleteOverlayFilters(type: string) {

        if (this.overlayFilterData[type]) {
            delete this.overlayFilterData[type];
        }
    }

    // update filter by index
    updateOverlayFilters(type: string, data: Object) {
        let _data = this.overlayFilterData;

        let foundIndex = _data[type].findIndex(x => x.name === data['name']);
        _data[type][foundIndex] = data;

        this.overlayFilterSource.next(_data);
    }

    // update overll filter 
    updateOvelayFiltersByKey(type: string, data: Array<any>) {
        let _data = this.overlayFilterData;

        _data[type] = data;

        this.overlayFilterSource.next(_data);
    }

    colorSectionSetter(data: any) {
        this.filterColorSectionData['colors'] = data;

        this.filterColorSectionSource.next(this.filterColorSectionData)
    }
    updateOpacity(type: string, data: any) {
        let _data = this.overlayFilterData;

        _data[type] = data

        // let foundIndex = _data[type].findIndex(x => x.name === data['name']);
        // _data[type][foundIndex] = data;

        this.overlayFilterSource.next(_data);


    }

    // --------------------------- selected filtered ------------------------------------
    updateSelectedFiltered(data) {
        this.selectedFilteredData['filterLayer'] = data;
        console.log(data, this.selectedFilteredData)
        this.selectedFilteredDataSource.next(this.selectedFilteredData);
    }


    // ---------------------------- raw images --------------------------------------------

    setRawImagesList(list: Array<any>) {
        this.rawImagesData['images'] = [];
        this.rawImagesData['images'] = list;
        this.rawImagesSource.next(this.rawImagesData)
    }

}