import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router';

// Services
import { ToastrService } from 'ngx-toastr';
import { ApiService, JwtService, UserService, SocketService } from 'src/app/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  @ViewChild('username', null) private elementRef: ElementRef;

  // form
  loginForm: FormGroup;
  formSubmit: Boolean = false;

  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private router: Router,
    private jwtService: JwtService,
    private userService: UserService,
    private socketService: SocketService) {

    // clear localstorage 
    this.jwtService.destroyToken();
    this.userService.destroySession();

    // create login form
    this.loginForm = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required])
    })
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.focus();
  }

  // submit login form
  submit() {

    // validate form
    if (!this.loginForm.valid) return this.toastr.warning('Invalid user credentials');

    // get user data
    this.formSubmit = true;
    this.apiService._post('login-user', this.loginForm.value).subscribe(response => {


      // disable form loader
      this.formSubmit = false;

      // validatte response status
      if (!response.status) return this.toastr.warning('Invalid user credentials');

      // update token and session
      this.jwtService.saveToken(response.token);

      // added user name to response for socket
      let _sessionData = response.data;
      _sessionData['username'] = this.loginForm.controls['username'].value;

      this.userService.updateSession(_sessionData);

      // create socket connection
      // this.socketService.connectSocket();
      this.decideRoute();
      // route to home with alert success on login
      // this.router.navigate(['/home'])
      this.toastr.success(response.message);

    });


  }


  // Temp route to port page if only one port is available 
  decideRoute() {
    this.apiService._get('get-ports').subscribe(response => {
      let portDetails = response.data;
      console.log(portDetails)
      if (portDetails.length == 1) {
        this.router.navigate(['/home/port'], { queryParams: { port_name: portDetails[0]['name'], port_id: portDetails[0]['id']}})
      } else {
        this.router.navigate(['/home'])
      }
    })
  }

}
