import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularImageViewerModule } from "angular-x-image-viewer";
import 'hammerjs';


// shared components
import {
  BreadcrumbComponent,
  ImageViewComponent,
  LoadingScreenComponent,
  PdfViewerComponent,
} from "./components";

// shared layout
import {
  HeaderComponent,
} from "./layout";
import { NgbPopoverModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { BsDatepickerModule, TypeaheadModule, PopoverModule, BsDropdownModule } from 'ngx-bootstrap';

import { NgxGalleryModule } from 'ngx-gallery';
import { MapModule } from '../modules/map/map.module';
import { ApprovedAssessmentComponent } from '../modules/map/components/left-sidebar/approved-assessment/approved-assessment.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    NgbPopoverModule,
    NgbModule,
    AngularImageViewerModule,
    BsDatepickerModule.forRoot(),
    NgxGalleryModule,
    TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  declarations: [
    BreadcrumbComponent,
    ImageViewComponent,
    HeaderComponent,
    LoadingScreenComponent,
    PdfViewerComponent,
  ],
  exports: [
    BreadcrumbComponent,
    ImageViewComponent,
    HeaderComponent,
    LoadingScreenComponent,
    PdfViewerComponent,
  ]
})
export class SharedModule { }
