import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';


interface IDateState {
  previousDate: string[];
  currentDate: string[];
  skip: number;
  dateRange: any;
  iteration: number
}

interface IApprovedInspection {
  status: Boolean;
  data: any[];
  isLoadMore: Boolean;
  dates: any[]
  unReadCount: number
}

@Component({
  selector: 'app-report-summary',
  templateUrl: './report-summary.component.html',
  styleUrls: ['./report-summary.component.css']
})
export class ReportSummaryComponent implements OnInit {

  reportSummary: IApprovedInspection;

  @Input() fetchUrl: string

  //card toggle
  showCardBody: Boolean = true;

  // active calendar dates 
  activeCalendarDates: any[] = []

  //select from and to date
  fromDate: any;
  toDate: any;

  approvedInspection = false;


  // init data for myapprovedInspection data
  approvedInspectionDate: IDateState = {
    skip: 0,
    currentDate: [],
    previousDate: [],
    dateRange: "",
    iteration: 0
  }

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getInspections();
  }

  toggleModal(body, optionalbody = "") {
    document.getElementById(body).classList.toggle("hide");
    document.getElementById(`${body}-toggle-arrow`).classList.toggle("up");
    if (optionalbody != "")
      document.getElementById(optionalbody).classList.toggle("hide");
  }

  displayApprovedInspection(date, pop: any) {
    pop.close()
    this.approvedInspection = true;
    this.fromDate = date.fromDate;
    this.toDate = date.toDate;
    this.reportSummaryCalendarApply()
  }

  // when user clicks on apply button on calendar
  reportSummaryCalendarApply() {
    this.approvedInspectionDate.skip = 0
    this.paginateReportSummary()
  }

  close(pop: any) {
    pop.close()
  }
  open(pop: any) {
    pop.open()
  }



  async getInspections() {
    if (this.fetchUrl) {
      // get approved inspection details
      this.apiService._get(this.fetchUrl).subscribe(response => {
        console.log("get report summary", response);
        // validatte response status
        if (!response.status)
          return this.toastr.warning(
            "Some Error in Fetching Approved Inspections"
          );
        this.activeCalendarDates = response.dates
        this.approvedInspectionDate.iteration += 1
        this.reportSummary = response
      });
    }
  }


  // Pagination for approvedInspection happens here
  paginateReportSummary() {
    this.approvedInspectionDate.currentDate = [this.fromDate, this.toDate]
    var pervArray = this.reportSummary.data;

    pervArray = this.validateApprovedInspection(pervArray)

    if (this.approvedInspectionDate.skip == 0) {
      pervArray = []
    }

    console.log(this.fromDate, this.toDate)
    const skip = this.approvedInspectionDate.skip
    const limit = 10;
    var url = "";

    if (this.fromDate && this.fromDate != "" && this.toDate != "") {
      url = `${this.fetchUrl}&skip=${skip}&limit=${limit}&fromDate=${this.fromDate}&toDate=${this.toDate}`
    } else {

      url = `${this.fetchUrl}&skip=${skip}&limit=${limit}`
    }

    this.apiService
      ._get(url)
      .subscribe(response => {
        // validatte response status
        if (!response.status)
          return this.toastr.warning("Some Error in Fetching details");

        // set approvedInspection datails
        this.activeCalendarDates = response.dates

        console.log("response", response)
        this.reportSummary.data = [
          ...pervArray,
          ...response.data
        ];
        console.log(this.reportSummary)
        //add skip
        this.approvedInspectionDate.previousDate = [this.fromDate, this.toDate]
        this.reportSummary.isLoadMore = response["isLoadMore"];
      });
  }


  // logic to setup skip and iteration count
  validateApprovedInspection(data) {
    if (this.approvedInspectionDate.iteration == 0) {
      this.approvedInspectionDate.skip = 0
      this.approvedInspectionDate.iteration += 1
      if (this.fromDate != "" && this.toDate != "") {
        return []
      }
      else {
        this.approvedInspectionDate.skip = data.length
        return data
      }
    }
    else if (this.approvedInspectionDate.currentDate[0] !== this.approvedInspectionDate.previousDate[0] || this.approvedInspectionDate.currentDate[1] !== this.approvedInspectionDate.previousDate[1]) {
      this.approvedInspectionDate.skip = 0
      this.approvedInspectionDate.iteration += 1
      return []
    }
    else {
      this.approvedInspectionDate.skip = data.length
      return data
    }

  }

  // date reset
  resetDate(data) {
    if (data == 'true') {
      this.fromDate = ""
      this.toDate = ""
      this.approvedInspection = false
      this.paginateReportSummary()
    }
  }

  openReportSummary(data) {
    console.log(data)
    const _url = data['report_url'];
    window.open(`/view-report?document=${window.btoa(_url)}`, '_blank');
  }
}
