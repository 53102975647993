import { Component, OnInit, Input, SimpleChange, HostListener, EventEmitter, Output } from '@angular/core';


import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { getCenter } from 'ol/extent';
import ImageLayer from 'ol/layer/Image';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import Draw, { createRegularPolygon, createBox } from 'ol/interaction/Draw';
// import Circle from 'ol/geom/Circle';
import Polygon from 'ol/geom/Polygon';
import { boundingExtent } from "ol/extent";

import * as OlGeom from "ol/geom";
import * as ol from "ol";
import OlVector from "ol/source/Vector";
import OlLayerVector from "ol/layer/Vector";
import Point from "ol/geom/Point";
import * as OlProj from "ol/proj";
import { Style, Fill, Stroke, Text, Circle } from "ol/style";
import { Group as LayerGroup } from "ol/layer";

// Service
import { InternalCommService, ApiService } from 'src/app/core';
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'ground-image-viewer',
  templateUrl: './ground-image-viewer.component.html',
  styleUrls: ['./ground-image-viewer.component.css']
})
export class GroundImageViewerComponent implements OnInit {

  draw: any
  source: any
  map: any
  staticImageSource: any
  loader: Boolean
  defectList: any = []

  //defect layer group

  defectLayerGroup: any
  defectLayerList: any


  // coordinates for form
  @Input() selectedImageObject: any;
  @Output() form_data = new EventEmitter<any>();
  @Output() window_loader = new EventEmitter<any>();
  @Output() selectedDefectFromViewer = new EventEmitter<any>();
  @Output() closeImageViewer = new EventEmitter<any>()
  @Output() updateDefectNumber = new EventEmitter<any>()
  @Output() autoSave = new EventEmitter<any>();

  @Input() selectedDefect: any;
  @Input() defectWizardWindow: Boolean



  // Window loader
  windowloader: Object = {
    name: "",
    loader: false
  };

  constructor(private apiService: ApiService, private toastr: ToastrService) { }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log("on resize reference", this.map)
    if (this.map) {
      this.map.updateSize();
      this.map.once('rendercompleted', e => {
        setTimeout(function () { this.map.updateSize(); }, 200);
      })
      this.map.renderSync()
    }
  }



  styleFunctionPolygon(textToDisplay) {
    return [
      new Style({
        fill: new Fill({
          color: "rgba(255,255,255,0.2)"
        }),
        stroke: new Stroke({
          color: "red",
          width: 2
        }),
        text: new Text({
          textAlign: 'end',
          textBaseline: 'alphabetic',
          placement: 'line',
          font: "26px Calibri,sans-serif",
          fill: new Fill({ color: "#f1c40f" }),
          stroke: new Stroke({
            color: "#f1c40f",
            width: 1
          }),
          text: textToDisplay
        })
      })
    ];
  }

  ngOnInit() {
    this.openRightLayout()
    this.setUpImageLayer()
    // this.addInteraction('Polygon')
    // this.drawDefect1()
    this.drawDefect()

    //     [480.5, 571]
    // 1: (2) [523.5, 508]
  }

  ngOnChanges(changes: SimpleChange) {
   
    if (changes['selectedImageObject']) {
      // //console.log("current value", changes['selectedImageObject']['currentValue'])
      this.changeImageLayer(changes['selectedImageObject']['currentValue'], 'firsttime')
    }
    if (changes['selectedDefect'] && changes['selectedDefect']['currentValue']) {
      console.log("test1", changes['selectedDefect']['currentValue'])
      if (!this.isEmptyObject(changes['selectedDefect']['currentValue'])) this.selectDefect(changes['selectedDefect']['currentValue'])
    }
  }

  isEmptyObject(obj) {
    var name;
    for (name in obj) {
      return false;
    }
    this.updateDefectNumber.emit(null)
    return true;
  }


  getCenter(arr) {
    var x = arr.map(x => x[0]);
    var y = arr.map(x => x[1]);
    var cx = (Math.min(...x) + Math.max(...x)) / 2;
    var cy = (Math.min(...y) + Math.max(...y)) / 2;
    return [cx, cy];
  }

  selectDefect(defect) {
    console.log(defect)
    if (this.map) {
      // console.log("select defect", defect, defect.defect_center[1], defect.defect_center[0], this.map)

      // let defectNewCoord = []
      // for (const def of defect.coordinates[0]) {
      //   //console.log("***************", defect)
      //   defectNewCoord.push([def[0], def[1]])
      // }
      // console.log("coordinates -- ", defectNewCoord)
      var ext = boundingExtent(defect.coordinates[0]);
      console.log("ext -- ", ext)

      this.map.getView().fit(ext, this.map.getSize(), { duration: 100 });
      this.map.getView().setZoom(this.map.getView().getZoom() - 1)
      // this.map.getView().setCenter(this.getCenter(defect.coordinates[0]))
      // this.map.getView().setZoom(defect.defect_zoom)
    }
  }

  async beforeScreenshot(defect) {
    if (this.map) {
      // console.log("select defect", defect, defect.defect_center[1], defect.defect_center[0], this.map)

      var ext = boundingExtent(defect.coordinates[0]);
    

      this.map.getView().fit(ext, this.map.getSize(), { duration: 100 });
      this.map.getView().setZoom(this.map.getView().getZoom() - 1);
      await this.map.once('rendercomplete', async e => {

        await this.autoSave.emit(true);

      })
      this.map.renderSync()
    }
  }
  removeLayers(type) {
    // get all the layers on the map
    if (this.map) {
      const layers = this.map.getLayers().getArray();
      layers.forEach((layer, i) => {
        if (layer.values_ && layer.values_.title == type) {
          this.map.removeLayer(layer);
        }
      });
    }
  }



  async changeImageLayer(object, initial) {
    var that = this
    // //console.log(object);
    //console.log(this.map)
    if (initial == 'firsttime') {

      // var extent = [0, 0, 1024, 968];
      var extent = [0, 0, 1250, 968];
      var projection = await new Projection({
        code: 'xkcd-image',
        units: 'pixels',
        extent: extent
      });
      this.staticImageSource = await new Static({
        url: object.url,
        projection: projection,
        imageExtent: extent,
        crossOrigin: 'anonymous'
      })

      await this.staticImageSource.on('imageloadstart', function () {
        console.log("image loading start")
        that.windowloader = {
          name: "Fetching Image...",
          loader: true
        }
        that.window_loader.emit(that.windowloader);
      });

      await this.staticImageSource.on('imageloadend', function () {
        console.log("image loading end", that.map, that.defectList)
        that.updateLayersOnMap()
        that.windowloader = {
          name: "Fetching Image...",
          loader: false
        }
        that.window_loader.emit(that.windowloader);

      });
      await this.staticImageSource.on('imageloaderror', function () {
        console.log("image loading erro")
        this.loader = false
        that.windowloader = {
          name: "Fetching Image...",
          loader: false
        }
        that.window_loader.emit(that.windowloader);
      });


    }
    console.log("map", this.map)
    await this.map.once('rendercomplete', async e => {
      console.log("render successfully!! change image layer")
      // this.map.getLayers().removeAt(2)
      console.log("1", this.map);
      await this.afterMapRenders(initial)
      // this.map.getLayers().removeAt(1)
      // this.map.getLayers().removeAt(0)

    })
    this.map.renderSync()




  }

  async afterMapRenders(initial) {
    var layerArray, len, layer;
    layerArray = this.map.getLayers().getArray(),
      len = layerArray.length;
    if (initial == 'firsttime') {
      while (len > 0) {

        layer = layerArray[len - 1];
        this.map.removeLayer(layer);
        len = layerArray.length;
      }
    } else {
      for (let i = len; i > 1; i--) {
        console.log(i)
        layer = layerArray[len - 1];
        this.map.removeLayer(layerArray[i]);
        this.updateLayersOnMap()
      }
    }

    this.source.clear()
    //console.log("removed", this.map)

    this.map.getLayers().insertAt(0, new ImageLayer({
      source: this.staticImageSource
    }))
    this.map.getLayers().insertAt(1, new VectorLayer({
      source: this.source
    }))
  }


  removeDefectLayer() {
    if (this.map) {
      const layers = this.map.getLayers().getArray()
      for (const layer of layers) {
        if (layer.values_ && layer.values_.title && layer.values_.title == 'defect') {
          this.map.removeLayer(layer)
        }
      }
    }
  }


  async updateLayersOnMap() {
    // that.defectList.map(defect => {
    //   that.drawDefect1(defect)
    // })
    var layerArray = []
    for (let i = 0; i < this.defectList.length; i++) {
      console.log("1");
      layerArray.push(this.drawDefect1(this.defectList[i], i + 1))
    }
    console.log("defectlayerlist", layerArray)
    this.defectLayerGroup = new LayerGroup({
      layers: layerArray,
      title: "defect"
    });
    this.map.addLayer(this.defectLayerGroup)
    console.log("this map", this.map)

  }


  drawDefect() {
    var point = new Point(

      [803.5000103679997, 543.6874533440002]
    );
    var pointFeature = new ol.Feature(point);


    var vectorSource = new OlVector({
      projection: "EPSG:4326"
    });
    vectorSource.addFeatures([pointFeature]);
    var vectorLayer = new OlLayerVector({
      source: vectorSource
    });
    // this.map.addLayer(vectorLayer);

  }
  arrayPosShift(array, pos) {
    for (let i = 0; i < pos; i++) {
      const first_element = array[0]
      array.shift()
      array.push(first_element)
    }
    return array
  }


  drawDefect1(defect, index) {
    console.log(defect)
    this.removeDefectLayer()
    console.log(JSON.stringify([...defect.coordinates[0], defect.coordinates[0][0]])
    );

    let coordinates = [...defect.coordinates[0], defect.coordinates[0][0]]
    coordinates.reverse()
    let newCoords = this.arrayPosShift(coordinates, 2)

    let lineString = new OlGeom.Polygon(
      [newCoords]
    );

    // polygon.transform("EPSG:4326", "EPSG:3857");
    let feature = new ol.Feature(lineString);
    feature.setId(defect.id)
    feature.setStyle(this.styleFunctionPolygon(index.toString()))

    let vectorSource = new OlVector();
    vectorSource.addFeature(feature)
    let vectorLayer = new OlLayerVector({
      source: vectorSource
    });
    // this.map.once('rendercomplete', e => {
    // this.map.addLayer(vectorLayer)
    return vectorLayer
    // this.defectLayerList.push(vectorLayer);
    // })


  }


  removeInteraction() {
    this.map.removeInteraction(this.draw)
  }

  addInteraction(type) {
    var value = type;
    if (value !== 'None') {
      this.draw = new Draw({
        source: this.source,
        type: 'Circle',
        geometryFunction: createBox()
      });
      this.draw.on("drawstart", e => {
        //console.log("draw start", e)
        if (e.feature.geometryChangeKey_.target.getType() == "Polygon") {
          e.feature.setStyle(this.styleFunctionPolygon(""));
        }
      });
      this.draw.on('drawend', async e => {
        //console.log(e)
        let coord = [];
        // var coordinates = e.target.sketchCoords_;
        const coordinates = e.feature.getGeometry().getCoordinates()
        // Emit coordinates for ground defect form
        this.form_data.emit({ coordinates: coordinates, defect_zoom: this.map.getView().getZoom() })

        console.log("coordinates", coordinates, this.map.getView().getZoom())
        console.log("object", this.selectedImageObject.id);


      })
      this.map.addInteraction(this.draw);
    }
  }

  undoDefect() {
    console.log(this.map)
    const source = this.map.getLayers().getArray()[1].getSource()
    const features = source.getFeatures()
    if (features.length > 0) {
      source.removeFeature(features[features.length - 1])
    }
  }





  setUpImageLayer() {
    console.log("setUPimage Layer")
    // var extent = [0, 0, 1024, 968];
    var extent = [0, 0, 1300, 1300];
    var projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: extent
    });


    this.source = new VectorSource({ wrapX: false });

    var vector = new VectorLayer({
      source: this.source
    });


    this.staticImageSource = new Static({
      attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
      url: 'https://aerodyne-abp.s3.eu-west-2.amazonaws.com/tests/file_1580723670.png',
      projection: projection,
      imageExtent: extent,
      crossOrigin: 'anonymous'
    })


    this.map = new Map({
      layers: [
        new ImageLayer({
          source: this.staticImageSource
        }), vector
      ],
      target: 'map1',
      view: new View({
        projection: projection,
        center: getCenter(extent),
        zoom: 1,
        maxZoom: 8,
        minZoom: 1,
      })
    });

    this.map.on('click', e => {
      console.log("map click event", e.coordinate)
      //console.log(this.map)
      const features = this.map.forEachFeatureAtPixel(e.pixel, feature => {
        return feature
      })
      const defect = this.defectList.filter(defect => defect.id == features.getId())
      if (defect) {
        console.log("defect selected", defect[0])
        this.selectedDefectFromViewer.emit(this.changeDefectData(defect[0]))
      }
    })

  }

  changeDefectData(data) {
    let val = data;
    let _data = { ...val };
    if (_data["defect_rating"] && _data["defect_rating"]["id"])
      _data["defect_rating"] = _data["defect_rating"]["id"];
    if (_data["repair_priority"] && _data["repair_priority"]["id"])
      _data["repair_priority"] = _data["repair_priority"]["id"];
    if (_data["activity_required"] && _data["activity_required"]["id"])
      _data["activity_required"] = _data["activity_required"]["id"];
    return _data
  }


  openRightLayout() {
    let _rightLayoutData = {}
    _rightLayoutData["show"] = true;
    _rightLayoutData["defectWizardShow"] = true;
    // this.interComm.setData("rightLayout", _rightLayoutData);
  }


  async drawDefectPolygon(defectList) {
    console.log("defect list for drawing", defectList)
    this.defectList = defectList
    // this.afterMapRenders(null)
    // this.updateLayersOnMap()
    // defectList.map(defect => {
    //   this.drawDefect1(defect)
    // })
  }

  async deletePolyGon(defectList) {
    await this.map.removeLayer(this.defectLayerGroup)
    await this.removeFeaturesinVectorLayer()
  
    await this.updateLayersOnMap()
  }

  async getDomElement() {
    return this.map.getTargetElement();
  }


  async removeFeaturesinVectorLayer() {
   
    const layer = this.map.getLayers().getArray()[1]
    const features = layer.getSource().getFeatures()
    const vectorSource = layer.getSource()
    for (const feature of features) {
      vectorSource.removeFeature(feature)
    }

  }


  closeGroundImageviewer() {
    this.closeImageViewer.emit(null)
  }
  async getCenteredScreenshot(defect) {
    if (this.map) {
      // left,bottom , right, Top
      var ext = [0, 0, 1250, 950];//boundingExtent(defect.coordinates[0]);
      this.map.getView().setZoom(1);  // this.map.getView().setZoom(this.map.getView().getZoom() - 1);
      this.map.getView().setCenter(this.getCenter(ext))  //  this.map.getView().setCenter(this.getCenter(defect.coordinates[0]))   
      this.map.getView().fit(ext, this.map.getSize(), { duration: 100 }); 
      await this.map.once('rendercomplete', async e => { 
        console.log("screenshot-centered");
      })
      this.map.renderSync();    
    }
  }
}
