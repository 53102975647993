import { Component, OnInit, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'

// Services
import { ApiService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { AssetComponent } from '../../asset/asset.component';
import htmlToImage from 'dom-to-image-more';
import { ScreenshotComponent } from '../screenshot/screenshot.component';
import { ImageListComponent } from '../image-list/image-list.component';

import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../../core/services/user.service';

@Component({
  selector: 'map-defect-wizard',
  templateUrl: './defect-wizard.component.html',
  styleUrls: ['./defect-wizard.component.css']
})
export class DefectWizardComponent implements OnInit {

  // Input decoration


  // Output decoration
  @Output() undoDefectEmitter = new EventEmitter<any>()

  @Output() refreshRecentAssessment = new EventEmitter<any>()


  // form
  defectForm: FormGroup;

  // dropdowns
  rating: Array<any> = [];
  activityRequired: Array<any> = [];
  repairPriority: Array<any> = [];

  // initial close notes
  showText: Boolean = false;
  showAdditinal: Boolean = false;

  // toggle card
  imageList: Boolean = false;
  screenShot: Boolean = false;
  // form 
  disableForm: Boolean = true;
  formSubmit: Boolean = false;
  showSpecificDate: Boolean = false;
  takeScreenShotClicked: Boolean = false;

  //
  windowloader: Object = {
    name: "",
    loader: false
  };

  map: any;
  miniMap: any;

  // user details
  userDetails;

  // port details
  portDetails = {
    id: null
  };

  // asset details
  assetDetails = {
    id: null
  };

  // inspection detials
  inspectionDetails = {
    id: null
  };

  // defect index

  defectCount: any = 1;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activeRoute.queryParamMap.subscribe(params => {
      const orderObj = { ...params['params'] };
      this.userDetails = this.userService.getSession();
      // port details
      this.portDetails['id'] = orderObj['port_id'];
      this.portDetails['name'] = orderObj['port_name'];

      // asset details
      this.assetDetails['id'] = orderObj['asset_id'];
      this.assetDetails['name'] = orderObj['asset_name'];

      // inspection detils
      if (orderObj['inspection_id']) {
        this.inspectionDetails['id'] = orderObj['inspection_id'];
        this.inspectionDetails['inspection_name'] = orderObj['inspection_name'];

      }
      console.log(this.inspectionDetails.id);
      // this.resetForm();
      // reset form on different inspection
    });
    console.log("teststssttststst");
    // this.ngOnInit();
  }

  @Output() addDrawDefect = new EventEmitter();
  @Output() getMaptarget = new EventEmitter();
  @Output() updateInspectionData = new EventEmitter();
  @Output() progressLoader = new EventEmitter();
  @Output() positionDefect = new EventEmitter();
  
  @ViewChild(ImageListComponent, null) imageListComp: ImageListComponent;
  @ViewChild(ScreenshotComponent, null) screenShotComp: ScreenshotComponent;
  ngOnInit() {


    // defect form
    this.defectForm = new FormGroup({
      id: new FormControl(null),
      coordinates: new FormControl(null, [Validators.required]),  //  defect drawn coordinates
      uploaded_image: new FormControl([]),  // Add more image - upload image url
      defect_image: new FormControl([]),  // Screenshot image url 
      structure: new FormControl(null, [Validators.required]), // Structure
      defect_type: new FormControl(null, [Validators.required]), // Defect type
      defect_rating: new FormControl(null, [Validators.required]),  // Rating
      activity_required: new FormControl(null, [Validators.required]), // Activity required
      repair_priority: new FormControl(null, [Validators.required]),  // Repair priority
      specific_date: new FormControl(null), // specified date of repair priority
      defect_desc: new FormControl(null), // Add text
      additinal_notes: new FormControl(null), // Additional notes

      inspection: new FormControl(null),  // inspection id (On new inspection not required)
      defect_zoom: new FormControl(null, [Validators.required]),  // Zoom level of defect marked

      defect_center: new FormControl(null, [Validators.required]),  // Center coordinate of defect marked

      main_saver: new FormControl(null),
      defect_status: new FormControl(null),

      user: new FormControl(null, [Validators.required]), // logged user id
      asset: new FormControl(null, [Validators.required]),  // asset id
      port: new FormControl(null, [Validators.required]), // port id
      defectIndex: new FormControl(null),  // defect number
      defect_overall_view: new FormControl(null)  // defect number

    });

    // call dropdowns
    this.getDropdowns();
    this.toggleFormControl();
    this.initForm();
  }

  initForm() {
    // watch internal comm service
    // this.internalCommService.currentData.subscribe(response => {
    // get defect image ss
    var tempDefectImage = this.defectForm.controls['defect_image'].value;

    this.defectForm.reset();

    // set form non client values
    this.defectForm.controls['port'].setValue(this.portDetails['id']);
    this.defectForm.controls['asset'].setValue(this.assetDetails['id']);
    this.defectForm.controls['user'].setValue(this.userDetails['id']);
    this.defectForm.controls['inspection'].setValue(this.inspectionDetails['id']);


    this.showSpecificDate = (this.defectForm.controls['specific_date'].value) ? true : false;


  }


  // get dropdowns
  getDropdowns(): void {
    // fetch 
    this.apiService._get('get-dropdowns').subscribe(response => {

      this.rating = response['data']['defect_rating'];
      this.activityRequired = response['data']['activity_required'];
      this.repairPriority = response['data']['repair_priority'];
      console.warn('aaa', response)
    });
  }

  beforeSave = async () => {
    // console.log("button status", this.defectForm)
    this.takeScreenShotClicked = false;
    if (this.defectForm.controls["id"].value == null) {
      this.positionDefect.emit()
      // this.saveScreenshot(true);
    } else {
      this.saveDefect();
    }
  }

  undoDefect() {
    this.undoDefectEmitter.emit(null)
  }




  saveScreenshot = async (issave = false) => {
    this.getMaptarget.emit("true");
    console.log("map", this.map);
    let formData = new FormData();

    var exportOptions = {
      filter: function (element) {
        return element.className
          ? element.className.indexOf("ol-control") === -1
          : true;
      }
    };
    this.progressLoader.emit({ "status": true, name: "Taking Screenshot" })

    await htmlToImage
      .toBlob(this.map, exportOptions)
      .then(dataURL => {
        console.log(3);
        formData.append("file", dataURL, "file");
        // this.windowloader["loader"] = true;
        if (this.takeScreenShotClicked) {
          this.progressLoader.emit({ "status": true, name: "Saving Screenshot" })
        } else {
          this.progressLoader.emit({ "status": true, name: "Saving Details" })
          this.takeScreenShotClicked = false;
        }
        // this.windowloader["name"] = "saving screenshot";

        this.apiService._post("upload-file", formData).subscribe(async (response) => {
          console.log(5);

          // turn off loader
          // this.windowloader["loader"] = false;
          if (this.takeScreenShotClicked) {
            this.progressLoader.emit({ "status": false, name: "" })
          } else {
            this.takeScreenShotClicked = false;
          }

          // validate response
          if (!response.status)
            return this.toastr.error("Some error in taking screenshot");
          let defect_image = []
          defect_image = this.defectForm.controls['defect_image'].value != null ? this.defectForm.controls['defect_image'].value : [];
          defect_image.push(...response.data);
          this.defectForm.controls['defect_image'].setValue(defect_image);
          console.log("defectForm", this.defectForm.value);
          this.getScreenshotList("event");
          if (issave) {
            await htmlToImage.toBlob(this.miniMap).then(async url => {
              let formData = new FormData();
              console.log(url)
              formData.append('file', url, 'file');
              console.log("formdata for minimap target", formData)

              await this.apiService._post('upload-file', formData).subscribe(response => {
                let defect_overall_view = response['data'][0]['url'];
                this.defectForm.controls['defect_overall_view'].setValue(defect_overall_view);
                // console.log("intercommservice after uploading the file", this.interCommService.getdata('defectWizardForm'))
                this.saveDefect();
              })

            })


          }
        });
      })
      .catch(error => {
        console.log("error", error);

        // this.windowloader["loader"] = false;
        this.progressLoader.emit({ "status": false, name: "" })
        return this.toastr.error("some error taking screenshot");
      });


  }

  saveDefect = () => {

    console.warn('for submit start');

    try {
      this.formSubmit = true;
      // await this.internalCommService.defectWizardFormSubmit(false);

      console.log(this.defectForm.valid, this.defectForm)

      // validate form 
      if (!this.defectForm.valid) {
        console.warn('for submit error');
        this.formSubmit = false;
        return this.toastr.error('Error in saving form, Some Data missing');
      }

      if (this.defectForm.controls['defect_image'].value == null) {
        this.defectForm.controls['defect_image'].setValue([]);
      }

      if (this.defectForm.controls['uploaded_image'].value == null) {
        this.defectForm.controls['uploaded_image'].setValue([]);
      }

      let formValue = this.defectForm.value;
      delete formValue['defectIndex'];

      // this.progressLoader.emit({ "status": true, name: "Saving Defect" })
      this.apiService._post('create-defect', formValue).subscribe(async response => {


        this.formSubmit = false;
        this.progressLoader.emit({ "status": false, name: "" })
        // validate response
        if (!response['status']) {
          // this.formSubmit = false; 
          return this.toastr.error(response['message']);
        }

        this.toastr.success(response['message']);
        console.warn("response['inspection']", response['inspection']);
        if (response['inspection']) {
          let _inspection = response['inspection'];
          console.log("_inspection", _inspection)
          // this.internalCommService.reloadViewInspectionSet(true);
          this.router.navigate([], {
            relativeTo: this.activeRoute,
            queryParams: { inspection_id: _inspection['id'], inspection_name: _inspection['name'] }, queryParamsHandling: 'merge'
          })


          // this.locationTrackingService.destroyRunningContainer()

        }
        this.updateInspectionData.emit("true");
        this.resetForm();
        this.toggleFormControl();


      });
    } catch (error) {
      this.progressLoader.emit({ "status": false, name: "" })
      this.formSubmit = false;
    }
  }

  // image list incoming data
  imageListIncomig(event) {

    // update upload image in form
    this.defectForm.get('uploaded_image').setValue(event.imageList);

    // close image list
    this.imageList = !event.close
  }

  getScreenshotList = (event) => {
    this.screenShotComp.imageList = this.defectForm.controls['defect_image'].value != null ? this.defectForm.controls['defect_image'].value : [];
  }
  getImageList = (event) => {
    this.imageListComp.imageList = this.defectForm.controls['uploaded_image'].value != null ? this.defectForm.controls['uploaded_image'].value : [];
  }

  addScreenShotEvent = (event) => {
    console.log("add Screenshot", event);
    this.takeScreenShotClicked = true;
    if (this.takeScreenShotClicked) this.saveScreenshot();
  }

  addDefect = () => {
    this.defectForm.reset();
    this.toggleFormControl();
    // set form non client values
    this.defectForm.controls['port'].setValue(this.portDetails['id']);
    this.defectForm.controls['asset'].setValue(this.assetDetails['id']);
    this.defectForm.controls['user'].setValue(this.userDetails['id']);
    this.defectForm.controls['inspection'].setValue(this.inspectionDetails['id']);
    this.defectForm.controls['defect_image'].setValue([]);
    this.defectForm.controls['uploaded_image'].setValue([]);
    this.screenShotComp.imageList = [];
    console.log("this.inspectionDetails['id']", this.inspectionDetails['id']);
    console.log("this.defectForm", this.defectForm.value);
    this.addDrawDefect.emit("true");
  }

  removeImage(event) {
    console.log("Remove Image", event)
    if (event.type == "screenshot") {
      let defect_image: Object[] = this.defectForm.controls['defect_image'].value
      defect_image.splice(event.index, 1);
      this.defectForm.controls['defect_image'].setValue(defect_image);
      this.getScreenshotList("event");
    }
  }


  toggleFormControl() {
    let formControl = [
      'defect_rating', 'defect_type', 'repair_priority', 'specific_date', 'activity_required',
      'structure', 'defect_status', 'defect_image', 'uploaded_image',
      'defect_desc', 'additinal_notes', 'main_saver'
    ]
    if (this.defectForm.controls['coordinates'].value == null || this.defectForm.controls['coordinates'].value == []) {
      formControl.forEach(key => {
        this.defectForm.get(key).disable();
      });
      this.disableForm = true;
    } else {

      this.disableForm = false;
      formControl.forEach(key => {
        this.defectForm.get(key).enable();
      })
    }
  }

  // reset form and intercom service
  resetForm() {

    // clear form
    this.defectForm.reset();
    this.toggleFormControl();
    // this.defectForm.updateValueAndValidity();




  }

  // on change of repair priority - update specific date validators
  onChangeRepairPriority(data) {

    if (this.repairPriority.length > 0) {
      // find the selected object
      let selectedObj = this.repairPriority.find(x => x.id == data);
      console.log(selectedObj, this.repairPriority)
      // valdiate
      if (selectedObj['name'].toLowerCase() === "s - specific date") {

        // add validators
        this.defectForm.controls['specific_date'].setValidators(Validators.required);
        this.showSpecificDate = true;
      } else {
        this.defectForm.controls['specific_date'].clearValidators();
        this.defectForm.controls['specific_date'].updateValueAndValidity();
        this.defectForm.controls['specific_date'].setValue(null);
        this.showSpecificDate = false;
      }

    }
    console.log(this.defectForm.controls['specific_date'])
  }


}
