import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'map-screenshot',
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.css']
})
export class ScreenshotComponent implements OnInit {

  // Output dectoration
  @Output() close = new EventEmitter<Boolean>();
  @Output() removeScreenshot = new EventEmitter();
  @Output() ScreenshotList = new EventEmitter();
  @Output() addImage = new EventEmitter();

  // image list urls
  imageList: Array<Object> = [];

  // image viewer
  imageViewerData = {
    show: false,
    heading: '',
    image: null
  }
  
  constructor() { }

  ngOnInit() {
    this.getScreenshotList();
  }

  done() {
    // emit to close
    this.close.emit(true);
  }
  getScreenshotList = () => {
    this.ScreenshotList.emit("true");

  }
  removeUrlImage = (index) => {
    let data = {
      index: index,
      type: "screenshot"
    }
    this.removeScreenshot.emit(data);
  }

  takeScreenShot = () => {
    console.log("taking ScreenShot");
    this.addImage.emit("true");
  }

  // image select - open image viewer
  onImageSelect(image) {
    this.imageViewerData.show = true;
    this.imageViewerData.heading = image.file_name;
    this.imageViewerData.image = image.url;
  }
}
