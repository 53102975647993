import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

// service
import { ApiService } from "src/app/core/services";

//internal communication service

import { InternalCommService } from "src/app/core/services";
import value from "*.json";
import { ToastrService } from "ngx-toastr";
import { throwError } from "rxjs";
import { timingSafeEqual } from 'crypto';
import { error } from 'protractor';
import { NgbModal, ModalDismissReasons, NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-defect-list",
  templateUrl: "./defect-list.component.html",
  styleUrls: ["./defect-list.component.css"]
})
export class DefectListComponent implements OnInit {
  // inspection detials

  @Output() outGenerateReport = new EventEmitter();
  @Output() defectListLength = new EventEmitter();
  @Output() selectedDefect = new EventEmitter();
  @Output() refreshRecentAssessment = new EventEmitter()
  @Output() refreshApprovedInspection = new EventEmitter();
  @Output() loader = new EventEmitter();
  @Output() defectDeleted = new EventEmitter();

  inspectionDetails: any = {
    id: null
  };

  // select defect
  _selectedDefect = null;

  //buttons enable
  generateReportButton: boolean = false;
  sendToPublish: boolean = false;

  // table loader
  tableLoader: Boolean = true;

  constructor(
    private internalCommService: InternalCommService,
    private apiService: ApiService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
  }
  defectList: any = [];
  dropDowns: any = [];
  dropDownsExtra: any = [];
  filterData: any = {};

  bottomLayout: Object = {
    show: false
  };
  layoutToggle: Object = {
    bottomShow: true
  };
  selectedFile: File;
  selectedFileUrl;
  executiveSummary;
  fileLabel: string;
  activeParams;
  enableUpload = false;
  windowloader: Object = {
    name: "",
    loader: true
  };


  ngOnInit() {
    this.getDefectlist();
    this.getDropDownLists();
  }



  getDefectlist = async () => {
    // this.internalCommService.selectButton('defectAction','')
    if (!this.inspectionDetails["id"]) {
      await this.activeRoute.queryParamMap.subscribe(params => {
        const orderObj = { ...params["params"] };
        console.log("paramsd", orderObj);
        // disable loader
        this.tableLoader = false;
        // inspection detils
        if (orderObj["inspection_id"]) {
          this.inspectionDetails["id"] = orderObj["inspection_id"];
          this.inspectionDetails["inspection_name"] =
            orderObj["inspection_name"];
        }
      });
    }


    if (this.inspectionDetails["id"]) {
      // enable loader
      this.tableLoader = true;
      console.log("response----", this.inspectionDetails["id"])
      await this.apiService
        ._put(`get-defects-by-inspection/${this.inspectionDetails["id"]}`, {
          filterData: this.filterData
        })
        .subscribe(
          async response => {
            console.log("response----", response)
            if (response.data) {
              this.defectList = response.data;
              console.log("defect list inner", this.defectList);

              // disable loader
              this.tableLoader = false;
              // emit event for updaate Defect Index

              this.defectListLength.emit("true");
              console.warn("defect list", this.defectList);
              this.generateReportButton = !response.buttons.generate_report;
              this.sendToPublish = !response.buttons.publish_report;

              if (this.dropDownsExtra.length == 0) {
                this.dropDownsExtra = response.dropdowns;
              }
            } else {
              throw throwError(response);
            }
          },
          error => {
            // disable loader
            this.tableLoader = false;
            console.error(error);
          }
        );
    }
  };

  getDropDownLists = () => {
    this.apiService._get("get-dropdowns").subscribe(
      response => {
        this.dropDowns = response.data;
        console.warn("dropDowns", this.dropDowns);
      },
      error => {
        console.error(error);
      }
    );
  };

  changeFilterOptions = (parms, value) => {
    this.filterData[parms] = value;
    this.getDefectlist();
  };

  // save selected defect in intercomm
  saveDefectDetailsTempStore(data, index) {
    console.log(data);
    let val = data;

    let _data = { ...val };
    if (_data["defect_rating"] && _data["defect_rating"]["id"])
      _data["defect_rating"] = _data["defect_rating"]["id"];
    // if (_data["defect_type"] && _data["defect_type"]["id"])
    //   _data["defect_type"] = _data["defect_type"]["id"];
    if (_data["repair_priority"] && _data["repair_priority"]["id"])
      _data["repair_priority"] = _data["repair_priority"]["id"];
    // if (_data["structure"] && _data["structure"]["id"])
    //   _data["structure"] = _data["structure"]["id"];
    // if (_data["fault_code"] && _data["fault_code"]["id"])
    //   _data["fault_code"] = _data["fault_code"]["id"];
    // if (_data["reason_code"] && _data["reason_code"]["id"])
    //   _data["reason_code"] = _data["reason_code"]["id"];
    if (_data["activity_required"] && _data["activity_required"]["id"])
      _data["activity_required"] = _data["activity_required"]["id"];
    _data["asset"] = _data["asset"]["id"];
    _data["user"] = _data["user"]["id"];
    _data["inspection"] = _data["inspection"]["id"];
    _data["defectIndex"] = index;

    // _data['defect_type'] = data['defect_type']['id'];
    // _data['defect_rating'] = data['defect_rating']['id'];
    // _data['repair_priority'] = data['repair_priority']['id'];
    // _data['structure'] = data['structure']['id'];
    // _data['fault_code'] = data['fault_code']['id'];
    // _data['reason_code'] = data['reason_code']['id'];


    this.selectedDefect.emit(_data);
    // this.internalCommService.setData('defectWizardForm', _data);
    // this.internalCommService.setDefectNo(index + 1);

    // // update layout 
    // let _rightLayoutData = this.internalCommService.getdata('rightLayout');
    // _rightLayoutData = (_rightLayoutData) ? _rightLayoutData : {};
    // _rightLayoutData['show'] = true;
    // _rightLayoutData['defectWizardShow'] = true;
    // _rightLayoutData['imageListShow'] = false;
    // this.internalCommService.setData('rightLayout', _rightLayoutData);


  }

  saveInspection(action) {
    if (action == "save" || action == "publish") {
      action == "publish" ? this.toastr.success("Sending for publish") : "";

      let inspectionObject = {
        id: this.inspectionDetails["id"],
        type: action,
        name: this.inspectionDetails["inspection_name"]
      };
      // if (action == 'publish') {
      //   this.toastr.success('Publishing...')
      // }

      // (action == 'publish')?(this.toastr.success('')):('')
      this.apiService
        ._post("save-inspection", inspectionObject)
        .subscribe(response => {
          response.status
            ? action == "save"
              ? this.toastr.success("Inspection name updated")
              : this.toastr.success("Inspection Sent for Publishing")
            : this.toastr.warning("Try again later please.");
          this.internalCommService.selectButton(
            "defectAction",
            "reloadViewInspection"
          );
          this.getDefectlist();
          this.refreshRecentAssessment.emit(null)
        });
    } else if (action == "report") {
      console.log("Report action called");
      if (this.inspectionDetails["id"]) {
        this.generateReportButton = true;
        this.toastr.success("Generating Report...");
        this.apiService
          ._get(`generate-report/${this.inspectionDetails["id"]}`)
          .subscribe(response => {
            if (response.status) {
              this.toastr.success("Report Generated Successfully");
              this.internalCommService.selectButton(
                "defectAction",
                "reloadSavedReport"
              );
              this.getDefectlist();
            } else {
              this.generateReportButton = false;
              this.toastr.warning("Error generating report");
            }
          });
      } else {
        this.toastr.warning("Please check inspection detail");
      }
    }
  }

  // delete defect
  deleteDefect(data, list) {
    this.modalService
      .open(list, { centered: true, ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          let _body = {
            ids: []
          };
          _body["ids"].push(data.id);
      
          this.apiService._put("delete-defect", _body).subscribe(response => {
            // validate reponse
            if (!response["status"]) return this.toastr.error(response["message"]);
      
            // clear the form value in defect wizard
            this.internalCommService.setData("resetDefectForm", true);
      
            // reload the table
            this.getDefectlist();
            this.defectDeleted.emit(null)
            // alert the user
            this.toastr.success(response["message"]);
          }, error => {
            console.log(error);
          });
        }, reason => {
          console.log('false')
        })
  }

  viewDraftreportModel = () => {
    this.outGenerateReport.emit("true")
  }


  generateReport() {
    let postData = {
      id: this.inspectionDetails["id"],
      report_cover_image: '',
      summary_text: ''
    };

    this.loader.emit({
      name: "Generating Report..",
      status: true
    })


    this.apiService._post("generate-report", postData).subscribe(
      response => {
        console.log(response);
        this.loader.emit({
          name: "",
          status: false
        });
        if (response.status) {
          this.toastr.success(response.message);
          // report_url
          window.open(`/view-report?document=${window.btoa(response['report_url'])}`, '_blank');
        } else {
          this.toastr.error(response.message);
        }
      },
      err => {
        this.loader.emit({
          name: "",
          status: false
        });
        console.log(err);
      }
    );
  }
}
