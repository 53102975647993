import { Component, OnInit, Output, EventEmitter, HostListener, Input, SimpleChange } from '@angular/core';


import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { getCenter } from 'ol/extent';
import ImageLayer from 'ol/layer/Image';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Draw, { createRegularPolygon, createBox } from 'ol/interaction/Draw';
// import Circle from 'ol/geom/Circle';
import Polygon from 'ol/geom/Polygon';
import * as olControl from 'ol/control';
import * as OlGeom from "ol/geom";
import * as ol from "ol";
import OlVector from "ol/source/Vector";
import OlLayerVector from "ol/layer/Vector";
import Point from "ol/geom/Point";
import * as OlProj from "ol/proj";
import { Style, Fill, Stroke, Text, Circle as CircleStyle, Icon } from "ol/style";
import { InternalCommService, ApiService } from 'src/app/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { easeIn, easeOut } from 'ol/easing';

import OlMap from "ol/Map";
import OlXYZ from "ol/source/XYZ";
import OlTileLayer from "ol/layer/Tile";
import OlLayerVectorTile from "ol/layer/VectorTile";
import OlOSM from "ol/source/OSM";
import OlView from "ol/View";
import Feature from 'ol/Feature';
import { boundingExtent } from "ol/extent";
import { Cluster, OSM, Vector as VectorSource } from 'ol/source';
import Geolocation from 'ol/Geolocation';
import { retry } from 'rxjs/operators';
import { FilterGroundViewService } from 'src/app/core/services/filter-ground-view.service';
import { async } from '@angular/core/testing';



@Component({
  selector: 'ground-reference',
  templateUrl: './ground-reference.component.html',
  styleUrls: ['./ground-reference.component.css']
})
export class GroundReferenceComponent implements OnInit {

  draw: any
  source: any
  sourceOne: any
  map: OlMap
  asset: any
  port: any
  assetDetails: any
  coordFeatures: any
  featuresListFromDBAsObject: any = {}
  _tempFeaturesListFromDBAsObject: any = {}

  portname: string = this.activatedRoute.queryParams["_value"].port_name;
  portid: string = this.activatedRoute.queryParams["_value"].port_id;

  selectedMarker: any[] = []
  @Output() selectedMarkerEmitter = new EventEmitter();
  @Output() markerSelectedImage = new EventEmitter()
  @Input() selectedDefect: any;
  @Input() imageList: any;

  // // cluster source
  clusterSource: any
  clusterLayer: any

  styleCache: any = {};
  showFirst = true


  // cluster distance
  clusterDistance: number = 40

  defectWizardWindow: Boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.map) {
      console.log("on resize reference", this.map)
      this.map.updateSize();
      this.map.once('rendercompleted', e => {
        setTimeout(function () { this.map.updateSize(); }, 500);
      })
    }
  }



  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private filterGroundViewService: FilterGroundViewService,
    private router: Router
  ) {
    this.asset = this.activatedRoute.queryParams["_value"].asset_id;
    this.port = this.activatedRoute.queryParams["_value"].port_id;
    this.portname = this.activatedRoute.queryParams["_value"].port_name;
    this.portid = this.activatedRoute.queryParams["_value"].port_id;

  }

  async ngOnInit() {
    this.filterGroundViewService.groundViewdata.subscribe(response => {
      console.log("subscription filter ground view", response);
      if (response['filterData'] && response['filterData']['type'] == 'filter') {
        this.clusterAgain(response['filterData']['data'])
      }
      else if (response['filterData'] && response['filterData']['type'] == 'search') {
        this.searchDataByName(response['filterData']['data'])
      }
    })

    await this.downloadReportSubscribe()
    await this.getAssetDetails()
  }



  async downloadReportSubscribe() {
    this.filterGroundViewService.reportData.subscribe(response => {
      if (response['download']) {
        this.generateReport()
        this.filterGroundViewService.downloadReport(false)
      }
    })
  }


  searchDataByName(name) {
    let searchResults = []
    for (const key in this.featuresListFromDBAsObject) {
      if (this.featuresListFromDBAsObject.hasOwnProperty(key)) {
        const data = this.featuresListFromDBAsObject[key];
        console.log("searchbyname", data)
        if (data.name.toLowerCase().includes(name.toLowerCase()) || data.thumb_url.toLowerCase().includes(name.toLowerCase())) {
          searchResults.push(data)
        }
      }
    }
    // console.warn("searchResults",searchResults);
    if (name != "") {
      this.clusterAgain(searchResults);
    } else {
      this.clusterAgain(this._tempFeaturesListFromDBAsObject);
    }
    // this.selectedMarkerEmitter.emit(searchResults)
  }




  getAssetDetails = async () => {
    this.apiService._get(`get-asset-id/${this.asset}`).subscribe(
      async response => {
        //console.log("response -- ", response)
        this.assetDetails = response.asset;
        // await this.loadMapTiler();
        setTimeout(async () => {
          await this.loadMarkers()
        }, 3000);

      },
      error => {
        console.error(error);
      }
    );
  };

  async updateClusterLayerFeature(filteredData) {
    if (this.map) {
      const layers = this.map.getLayers().getArray()
      const clusterLayer = layers.filter(layer => layer.get('title') === 'clusterLayer')
      // this.map.removeLayer(clusterLayer[0])
      const updatedFeatures = await this.getClusterFeatures(filteredData)

      var source = new VectorSource({
        features: updatedFeatures
      });

      this.clusterSource = new Cluster({
        distance: this.clusterDistance,
        source: source
      });
      clusterLayer[0].setSource(this.clusterSource)
      console.log(this.featuresListFromDBAsObject)
      let featureList = []
      for (const key in this.featuresListFromDBAsObject) {
        if (this.featuresListFromDBAsObject.hasOwnProperty(key)) {
          featureList.push(this.featuresListFromDBAsObject[key]);
        }
      }
      this.selectedMarkerEmitter.emit(featureList)

    }
  }


  clusterAgain(data) {
    console.log("map", this.map)
    this.updateClusterLayerFeature(data)
  }



  loadMarkers = async () => {
    var count = 20000;
    var features = new Array(count);
    console.log("in on loadmarker", this.imageList.length);
    
    // this.apiService._get(`get-all-ground-image/${this.port}`).subscribe(response => {
    //   //console.log("load marker", response)

    //   const allMarkers = response.data
    //   this._tempFeaturesListFromDBAsObject = allMarkers;
    //   this.testClustering(allMarkers)
    //   // this.testClustering()
    //   //console.log("load marker", allMarkers)
    // })
    // let response = await this.apiService._get(`get-all-ground-image/${this.port}`).toPromise()
    const allMarkers = this.imageList
    this._tempFeaturesListFromDBAsObject = allMarkers;
    this.testClustering(allMarkers)
    // this.testClustering()
    // console.log("load marker", allMarkers)
  }



  loadMapTiler = async () => {

    // console.log('this.assetDetails.maptiler_url',this.assetDetails.maptiler_url)
    try {

      var mapExtent = await OlProj.transformExtent(
        this.assetDetails.maptiler_coordinates,
        "EPSG:4326",
        "EPSG:3857"
      );

      var source = await new OlXYZ({
        url: `${this.assetDetails.maptiler_url}{z}/{x}/{y}.png`,
        // url: this.assetDetails.maptiler_url,
        tilePixelRatio: 1.0,
        minZoom: this.assetDetails.maptiler_min_zoom,
        maxZoom: this.assetDetails.maptiler_max_zoom,
        crossOrigin: "Anonymous"
      });

      //instance for source vector
      this.sourceOne = await new OlVector({
        wrapX: false
      });

      //instance for layer vector
      var vectorLayer = await new OlLayerVector({
        source: this.sourceOne
      });

      // instance for tile layer, to be included in map
      var layer = await new OlTileLayer({
        extent: mapExtent,
        source: source,
        crossOrigin: "Anonymous"
      });

      // layer for Openstreetmap
      var osm = await new OlTileLayer({
        source: new OlOSM()
      });


      ////console.log('ortho', this.map)

      //creating instance for map, and add all vector layer and source layer
      this.map = await new OlMap({
        target: "map",
        layers: [osm, layer, vectorLayer],
        controls: olControl.defaults({ attribution: false }),
        view: new OlView({
          //  map center coordinates
          center: OlProj.fromLonLat(this.assetDetails.maptiler_lat_lng),
          zoom: this.assetDetails.default_zoom
        })
      });

      this.map.once('rendercompleted', e => {
        this.loadMarkers()
      })

    } catch (error) {
      //////console.log("404 on maptiles")
    }

  };

  isCluster(feature) {
    // console.log("check cluster", feature)
    if (!feature || !feature.get('features')) {
      return false;
    }
    return feature.get('features').length > 1;
  }




  async getClusterFeatures(allObjects) {
    try {
      // console.log(allObjects)
      this.featuresListFromDBAsObject = {}
      var count = allObjects.length;
      // console.log("all objects", allObjects);

      var features = new Array(count);
      var e = 4500000;
      for (var i = 0; i < count; ++i) {
        // console.log([allObjects[i].longitude, allObjects[i].latitude], i);

        //console.log(OlProj.transform([allObjects[i].longitude, allObjects[i].latitude], "EPSG:4326", "EPSG:3857"))
        features[i] = new Feature(new Point(OlProj.transform([allObjects[i].longitude, allObjects[i].latitude], "EPSG:4326", "EPSG:3857")));
        features[i].setId(allObjects[i].id)
        features[i].set('color', allObjects[i].defect_severity)
        features[i].set('severity', allObjects[i].severity_key)
        const newId = allObjects[i].id
        this.featuresListFromDBAsObject[newId] = allObjects[i]
      }
      return features
    } catch (error) {
      console.log("error in getting cluster features data", error)
    }
  }



  async testClustering(allObjects) {

    //mapTilers
    console.log("maptiler coordinates", this.assetDetails.maptiler_coordinates)
    // console.log("allobjects", this.assetDetails.maptiler_url, this.assetDetails.maptiler_min_zoom, this.assetDetails.maptiler_max_zoom)
    var mapExtent = await OlProj.transformExtent(
      this.assetDetails.maptiler_coordinates,
      "EPSG:4326",
      "EPSG:3857"
    );

    // console.log('Image Data -- 1',`${this.assetDetails.maptiler_url}`)

    var source = await new OlXYZ({
      // url: `${this.assetDetails.maptiler_url}{z}/{x}/{y}.png`,
      url: this.assetDetails.maptiler_url,
      tilePixelRatio: 1.0,
      minZoom: this.assetDetails.maptiler_min_zoom,
      maxZoom: this.assetDetails.maptiler_max_zoom,
      crossOrigin: "Anonymous"
    });

    //instance for source vector
    this.sourceOne = await new OlVector({
      wrapX: false
    });

    //instance for layer vector
    var vectorLayer = await new OlLayerVector({
      source: this.sourceOne
    });

    // instance for tile layer, to be included in map
    var layer = await new OlTileLayer({
      extent: mapExtent,
      source: source,
      crossOrigin: "Anonymous"
    });

    // layer for Openstreetmap
    var osm = await new OlTileLayer({
      source: new OlOSM()
    });



    let features = await this.getClusterFeatures(allObjects)
    // console.log("features -- ", features);


    var source = new VectorSource({
      features: features
    });

    this.clusterSource = new Cluster({
      distance: this.clusterDistance,
      source: source
    });

    var styleCache = {};

    const featureStyle = (feature) => {
      const initialFeature = feature.get('features')[0]
      var size = feature.get('features').length;
      var style
      const colorCode = hexToRgbA(initialFeature.get('color'), 0.6)
      if (!style) {
        style = new Style({
          image: new CircleStyle({
            radius: 10,
            stroke: new Stroke({
              // width: 4,
              color: '#fff'
            }),
            fill: new Fill({
              color: colorCode
            })
          }),
          // text: new Text({
          //   text: size.toString(),
          //   fill: new Fill({
          //     color: '#fff'
          //   })
          // })
        });
      }
      return style;
    }
    const getColorParentfeature = (feature) => {
      const features = feature.get('features')
      let selectedFeature;

      let maxSeverity = 0
      for (const feature of features) {
        // console.log(feature)
        // console.log(feature.get('severity') > maxSeverity, feature.get('severity'), maxSeverity);

        if (feature.get('severity') > maxSeverity) {
          maxSeverity = feature.get('severity')
          selectedFeature = feature
        }
      }
      if (maxSeverity == 0) {
        selectedFeature = features[0]
      }
      // console.log(selectedFeature.get('color'));

      return selectedFeature.get('color')
    }




    const hexToRgbA = (hex, opacity) => {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
      }
      throw new Error('Bad Hex');
    }

    this.clusterLayer = new VectorLayer({
      source: this.clusterSource,
      style: function (feature) {
        var size = feature.get('features').length;
        var style
        if (size === 1) return featureStyle(feature)
        let colorCodeCluster = hexToRgbA(getColorParentfeature(feature).toLowerCase(), 0.5)
        if (!style) {
          style = new Style({
            image: new CircleStyle({
              radius: 14,
              stroke: new Stroke({
                width: 4,
                color: '#fff'
              }),
              fill: new Fill({
                color: colorCodeCluster,
              })
            }),
            text: new Text({
              text: size.toString(),
              fill: new Fill({
                color: '#fff'
              })
            })
          });
        }
        return style;
      }
    });

    this.clusterLayer.set('title', 'clusterLayer')
    var raster = new TileLayer({
      source: new OSM()
    });


    //dynamic cluster testing




    let mapView = new View({
      center: OlProj.transform(this.assetDetails.maptiler_lat_lng, "EPSG:4326", "EPSG:3857"),
      zoom: 18
    })

    this.map = new Map({
      layers: [osm, layer, vectorLayer, this.clusterLayer],
      target: 'map',
      view: mapView
    });

    this.map.on('click', e => {
      const features = this.map.forEachFeatureAtPixel(e.pixel, feature => {
        return feature
      })
      // console.log("features", features, features.get('features').length)
      if (features.get('features').length > 1) this.zoomToSpecificBound(features)
      else {
        // console.log(this.featuresListFromDBAsObject[features.get('features')[0].getId()])
        this.markerSelectedImage.emit(this.featuresListFromDBAsObject[features.get('features')[0].getId()])
      }
      this.updateClusterData()
    })


    this.map.getView().on('change:resolution', e => {

    })

    // this.addFeaturesToCluster(allObjects)


    //geolocation

    var geolocation = new Geolocation({
      // take the projection to use from the map's view
      projection: mapView.getProjection()
    });

    geolocation.on('change', function (evt) {
      //console.log(geolocation.getPosition());
    });




    //console.log("map", this.map)
    //console.log("get features", clusters.getSource())
    const allfeatures = this.clusterLayer.getSource().getFeatures()
    //console.log("features -- all", allfeatures)

    this.map.once('rendercomplete', e => {
      //console.log("this.map", this.map)
      const allfeatures = this.clusterLayer.getSource().getFeatures()
      //console.log("features -- all", allfeatures)
      this.map.on('moveend', e => {
        this.updateClusterData()
      })
    })

  }



  zoomToSpecificBound(features) {

    let arrayOfFeatures = this.extractfeatures([features])
    // console.log("arrayoffeatures", arrayOfFeatures)
    let coordinates = []
    for (const feature of arrayOfFeatures) {
      // console.log(this.featuresListFromDBAsObject[feature.getId()])
      const featureObject = this.featuresListFromDBAsObject[feature.getId()]
      coordinates.push(OlProj.transform([featureObject.longitude, featureObject.latitude], "EPSG:4326", "EPSG:3857"))
    }
    var ext = boundingExtent(coordinates);
    // ext = OlProj.transformExtent(ext, OlProj.get('EPSG:4326'), OlProj.get('EPSG:3857'));
    // console.log("map size", this.map.getSize())
    const mapSizeWithPadding = [this.map.getSize()[0] + 100, this.map.getSize()[1] + 100]

    let view = this.map.getView();
    let extent = view.calculateExtent(this.map.getSize());
    //hold the current resolution
    let res = view.getResolution();
    //if you use older versions of ol3 use `fitExtent` istead of `fit`

    view.fit(ext, this.map.getSize(), { duration: 2500 });

    const zoomVal = this.map.getView().getZoom()
    setTimeout(() => {
      this.map.getView().setZoom(zoomVal - 3)
    })

  }


  updateClusterData() {

    //console.log("get view", this.map.getView())
    const zoomVal = this.map.getView().getZoom()
    // console.log(zoomVal)
    if (zoomVal > 25) {
      // console.log(this.clusterSource)
      this.clusterSource.setDistance(1)
    }
    let extent = this.map.getView().calculateExtent(this.map.getSize())
    //console.log("vector source", clusters.getSource())
    const afeatures = this.clusterLayer.getSource().getFeatures()
    const vectorSource = this.clusterLayer.getSource()
    // vectorSource.getFeaturesInExtent(extent, function (feature) {
    //   // do something
    //   //console.log("intersect", feature)
    // });
    const featuresInExtent = vectorSource.getFeaturesInExtent(extent)
    // console.log("----")
    //console.log("----", afeatures)
    this.selectedMarker = []
    const extractAllfeatures = this.extractfeatures(featuresInExtent)
    // console.log("extractAllFeatures", extractAllfeatures);
    // console.log("selected marker", this.selectedMarker)
    this.selectedMarkerEmitter.emit(this.selectedMarker)
  }



  addFeaturesToCluster(featuresData) {
    const lengthOfFeatures = featuresData.length
    let ext = this.map.getView().calculateExtent(this.map.getSize())
    let features = []
    for (let i = 0; i < lengthOfFeatures; i++) {
      features[i] = new Feature(new Point(OlProj.transform([featuresData[i].longitude, featuresData[i].latitude], "EPSG:4326", "EPSG:3857")));
      features[i].setId(featuresData[i].id)
      const newId = featuresData[i].id
      this.featuresListFromDBAsObject[newId] = featuresData[i]
    }
  }


  generateReport() {
    console.log(this.featuresListFromDBAsObject)
    let ids = []
    for (const key in this.featuresListFromDBAsObject) {
      ids.push(key)
    }
    //sameple pdf
    const url = "https://aerodyne-abp.s3.eu-west-2.amazonaws.com/inspection-report/inspection_1582119057341.pdf"
    window.open(`/view-report?document=${window.btoa(url)}`, '_blank');

  }


  backpage() {
    this.router.navigate(["home/port"], {
      queryParams: {
        port_name: this.portname,
        port_id: this.portid
      }, queryParamsHandling: "merge"
    });
  }


  getStyle(feature, resolution) {
    var size = feature.get('features').length;
    var style
    var svg = '<svg height="496pt" viewBox="0 0 496 496.00168" width="496pt" xmlns="http://www.w3.org/2000/svg"><path d="m360 256-112-248-104 248 104-40zm0 0" fill="#57a4ff"/><g fill="#004fac"><path d="m247.890625 0c-3.183594.046875-6.035156 1.972656-7.265625 4.90625l-104 248c-1.242188 2.957031-.601562 6.367188 1.628906 8.671875 2.234375 2.300781 5.625 3.046875 8.617188 1.894531l101.234375-38.941406 109.207031 39c.863281.3125 1.773438.472656 2.6875.46875 2.714844 0 5.246094-1.378906 6.714844-3.660156 1.472656-2.28125 1.691406-5.152344.574218-7.625l-112-248c-1.289062-2.921875-4.207031-4.777344-7.398437-4.714844zm2.796875 208.472656c-.859375-.308594-1.769531-.46875-2.6875-.472656-.980469 0-1.953125.179688-2.871094.53125l-86.503906 33.277344 89.648438-213.765625 96.6875 214.09375zm0 0"/><path d="m488 240h-16.296875c-3.070313-85.421875-54.433594-161.671875-132.4375-196.621094-4.035156-1.804687-8.765625.003906-10.570313 4.039063-1.800781 4.035156.007813 8.765625 4.042969 10.566406 72.25 32.371094 119.90625 102.90625 122.964844 182.015625h-31.703125c-4.417969 0-8 3.582031-8 8 0 4.421875 3.582031 8 8 8h31.800781c-4.304687 108.515625-91.285156 195.496094-199.800781 199.800781v-31.800781c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v31.800781c-108.511719-4.304687-195.496094-91.285156-199.800781-199.800781h31.800781c4.417969 0 8-3.578125 8-8 0-4.417969-3.582031-8-8-8h-31.703125c3.0625-79.109375 50.714844-149.644531 122.96875-182.015625 4.03125-1.800781 5.84375-6.53125 4.039063-10.566406-1.800782-4.035157-6.535157-5.84375-10.566407-4.039063-78.007812 34.949219-129.367187 111.199219-132.441406 196.621094h-16.296875c-4.417969 0-8 3.582031-8 8 0 4.421875 3.582031 8 8 8h16.199219c4.328125 117.34375 98.457031 211.472656 215.800781 215.800781v16.199219c0 4.421875 3.582031 8 8 8s8-3.578125 8-8v-16.199219c117.34375-4.328125 211.472656-98.457031 215.800781-215.800781h16.199219c4.417969 0 8-3.578125 8-8 0-4.417969-3.582031-8-8-8zm0 0"/></g></svg>'
    if (!style) {
      style = new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({
            width: 2,
            color: '#fff'
          }),
          fill: new Fill({
            color: 'rgba(73, 223, 244,0.9)'
          })
        }),
        text: new Text({
          text: size.toString(),
          fill: new Fill({
            color: '#fff'
          })
        })
      });
    }
    return style;
  }


  setUpCluster() {

    //setup cluster source
  }

  extractfeatures(features) {
    // console.log("extract features", features)
    let featuresArray = []
    for (const feature of features) {
      if (this.isCluster(feature)) {
        // console.log("clustered features", feature)
        var childFeatures = feature.get('features');
        // console.log("child features", childFeatures)

        for (let i = 0; i < childFeatures.length; i++) {
          if (this.isCluster(childFeatures[i])) this.extractfeatures(childFeatures)
          else {
            featuresArray.push(childFeatures[i])
            // console.log(childFeatures[i].getId());
            this.selectedMarker.push(this.featuresListFromDBAsObject[childFeatures[i].getId()])
          }
        }
      }
      else {
        // console.log(feature.get('features')[0].getId());

        this.selectedMarker.push(this.featuresListFromDBAsObject[feature.get('features')[0].getId()])
      }
    }

    return featuresArray
  }


  zoomTOSpecificCoord(coord) {
    // console.log("zoomTOSpecificCoord", coord, this.map)
    this.map.getView().setCenter(OlProj.transform([coord.longitude, coord.latitude], "EPSG:4326", "EPSG:3857"))
    // this.map.panTo(OlProj.transform([coord.longitude, coord.latitude], "EPSG:4326", "EPSG:3857"))
    this.map.getView().setZoom(22)
  }

  setCluster(value) {
    this.clusterDistance = value
    this.clusterLayer.getSource().setDistance(value)
  }

  showGroupUngroupData(data) {
    // group => true , Ungroup => false
    console.log("group, Ungroup", data);
    if (!data) {
      console.log("this.map", this.map);
      this.setCluster(0)
    }
    else {
      this.setCluster(40)

    }
  }

}
