import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";


interface IRecentUploads {
  lastAddedCount: number;
  data: any[];
  dates: any[];
  isLoadMore: Boolean
}

interface IDateState {
  previousDate: string[];
  currentDate: string[];
  skip: number;
  dateRange: any;
  iteration: number
}



@Component({
  selector: 'recent-uploads',
  templateUrl: './recent-uploads.component.html',
  styleUrls: ['./recent-uploads.component.css']
})
export class RecentUploadsComponent implements OnInit {

  //test 
  recentUploadData: IRecentUploads

  // api ur
  @Input() fetchUrl: string

  //toggle
  showCardBody: Boolean = false
  recentUploads: Boolean


  fromDate: string = ""
  toDate: string = ""


  recentUploadsDate: IDateState = {
    skip: 0,
    currentDate: [],
    previousDate: [],
    dateRange: "",
    iteration: 0
  }


  activeDatesForCalendar : any[] = []


  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getRecentUploads()
  }

  getRecentUploads() {

    this.apiService._get(this.fetchUrl).subscribe(response => {
      // validatte response status
      if (!response.status)
        return this.toastr.warning(
          "Some Error in Fetching recent uploads details"
        );

      console.log("recent uploads", response);
      this.activeDatesForCalendar = response.dates
      this.recentUploadData = response
    });
  }



  resetDate(data) {
    if (data == 'true') {
      this.fromDate = ""
      this.toDate = ""
      this.recentUploads = false

      this.paginateRecentUploads()
    }
  }


    // called when user click on calendar close
    close(pop: any) {
      pop.close()
    }
    open(pop: any) {
      pop.open()
    }
  

  paginateRecentUploads() {
    this.recentUploadsDate.currentDate = [this.fromDate, this.toDate]
    var pervArray = this.recentUploadData.data;

    pervArray = this.validateRecentUploadState(pervArray)

    if (this.recentUploadsDate.skip == 0) {
      pervArray = []
    }

    console.log(this.fromDate, this.toDate)
    const skip = this.recentUploadsDate.skip
    const limit = 10;
    var url = "";
    console.log(skip, pervArray);

    if (this.fromDate && this.fromDate != "" && this.toDate != "") {
      url = `${this.fetchUrl}&skip=${skip}&limit=${limit}&fromDate=${this.fromDate}&toDate=${this.toDate}`
    } else {
      url = `${this.fetchUrl}&skip=${skip}&limit=${limit}`;
    }

    this.apiService._get(url).subscribe(response => {
      console.log("recentuploads", response);

      // validatte response status
      if (!response.status)
        return this.toastr.warning("Some Error in Fetching details");

      console.log(response.data.length);
      this.activeDatesForCalendar = response.dates


      // set notification datails
      this.recentUploadData.lastAddedCount = response.lastAddedCount;
      this.recentUploadData.data = [
        ...pervArray,
        ...response.data
      ];
      //add skip
      this.recentUploadsDate.previousDate = [this.fromDate, this.toDate]
      this.recentUploadData.isLoadMore = response["isLoadMore"];
    });
  }


  validateRecentUploadState(data) {
    if (this.recentUploadsDate.iteration == 0) {
      this.recentUploadsDate.skip = 0
      this.recentUploadsDate.iteration += 1
      if (this.fromDate != "" && this.toDate != "") {
        return []
      }
      else {
        this.recentUploadsDate.skip = data.length
        return data
      }
    }
    else if (this.recentUploadsDate.currentDate[0] !== this.recentUploadsDate.previousDate[0] || this.recentUploadsDate.currentDate[1] !== this.recentUploadsDate.previousDate[1]) {
      console.log(1);
      
      this.recentUploadsDate.skip = 0
      this.recentUploadsDate.iteration += 1
      return []
    }
    else {
      console.log(2);
      
      this.recentUploadsDate.skip = data.length
      return data
    }

  }


  displayRecentUploads(date, pop: any) {
    pop.close()
    this.recentUploads = true;
    this.fromDate = date.fromDate;
    this.toDate = date.toDate;
    this.recentUploadCalendarApply();
  }

  recentUploadCalendarApply() {
    this.recentUploadsDate.skip = 0
    this.paginateRecentUploads()
  }

  // Redirect page
  redirectPage = (data, page) => {
    console.log('444', data)
    if (page === "asset") {
      this.router.navigate(["home/port/asset"], {
        queryParams: {
          port_name: data.port,
          port_id: data.portId,
          asset_name: data.asset,
          asset_id: data.assetId
        }
      });
    }
    if (page === "approve") {
      this.router.navigate(["home/port/asset"], {
        queryParams: {
          port_name: data.port,
          port_id: data.portId,
          asset_name: data.asset,
          asset_id: data.assetId,
          is_approve: true
        }
      });
    } else if (page === "port") {
      this.router.navigate(["home/port"], {
        queryParams: { port_name: data.port, port_id: data.portId }
      });
    }
  };
}
