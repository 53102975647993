import { Component, OnInit, Input, SimpleChange, Output, EventEmitter } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'ground-defect-list',
  templateUrl: './ground-defect-list.component.html',
  styleUrls: ['./ground-defect-list.component.css']
})
export class GroundDefectListComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  @Output() applyFilters = new EventEmitter();
  @Output() deleteDefect = new EventEmitter();
  @Output() selectedDefect = new EventEmitter();

  @Input() dropDowns: any = [];
  filterData: any = {};
  defectList: any = [];
  tableLoader: Boolean = false;
  ngOnInit() {

  }
  changeFilterOptions = (parms, value) => {
    this.filterData[parms] = value;
    this.applyFilters.emit(this.filterData)
  };


  deleteDefectData = (data, list) => {
    this.modalService
    .open(list, { centered: true, ariaLabelledBy: "modal-basic-title" })
    .result.then(
      result => {
        this.deleteDefect.emit(data);
      }, error => {
        console.log('false')
      })
  }

  selectDefect = (data, index) => {

    let val = data;
    let _data = { ...val };
    if (_data["defect_rating"] && _data["defect_rating"]["id"])
      _data["defect_rating"] = _data["defect_rating"]["id"];
    if (_data["repair_priority"] && _data["repair_priority"]["id"])
      _data["repair_priority"] = _data["repair_priority"]["id"];
    if (_data["activity_required"] && _data["activity_required"]["id"])
      _data["activity_required"] = _data["activity_required"]["id"];
    _data["index"] = index;
    console.warn("3333",data, index)
    this.selectedDefect.emit(_data);
  }


}
