import { Component, OnInit, Output, EventEmitter } from '@angular/core';

// Servcie
import { ApiService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'map-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.css']
})
export class ImageListComponent implements OnInit {

  // Output dectoration
  @Output() imageListOutput = new EventEmitter<Object>();

  // image list urls
  imageList: Array<Object> = [];

  // image render & upload
  imageUpload: Array<any> = [];
  imageRender: Array<any> = [];

  // loader
  windowloader = {
    loader: false,
    name: ''
  }

  // image viewer
  imageViewerData = {
    show: false,
    heading: '',
    image: null
  }

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    // for (let i = 0; i < 50; i++) {
    //   this.imageList.push(
    //     {
    //       file_name: '1',
    //       loader: true,
    //       url: `https://i.picsum.photos/id/${i + 1}5${i}/1024/720.jpg`
    //     }
    //   )
    // }
  }


  // error handel on broken image
  errorHandler(event) {
    console.debug(event);
    event.target.src = "../../../../../../assets/images/no-image.jpg";
  }

  // delete image in list/render
  deleteImage(index, type) {
    if (type === 'url') this.imageList.splice(index, 1);
    if (type === 'render') {
      this.imageRender.splice(index, 1);
      this.imageUpload.splice(index, 1);
    }
  }

  // file input
  onSelectFile(event) {

    // if multiple files available
    if (event.target.files && event.target.files[0]) {

      // get file length
      var filesLength = event.target.files.length;

      // render image in list and store image file in uploads array
      for (let i = 0; i < filesLength; i++) {
        // save image in array
        this.imageUpload.push(event.target.files[i]);

        // render image
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageRender.push(event.target.result);
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }

    event.target.value = '';
  }

  // save new uploaded images
  async saveImages() {
    console.warn(1)
    // turn on loader
    this.windowloader["loader"] = true;
    this.windowloader["name"] = 'uploading images';

    // declare formdata
    let formData = new FormData();

    // append daata formdata
    await this.imageUpload.map(data => formData.append('file', data, 'file'));

    // upload image
    await this.apiService._post('upload-file', formData).subscribe(response => {


      // validate response
      if (!response.status) return this.toastr.error('Some error in taking screenshot');

      // clear uploads and render
      this.imageRender = [];
      this.imageUpload = [];

      // append in list
      this.imageList = [...this.imageList, ...response.data];

      // this.done()

      // // turn of loader
      this.windowloader.loader = false;
      this.windowloader.name = ''
    });


  }

  done() {
    // emit to close
    this.imageListOutput.emit({ close: true, imageList: this.imageList });
  }

  // image select - open image viewer
  onImageSelect(image, index) {
    this.imageViewerData.show = true;
    this.imageViewerData.heading = image.file_name || `image ${index+1}`;
    this.imageViewerData.image = image.url || image;
  }

}
