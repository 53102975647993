import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Auth gaurd
import { AuthGuard } from 'src/app/core/services/auth-gaurd.service';

// Components
import { PortListComponent } from './port-list/port-list.component';
import { AssetManagementComponent } from './asset-management/asset-management.component';

const mapRoutes: Routes = [
    { path: '', component: AssetManagementComponent , canActivate: [AuthGuard] },
]

@NgModule({
    imports: [
        RouterModule.forChild(mapRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class assetManagementRouteModule { }