import { Component, OnInit } from '@angular/core';
import * as papaparse from 'papaparse';

// service
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../core/services';

import { UserService } from 'src/app/core';

interface IjsonArray {
  name: string;
  url: string;
  thumb_url: string,
  port: string,
  elevation: number,
  tags: string,
  latitude: number,
  longitude: number
}
@Component({
  selector: 'asset-mang-ground-view',
  templateUrl: './ground-view.component.html',
  styleUrls: ['./ground-view.component.css']
})
export class GroundViewComponent implements OnInit {

  // ground view json array
  jsonArray: IjsonArray[] = []

  // Header 
  fileHeader = ['name', 'tags', 'url', 'thumb_url', 'latitude', 'longitude', 'port', 'elevation'];

  // Ground data
  groundData: IjsonArray[] = []

  // Ground form
  groundForm: FormGroup;
  groundFormModalView: Boolean = true;
  submitted: Boolean = false;

  // select data
  isSelect: Boolean = false;

   // window loader
   windowloader: Object = {
    name: "",
    loader: false
  };

  // Port 
  portId: string = null;

  // contains selected facades list
  deleteFacadeImages = [] 

  displayUpload : boolean = false;
  fileType = "pdf";
  filePdf: Boolean = true;
  selectedValue: any;
  fileData: any;
  assests : any;
  UploadAsesstsData : Array<any> = [];

  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private userServices : UserService
  ) { }

  ngOnInit() {

    // Formgroup
    this.groundForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      url: new FormControl(null, [Validators.required]),
      thumb_url: new FormControl(null, [Validators.required]),
      port: new FormControl(this.portId, [Validators.required]),
      elevation: new FormControl(null, [Validators.required]),
      tags: new FormControl(null, [Validators.required]),
      latitude: new FormControl(null, [Validators.required]),
      longitude: new FormControl(null, [Validators.required])
    });

    this.getGroundData()
    // this.getAssetsData()

  }

  // get form control
  get groundFormControl() {
    return this.groundForm.controls;
  }

  // CSV to json Array
  async onInputCSV(files: File[]) {
    if (!this.portId) return this.toastr.error('Port not found');

    let file_header = ['port', 'elevation']
    if (files[0]) {
      papaparse.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        transformHeader: (h) => {
          file_header.push(h);
          console.log(h)
          return h;
        },
        complete: (result, file) => {

          this.jsonArray = result.data.map(x => {

            x['port'] = this.portId;
            x['elevation'] = (x['elevation']) || 0;
            x['selected'] = false
            return x
          })


          if (!this.validateCSVHeaders(file_header)) {
            this.toastr.error('Invalid file format');
          } else {
            document.getElementById('csvUploadTableBtn').click();
          }
        }
      });


    }
  }


  // validation for csv file header
  validateCSVHeaders(csv_headers: Array<string>) {
    let present = this.fileHeader.map(x => (csv_headers.includes(x)));
    return !present.includes(false);
  }

  // toggle Select and un select
  toggleAll(checked) {
    console.log(checked)
    this.groundData.map(x => x['selected'] = checked);
    console.log(this.groundData)

  }

  // getting ids of selected facades
  deleteSelected(facade) {
    let selectedFacade = this.groundData.filter(x => x['selected'] == true);
    for (const key in selectedFacade) {
      if (selectedFacade.hasOwnProperty(key)) {
        const element = selectedFacade[key];
        this.deleteFacadeImages.push(element['id'])
      }
    }
    console.warn("this.deleteFacadeImages", this.deleteFacadeImages);
    if (this.deleteFacadeImages.length > 0) {
      this.delete(this.deleteFacadeImages, facade)
    }
  }

  cancelClicked() {
    // reset array if cancel is clicked
    this.deleteFacadeImages = []
  }

  // get port based ground data
  getGroundData() {
    this.apiService._get(`get-all-ground-image/${this.portId}`).subscribe(response => {

      if (!response['status']) return this.toastr.error('Some error in getting data');

      this.groundData = response['data']
      console.log("this.groundData",this.groundData)
    })
  }

  // save json array 
  save() {
    this.windowloader = {
      name: "Loading...",
      loader: true
    };

    // call api to save 
    this.apiService._post('create-ground-image', this.jsonArray).subscribe(response => {

      console.log(response);
      this.windowloader["loader"] = false;

      if (!response['status']) return this.toastr.error(response['message']);

      this.groundData = this.jsonArray;

      this.jsonArray = [];

      document.getElementById('csvUploadTableBtn').click();
    })

  }

  // Cancel to save csv file 
  cancel() {

    this.jsonArray = []
  }

  // Edit
  edit(data) {
    this.groundForm.patchValue(data);
  }

  // update
  update() {
    this.apiService._put(`update-facade-view`, this.groundForm.value).subscribe(response => {

      if (!response['status']) return this.toastr.error('Some error in getting data');

      // reload list
      this.getGroundData()

      // alert the user
      this.toastr.success(response["message"]);
    }, error => {
      console.log(error);
    })
  }

  delete(id, facade) {
    this.modalService
      .open(facade, { centered: true, ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          let _body = {
            ids: []
          };
          if (this.deleteFacadeImages.length > 0) {
            for (const key in this.deleteFacadeImages) {
              if (this.deleteFacadeImages.hasOwnProperty(key)) {
                const element = this.deleteFacadeImages[key];
                _body["ids"].push(element);
              }
            }
          } else {
            _body["ids"].push(id);
          }
          this.apiService._put("delete-ground", _body).subscribe(response => {
            // validate reponse
            if (!response["status"]) return this.toastr.error(response["message"]);

            // alert the user
            this.toastr.success(response["message"]);
            // reload the list
            this.getGroundData();
            // empty delete array
            this.deleteFacadeImages = [];
          }, error => {
            console.log(error);
          });
        }, reason => {
          console.log('false');
        })
  }

  switchFile(e) {
    if (e.target.defaultValue == "pdf") {
      this.filePdf = true;
      this.selectedValue = null;
      this.fileData = null;
    } else {
      this.filePdf = false;
      this.selectedValue = null;
      this.fileData = null;
    }
  }

 

  onFileChange(fileInput) {
    console.log(fileInput)
    this.fileData = fileInput.target.files[0];
    console.log(this.fileData)
 
  }

  saveDetails(){

    const formData = new FormData();
        formData.append("file", this.fileData);
        formData.append("port", this.portId);
        formData.append("asset_id", this.assests.id);
        formData.append("asset_name", this.assests.name);
        formData.append("type", 'video');
        formData.append("page", '');
        // console.log(">>>>", this.selectedValue, this.curPage);

        console.warn(formData);
        this.apiService
          ._post("insert-port-attachment", formData)
          .subscribe((response) => {
            // validatte response status
            if (!response.status)
              return this.toastr.error("File upload failed");
            else this.toastr.success(response.message);
            this.getAssetsData();
            this.fileData = null;
            // this.getAttachments();
          });
  }

  getmainAssetsData() {
    this.apiService._get(`get-asset-by-port/${this.portId}`).subscribe(response => {

      if (!response['status']) return this.toastr.error('Some error in getting data');
      console.warn("GROUNG ", response);

      this.assests = response['asset']
      this.getAssetsData()
    })
  }

  getAssetsData(){
    this.apiService._get(`get-asset-attachment?asset=${this.assests.id}&type=video`).subscribe(response => {

      if (!response['status']) return this.toastr.error('Some error in getting data');
      this.UploadAsesstsData = response.data
      // console.log('2.3.4.5.67.8',this.UploadAsesstsData)
    })
  }

  deleteVideo(id){
    this.apiService
    ._delete(`delete-port-attachment?id=${id}`)
    .subscribe((response) => {
      if (!response.status)
        return this.toastr.error("File Delete failed");
      else this.toastr.success(response.message);
      this.getAssetsData();
    });
   
  }
}
