import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-site-information',
  templateUrl: './site-information.component.html',
  styleUrls: ['./site-information.component.css']
})
export class SiteInformationComponent implements OnInit {

  // contains all data for site information
  siteInformationData: any = []


  @Output() siteInformationDataEmit = new EventEmitter<any>();

  // url from parent component to fetch data for inspection highlights
  @Input() fetchUrl: string
  showCardBody: boolean = true

  constructor(private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getSiteInfoDetails()
  }

  //api call to get site information details
  getSiteInfoDetails() {
    if (this.fetchUrl) {
      this.apiService._get(this.fetchUrl).subscribe(response => {

        // // validate response status
        // if (!response.status)
        //   return this.toastr.warning(
        //     "Some Error in Fetching site information details"
        //   );
        this.siteInformationData = response['sideBarDetails']['inspectionDetails'];
        this.siteInformationDataEmit.emit(this.siteInformationData)
      });
    }
  }
}
