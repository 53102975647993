import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-assessment-highlights',
  templateUrl: './assessment-highlights.component.html',
  styleUrls: ['./assessment-highlights.component.css']
})
export class AssessmentHighlightsComponent implements OnInit {

  // for datatable
  dtOptions: DataTables.Settings = {};

  //contains all assessment highlights data
  assessmentHighlightsData: any = []

  //contains all Inspection Data
  inspectionData: any = []

  //card toggle
  showCardBody: Boolean = true;

  // for testing
  testCount: any = [
    "1", "2"
  ]

  // contains all defect found data
  defectFoundData: any = []

  // contains all total count data
  totalCountData = {}

  totalSeverityDefectCount: any = null;

  // url from parent component to fetch data for assessment highlights
  @Input() fetchUrl: string

  constructor(private apiService: ApiService, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.getAssessmentHighlightsData();
    this.getDefectFoundData();

    // for datatable
    this.dtOptions = {
      paging: false, searching: false,
      scrollY: '20vh',
      scrollX: true,
      scrollCollapse: true,
      autoWidth: false
    };
  }

  // assessment highlights
  // called initially on ngonit
  async getAssessmentHighlightsData() {
    if (this.fetchUrl) {
      // console.log(this.fetchUrl);
      // get My Notifications details
      await this.apiService._get(this.fetchUrl).subscribe(response => {
        // console.warn("22222", response);
        // validate response status
        if (!response.status)
          return this.toastr.warning(
            "Some Error in Fetching Inspection highlight details"
          );
        this.assessmentHighlightsData = response;
        this.inspectionData = response['inspectionData']
        this.inspectionData.isLoadMore = response['isLoadMore']
      });
    }
  }

  // getting defect found table data
  getDefectFoundData() {
    this.apiService._get(`get-insp-details`).subscribe(response => {
      // console.warn("11111", response);
      // validate response status
      if (!response.status)
        return this.toastr.warning(
          "Some Error in Fetching Defect Found details"
        );
      for (const data of response['data']) {
        this.defectFoundData.push(data)
      }
      for (const data of response['facade']) {
        this.defectFoundData.push(data)
      }
      for (const data of this.inspectionData) {
        if (data['mode'] == "facade-view") {
          let result = this.defectFoundData.find(x => { if (x.inspectionName == data.portName) return x; });
          if (result) {
            data['assetId'] = result.assetId;
            data['assetName'] = result.assetName;
            data['type'] = result.type;
            data['tag'] = result.tag;
            data['date'] = result.inspectionDate;
            data['inspectionName'] = result.inspectionName;
          }
        }
      }
      // console.warn("Final", this.defectFoundData);
      this.getTotalCountData()
      this.getTotalSeverityCountData()
    });
  }

  getTotalCountData() {
    this.totalCountData['minor'] = 0
    this.totalCountData['Significant'] = 0
    this.totalCountData['Major'] = 0
    this.totalCountData['Replace'] = 0
    this.totalCountData['Refurbish'] = 0
    this.totalCountData['Maintain'] = 0
    this.totalCountData['No Activity'] = 0
    this.totalCountData['A - Immediate'] = 0
    this.totalCountData['B - 1 Week'] = 0
    this.totalCountData['C - 1 Month'] = 0
    this.totalCountData['D - 3 Months'] = 0
    this.totalCountData['S - Specific'] = 0
    this.totalCountData['E - data'] = 0
    this.totalCountData['F - data'] = 0
    for (const data of this.defectFoundData) {
      this.totalCountData['minor'] += data['1.Minor Defects']
      this.totalCountData['Significant'] += data['2.Significant Defects']
      this.totalCountData['Major'] += data['3.Major Defects']
      this.totalCountData['Replace'] += data['Replace']
      this.totalCountData['Refurbish'] += data['Refurbish']
      this.totalCountData['Maintain'] += data['Maintain']
      this.totalCountData['No Activity'] += data['No Activity Required']
      this.totalCountData['A - Immediate'] += data['A - Immediate']
      this.totalCountData['B - 1 Week'] += data['B - 1 Week']
      this.totalCountData['C - 1 Month'] += data['C - 1 Month']
      this.totalCountData['D - 3 Months'] += data['D - 3 Months']
      this.totalCountData['E - data'] += data['E - 6 Months']
      this.totalCountData['F - data'] += data['F - Annual']
      this.totalCountData['S - Specific'] += data['S - Specific Date']
    }

    let totalCountDefect = this.totalCountData;
    let sum = 0;
    if (totalCountDefect) {
      for (let key in totalCountDefect) {
        if (totalCountDefect.hasOwnProperty(key)) {
          var value = totalCountDefect[key];
          sum = sum + Number(value);
        }
      }
    }
    this.assessmentHighlightsData["defectInspected"] = sum;
  }

  getTotalSeverityCountData() {
    let totalSevCount = 0;
    for (const data of this.defectFoundData) {
      totalSevCount += data['1.Minor Defects']
      totalSevCount += data['2.Significant Defects']
      totalSevCount += data['3.Major Defects']
    }
    this.totalSeverityDefectCount = totalSevCount;
  }

  paginateInspectionData() {
    const pervArray = this.inspectionData
    const skip = pervArray.length;
    const limit = 10;

    // get inspection details
    this.apiService
      ._get(
        `${this.fetchUrl}skip=${skip}&limit=${limit}`
      )
      .subscribe(response => {
        // validatte response status
        if (!response.status)
          return this.toastr.warning("Some Error in Fetching details");

        // set inspection datails
        this.inspectionData = [
          ...pervArray,
          ...response['inspectionData']
        ];
        this.inspectionData.isLoadMore = response["isLoadMore"];
      });
  }

  // Redirect page
  redirectPage = (data, page) => {
    if (page === "asset") {
      this.router.navigate(["home/port/asset"], {
        queryParams: {
          port_name: data.portName,
          port_id: data.portId,
          asset_name: data.assetName,
          asset_id: data.assetId
        }
      });
    }
    if (page === "approve") {
      this.router.navigate(["home/port/asset"], {
        queryParams: {
          port_name: data.portName,
          port_id: data.portId,
          asset_name: data.assetName,
          asset_id: data.assetId,
          is_approve: true
        }
      });
    } else if (page === "port") {
      this.router.navigate(["home/port"], {
        queryParams: { port_name: data.portName, port_id: data.portId }
      });
    }
  };

  // navigation for top view inspection
  getInspectionData(data) {
    this.router.navigate(["home/port/asset"], {
      queryParams: {
        port_name: data['portName'],
        port_id: data['portId'],
        asset_name: data['assetName'],
        asset_id: data['assetId'],
        inspectionName: data['inspectionName'],
        inspectionId: data['inspectionId'],
        showInspection: 'show'
      }, queryParamsHandling: "merge"
    });
  }

  // navigation for ground image defect list
  navigateToGround(data) {
    this.router.navigate(["home/port/ground"], {
      queryParams: {
        port_name: data['portName'],
        port_id: data['portId'],
        asset_name: data['assetName'],
        asset_id: data['assetId'],
        // image_id: "0719038f-9ead-4ef2-958b-27c1309c2691"
      }, queryParamsHandling: "merge"
    });
  }

  // navigation to facade view with filter
  navigateToFacadeViewWithFilter(data, severity, tag) {
    return;
    if (data.type == 'Facade View') {
      this.router.navigate(["home/port/ground"], {
        queryParams: {
          port_name: data['inspectionName'],
          port_id: data['portId'],
          asset_name: data['assetName'],
          asset_id: data['assetId'],
          filterSeverity: severity,
          filterTag: tag
        }, queryParamsHandling: "merge"
      });
    }
  }
  navigateToFacadeViewWithFilterView(data, severity, tag) {   
    if (data.type == 'Facade View') {
      this.router.navigate(["home/port/ground"], {
        queryParams: {
          port_name: data['inspectionName'],
          port_id: data['portId'],
          asset_name: data['assetName'],
          asset_id: data['assetId'],
          filterSeverity: severity,
          filterTag: tag
        }, queryParamsHandling: "merge"
      });
    }
  }

}
