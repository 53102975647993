import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {

  // Emit
  @Output() outAssessmentMode = new EventEmitter<Boolean>();
  @Output() outRecentAssessmentMode = new EventEmitter<Boolean>();
  @Output() outApprovedAssessmentMode = new EventEmitter<Boolean>();

  // toggle card body
  showCardBody: Boolean = true;

  // toggle assessment mode
  assessmentMode: Boolean = false;
  
  // toggle outRecentAssessment mode
  recentAssessmentMode: Boolean = false;

  // toggle outApprovedAssessment mode
  approvedAssessmentMode: Boolean = false;

  constructor() { }

  ngOnInit() {
  }

  // toggle assessment mode
  toggleAssessmentMode() {
    // this.assessmentMode = !this.assessmentMode;
    
    // emit 
    this.outAssessmentMode.emit(this.assessmentMode);
  }

   // toggle assessment
   toggleAssessment() {
    this.assessmentMode = !this.assessmentMode;
  }

  // toggle recent assessment
  recentAssessmentClick() {
    this.recentAssessmentMode = !this.recentAssessmentMode

    //emit
    this.outRecentAssessmentMode.emit(this.recentAssessmentMode)
  }
  
  // toggle approved assessment
  approvedAssessmentClick() {
    this.approvedAssessmentMode = !this.approvedAssessmentMode

    //emit
    this.outApprovedAssessmentMode.emit(this.approvedAssessmentMode)
  }

  toggleAssessmentBack() {
    this.assessmentMode = false;

    this.outAssessmentMode.emit(this.assessmentMode);
  }

}
