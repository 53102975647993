import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// components
import { LoginComponent } from './components/login/login.component';

// routes
import { AuthenticationRoutesModule } from './authentication-routing.module';
@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthenticationRoutesModule
  ],
  exports: [
    LoginComponent
  ],
  providers:[]
})
export class AuthenticationModule { }
