import { Component, OnInit, HostListener, ViewChild, Injectable, Inject, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
// Services
import { ApiService, UserService } from 'src/app/core';
import { ToastrService } from "ngx-toastr";
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as DownloadFile from "downloadjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";


// Components
import { GroundImageViewerComponent } from './ground-image-viewer/ground-image-viewer.component';
import { GroundReferenceComponent } from './ground-reference/ground-reference.component';
import { ActivatedRoute } from '@angular/router';
import { GroundDefectListComponent } from './ground-defect-list/ground-defect-list.component';

// Html to image
import domtoimage from 'dom-to-image-more';
import { Router } from "@angular/router";
import { HeaderService } from 'src/app/core/services/header.service';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


// Date picker - formate date to DD/MM/YYYY
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {



  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {
      let date = value.split(this.DELIMITER);
      result = {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return result;
  }

  format(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      result = date.day + this.DELIMITER + date.month + this.DELIMITER + date.year;
    }
    return result;
  }
}


@Component({
  selector: 'app-ground',
  templateUrl: './ground.component.html',
  styleUrls: ['./ground.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class GroundComponent implements OnInit {

  // image list array
  imageList: Array<any> = [];
  imageList_dynamic: Array<any> = [];
  portDetailsall: Array<any> = [];
  captured_date: any;
  generateimagecount: any = 0;
  tagsnames: Array<any> = [];
  selectedObject: any = null;
  topbutton: boolean = false;
  dropDownslist: any;
  defectsListObject: any;
  filterData: any;

  loadMorenumberStart : any = 0;
  loadMorenumberEnd : any = 150;

  // image viewer
  imageViewerData = {
    show: false,
    heading: '',
    image: null
  }



  // selectedObject: string = null
  enableGallery: Boolean = false
  disableForm: Boolean
  showText: Boolean

  formSubmit: Boolean = false;
  showAdditinal: Boolean
  defectWizardWindow: Boolean;

  public fileSwitch = "image";

  @ViewChild(GroundImageViewerComponent, null) imageViewerComp: GroundImageViewerComponent;
  @ViewChild(GroundReferenceComponent, null) imageReferenceComp: GroundReferenceComponent;
  @ViewChild(GroundDefectListComponent, null) defectListComp: GroundDefectListComponent;
  @ViewChild('list', null) private list: NgbModal
  @ViewChild('scroll', null) public scroll: ElementRef;

  @HostListener('window:resize', ['$event'])

  videoData: any = []


  onResize(event) {
    this.imageReferenceComp.onResize(null)
    this.imageViewerComp.onResize(null)
  }

  // defect form
  defectListFromGetDefectListMethod: any[]
  defectForm: FormGroup;
  defectNumber: number = 1;
  filesToUpload: Array<File> = [];
  coordinates: Array<any> = [null];
  formImageList: Array<any> = [];
  formImageRender: Array<any> = [];
  formImageUpload: Array<any> = [];

  //  defect form dropdown list
  ratingList: Array<any> = [];
  activityRequiredList: Array<any> = [];
  repairPriorityList: Array<any> = [];

  // defect form - toggle specifi date field
  showSpecificDate: Boolean = false;

  // Toggle Screenshot view
  showScreenShot: Boolean = false;

  // Toggle Add more image view
  showAddImages: Boolean = false;

  // Window loader
  windowloader: Object = {
    name: "",
    loader: false
  };

  // Port details
  portDetails = {
    id: null,
    name: null
  }

  // Asset details
  assetDetails = {
    id: null,
    name: null
  }

  // image id for navigation from home
  imageId: any

  // selected defect data for show the defect marker
  selectedDefect: any;

  // download pdfreport property detail:
  public checkAll: Boolean = false;
  public checkFlag: Boolean = false;
  public downFlag: Boolean = false;
  public checkedValues = [];
  public finalContentImg = [];
  tmpA = [];
  tmpB = [];
  tmpC = [];

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private headerService: HeaderService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal

  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // get query parmas
    this.getQueryParams();
  }


  ngOnInit() {


    // Defect Form
    this.defectForm = new FormGroup({
      id: new FormControl(),
      defect_type: new FormControl(null, [Validators.required]),
      defect_rating: new FormControl(null, [Validators.required]),
      repair_priority: new FormControl(null, [Validators.required]),
      specific_date: new FormControl(null),
      activity_required: new FormControl(null, [Validators.required]),
      structure: new FormControl(null, [Validators.required]),
      defect_status: new FormControl(null),
      defect_image: new FormControl([]),
      uploaded_image: new FormControl([]),
      coordinates: new FormControl([], [Validators.required]),
      defect_zoom: new FormControl(null, [Validators.required]),
      defect_center: new FormControl(null, [Validators.required]),
      defect_desc: new FormControl(null),
      additinal_notes: new FormControl(null),
      main_saver: new FormControl(null),
      user: new FormControl(this.userService.getSession()['id'], [Validators.required]),
      ground_image: new FormControl(null, [Validators.required]),
      port: new FormControl(this.portDetails.id, [Validators.required]),
      defect_overall_view: new FormControl(''),
    });

    // get query parmas
    this.getQueryParams();

    // fetch images
    this.getImageList();

    // fetch dropdowns
    this.getDropDownList();

    //get poertdetails
    this.getports()
    this.getcaptured_date()

    setTimeout(() => {
      this.toggleFormControl();

    }, 500);

    this.getAssetsData()

  }

  compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }


  // window loader
  windowLoader(e) {
    console.log(e)
    this.windowloader = e;
  }

  // get active route queryparams data
  getQueryParams() {
    this.activeRoute.queryParamMap.subscribe(params => {
      const orderObj = { ...params['params'] };

      // port details
      this.portDetails['id'] = orderObj['port_id'];
      this.portDetails['name'] = orderObj['port_name'];

      // asset details
      this.assetDetails['id'] = orderObj['asset_id'];
      this.assetDetails['name'] = orderObj['asset_name'];

      // getting image id for navigation from home
      this.imageId = params['params']['image_id'];

    });
  }

  // get image list based on port id
  async getImageList() {
    let url = `get-all-ground-image/${this.portDetails.id}`
    // this.apiService._get(url).subscribe(response => {
    //   console.log("getImagelist", response['data']);

    //   if (!response['status']) return this.toastr.error(response["message"]);

    //   this.tagsname(response['data']);

    //   this.imageList = response['data'].map(x => { x.show = false; x.loader = true; return x });
    //   //console.log(this.imageList)
    //   this.getImageData()

    // });
    let response = await this.apiService._get(url).toPromise()
    if (!response['status']) return this.toastr.error(response["message"]);

    

    // this.tagsname(response['data']);

    this.imageList = response['data'].map(x => { x.show = false; x.loader = true; return x });
    this.imageList_dynamic = this.imageList.slice(this.loadMorenumberStart, this.loadMorenumberEnd);
    this.tagsname(this.imageList_dynamic);
    // console.log(this.imageList_dynamic)
    this.getImageData()
  }

  // getting image data for navigation from home
  getImageData() {
    let data = this.imageList.find(x => x.id == this.imageId)
    console.warn("imageData >>>>>>", data);
    if (data) this.selectedImage(data);
  }

  viewAllImages = () => {
    this.getImageList();
    if (this.imageReferenceComp.map) {
      this.imageReferenceComp.map.getView().setZoom(this.imageReferenceComp.assetDetails.default_zoom - 1);
    }

  }



  // error handel on broken image
  errorHandler(event) {
    // console.debug(event);
    event.target.src = "../../../../../assets/images/no-image.jpg";
  }

  // Get dropdown list
  getDropDownList() {
    this.apiService._get('get-dropdowns').subscribe(response => {
      this.dropDownslist = response["data"];
      // Rating list
      this.ratingList = response['data']['defect_rating'];

      // Activity required list
      this.activityRequiredList = response['data']['activity_required'];

      // Repair Priority
      this.repairPriorityList = response['data']['repair_priority'];

    });


  }

  // on change of repair priority - update specific date validators
  onChangeRepairPriority(data) {

    if (this.repairPriorityList.length > 0) {

      // find the selected object
      let selectedObj = this.repairPriorityList.find(x => x.id == data);

      // valdiate
      if (selectedObj['name'].toLowerCase() === "s - specific date") {

        // add validators and show the specific date field
        this.defectForm.controls['specific_date'].setValidators(Validators.required);
        this.showSpecificDate = true;

      } else {
        // clear validators, update form field with value 'null' & hide the specific date field
        this.defectForm.controls['specific_date'].clearValidators();
        this.defectForm.controls['specific_date'].updateValueAndValidity();
        this.defectForm.controls['specific_date'].setValue(null);
        this.showSpecificDate = false;
      }

    }
  }


  //drawDefect


  addDefect() {
    this.defectForm.reset()
    this.defectForm.updateValueAndValidity()
    // this.imageViewerComp.removeLayers('defect')
    this.imageViewerComp.addInteraction('Polygon')
    this.defectForm.controls['port'].setValue(this.portDetails.id)
    this.defectForm.controls['ground_image'].setValue(this.selectedObject['id']);
    this.defectForm.controls['user'].setValue(this.userService.getSession()['id']);
    this.defectForm.controls['defect_image'].setValue([]);
    this.defectForm.controls['uploaded_image'].setValue([]);
    this.defectNumber = this.defectListComp.defectList.length + 1;

    this.toggleFormControl();
  }

  getcaptured_date = () => {
    this.apiService._get(`get-asset-by-port/${this.portDetails.id}`).subscribe(response => {
      if (response['status'] && response.asset && response.asset.captured_date != null)
        this.captured_date = response.asset.captured_date;
      else
        this.captured_date = ''

      console.log(this.captured_date)
    }, error => {
      console.log("error", error);
    })

  }
  getports = () => {
    this.apiService._get('get-ports').subscribe(response => {
      this.portDetailsall = response.data;
    }, error => {
      console.log("error", error);
    })
  }

  updateDefectNumber(event) {
    this.defectNumber = this.defectListComp.defectList.length + 1;
  }

  defectDeleted() {
    this.defectNumber = this.defectListComp.defectList.length + 1;
    this.defectForm.reset()
    this.toggleFormControl();
  }

  downloadImage(image) {
    console.log("downloadImage", image)
    this.toastr.info('Fetching Image for Download')
    DownloadFile(image.url)
  }

  undoDefect() {
    this.imageViewerComp.undoDefect();
    // this.defectForm.get('coordinates').setValue([])
    // this.toggleFormControl()
  }

  // toggle image lis - screenshot or image updload
  toggleImageList(data) {

    // clear render data
    this.formImageRender = [];
    this.formImageUpload = [];

    // validate and update form image list
    if (data === 'screenshot') {
      let ss_images = (this.defectForm.controls['defect_image'].value) ? this.defectForm.controls['defect_image'].value : [];
      this.formImageList = [...this.formImageList, ...ss_images]; //[...this.formImageList, ...ss_images];
      this.showScreenShot = true;
      this.showAddImages = false;
    } else if (data === 'addimages') {
      let up_images = (this.defectForm.controls['uploaded_image'].value) ? this.defectForm.controls['uploaded_image'].value : [];
      this.formImageList = [...this.formImageList, ...up_images];
      this.showScreenShot = false;
      this.showAddImages = true;
    } else {
      if (!this.showAddImages && this.showScreenShot) {
        this.defectForm.controls['defect_image'].setValue(this.formImageList);
      }
      if (this.showAddImages && !this.showScreenShot) {
        this.defectForm.controls['uploaded_image'].setValue(this.formImageList);
      }
      this.formImageList = [];
      this.showScreenShot = false;
      this.showAddImages = false;
    }

    // Add loader to formlist
    if (this.formImageList.length > 0) this.formImageList = this.formImageList.map(x => { x.loader = true; return x });
    console.log(this.formImageList)
  }

  // file input
  onSelectFile(event) {

    // if multiple files available
    if (event.target.files && event.target.files[0]) {

      // get file length
      var filesLength = event.target.files.length;

      // render image in list and store image file in uploads array
      for (let i = 0; i < filesLength; i++) {
        // save image in array
        this.formImageUpload.push(event.target.files[i]);

        // render image
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.formImageRender.push(event.target.result);
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }

    event.target.value = '';
  }

  // update form coordinate, defect zoom, defect center
  setCoordinate(event) {
    console.log("event.coordinates", event.coordinates);
    this.defectForm.controls['coordinates'].setValue(event.coordinates);
    this.defectForm.controls['defect_zoom'].setValue(event.defect_zoom);
    this.defectForm.controls['defect_center'].setValue(this.getCenter(event.coordinates));
    this.toggleFormControl();
  }

  getCenter(arr) {
    arr = [...arr]
    var x = arr.map(x => x[0]);
    var y = arr.map(x => x[1]);
    var cx = (Math.min(...x[0]) + Math.max(...x[0])) / 2;
    var cy = (Math.min(...y[0]) + Math.max(...y[0])) / 2;
    console.log([cx, cy])
    return [cx, cy];
  }

  // Save images
  async saveImages() {

    // show loader
    this.windowloader = {
      name: 'saving images',
      loader: true
    }

    // declare formdata
    let formData = new FormData();

    // append daata formdata
    await this.formImageUpload.map(data => formData.append('file', data, 'file'));

    // upload image
    await this.apiService._post('upload-file', formData).subscribe(response => {

      this.windowloader['loader'] = false;

      // validate response
      if (!response.status) return this.toastr.error('Some error in taking screenshot');

      console.warn(this.formImageList)
      // updated list
      this.formImageList.push(...response.data);
      console.warn(this.formImageList)

      // clear render data
      this.formImageRender = [];
      this.formImageUpload = [];

      this.showScreenShot = false;
      this.showAddImages = true;


    });

  }
  // Before save decide screenshot or save normal
  async beforeSave() {
    if (!this.defectForm.controls['id'].value) {
      this.imageViewerComp.beforeScreenshot(this.defectForm.value);
      // this.screenshotMap(true);
    } else {
      this.saveForm();
    }
  }
  // save form
  async saveForm() {
    console.log(this.defectForm);
    this.imageViewerComp.removeInteraction();

    this.formSubmit = true;
    this.windowloader = {
      name: 'saving details',
      loader: true
    }

    if (!this.defectForm.valid) return this.toastr.error('Form invalid!, Some data missing');

    await this.apiService._post('create-ground-defect', this.defectForm.value).subscribe(async response => {

      if (!response['status']) return this.toastr.error(response['message']);

      this.toastr.success(response['message']);
      this.windowloader['loader'] = false;
      this.formSubmit = false;
      this.headerService.reloadFacadeReport('reloadFacadeReport')
      await this.getDefectlist();
      // await this.imageViewerComp.afterMapRenders()
      // await this.imageViewerComp.updateLayersOnMap();
      // setTimeout(() => {




      //   this.imageViewerComp.changeImageLayer(this.selectedObject, null);

      // }, 500);
      this.formImageList = [];
      this.defectForm.reset();
      this.toggleFormControl()

      this.defectForm.updateValueAndValidity()
      this.defectForm.controls['port'].setValue(this.portDetails.id)
      this.defectForm.controls['ground_image'].setValue(this.selectedObject['id']);
      this.defectForm.controls['user'].setValue(this.userService.getSession()['id']);
      this.defectForm.controls['defect_image'].setValue([]);
      this.defectForm.controls['uploaded_image'].setValue([]);


    })
  }


  // screen shot the map element
  async screenshotMap(autoSave?: boolean) {

    console.clear();
    this.windowloader = {
      name: "Screenshot progressing",
      loader: true
    }
   
    if (!this.defectForm.controls['id'].value || this.selectedDefect) {
      this.imageViewerComp.getCenteredScreenshot(this.defectForm.value);
    }    
  //  return
    // declare formdata
    let formData = new FormData();

    // export options for html-to-image.
    var exportOptions = {
      filter: function (element) {
        return element.className
          ? element.className.indexOf("ol-control") === -1
          : true;
      }
    };

    let dom = await this.imageViewerComp.getDomElement();

    await domtoimage.toBlob(dom).then(dataURL => {

      formData.append("file", dataURL, "file");
      console.log(formData)

      this.windowloader = {
        name: "saving screenshot",
        loader: true
      }

      this.apiService._post("upload-file", formData).subscribe(response => {
        console.log(4, response);

        // turn off loader
        this.windowloader = {
          name: "",
          loader: false
        }

        // validate response
        if (!response.status)
          return this.toastr.error("Some error in taking screenshot");

        // Save to form image list
        this.formImageList.push(...response.data);

        if (autoSave) {
          this.defectForm.controls['defect_image'].setValue(this.formImageList);
          this.saveForm();
        }

      });
    }).catch(error => {


      this.windowloader = {
        name: "",
        loader: false
      }
      console.log(error)

      return this.toastr.error("some error taking screenshot");
    });
  }

  selectedImage(image) {
    // console.log("selected Image >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", image)

    // adding image id in query params for navigation from home
    this.router.navigate([], {
      queryParams: {
        image_id: image['id']
      }, queryParamsHandling: "merge"
    });

    this.enableGallery = true

    this.selectedObject = image;
    this.defectWizardWindow = true
    this.imageReferenceComp.defectWizardWindow = true;

    this.defectForm.reset();
    this.toggleFormControl();
    this.showAddImages = false;
    this.showScreenShot = false;

    // this.imageReferenceComp.zoomTOSpecificCoord({ latitude: image.latitude, longitude: image.longitude })

    // this.defectListComp['dropDowns'] = this.dropDownslist;

    // add to form
    this.defectForm.controls['ground_image'].setValue(this.selectedObject['id']);

    // this.selectedObject = image

    this.getDefectlist();


    setTimeout(() => {
      this.imageViewerComp.onResize(null)
      this.imageReferenceComp.onResize(null)
    }, 500);
  }

  // filter data
  setFilterData = (e) => {
    this.filterData = e;
    this.getDefectlist();
  }

  setSelectedDefect = (defect) => {

    setTimeout(() => {

      this.defectWizardWindow = true;
      this.showAddImages = false;
      this.showScreenShot = false;

    }, 100);

    defect = { data: defect }
    
    this.selectedDefect = defect.data;
    this.defectNumber = defect['data']['index'] + 1;

    this.defectForm.patchValue(defect.data);
    this.showSpecificDate = (this.defectForm.controls['specific_date'].value) ? true : false;

    // toggle to form
    this.showAddImages = false;
    this.showScreenShot = false;
    this.formImageList = [];
    this.formImageRender = [];
    this.formImageUpload = [];

    console.log("selectd defect from list", defect);

    this.toggleFormControl()

  }


  closeImageViewer(event) {
    this.enableGallery = false
    // this.imageReferenceComp.onResize(event)
    setTimeout(() => {
      this.imageReferenceComp.map.updateSize()
    }, 200);
  }



  deleteDefect = (data) => {
    let _body = {
      ids: []
    };
    _body["ids"].push(data.id);
    this.apiService._put("delete-ground-defect", _body).subscribe(async response => {
      // validate reponse
      if (!response["status"]) return this.toastr.error(response["message"]);
      await this.defectDeleted();
      await this.getDefectlist();

      await this.imageViewerComp.deletePolyGon(this.defectListFromGetDefectListMethod)
      this.toastr.success(response["message"]);
    });
  }


  getDefectlist = async () => {
    this.apiService._put(`get-ground-defects-by-id/${this.selectedObject.id}`, { filterData: this.filterData }).subscribe(async (response) => {
      this.defectListComp.defectList = await response.data;
      this.defectNumber = this.defectListComp.defectList.length + 1;
      await this.imageViewerComp.drawDefectPolygon(response.data)
      this.imageViewerComp.deletePolyGon(await response.data)
    },
      error => {
        console.log("error", error)
      })
  }
  setImageList(e) {
    this.imageList = []
    this.imageList_dynamic = []

    this.loadMorenumberStart = 0 
    this.loadMorenumberEnd = 150

    for (const x of e) {
      x.show = false; x.loader = x.loader == true ? true : false;
    }
    this.tagsname(e)
    this.imageList = e;
    this.imageList_dynamic = this.imageList.slice(this.loadMorenumberStart, this.loadMorenumberEnd)
    this.tagsname(this.imageList_dynamic)
  }


  tagsname(imagedata) {
    
    imagedata.sort(function (a, b) {
      if (a.no && b.no) {
        return a.no - b.no;
      }
    })

    this.tagsnames = [];
    for (const x of imagedata) {
      this.tagsnames.push(x.tags)
    }
    this.tagsnames = [...new Set(this.tagsnames)].sort(function (a, b) {
      return a.localeCompare(b);
    })

    // console.log('this.tagsnames',this.tagsnames)
  }

  async openpopupreport() {
    this.finalContentImg = [];
    this.modalService
      .open(this.list, { centered: true, ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
        }, reason => {
          console.log('false')
        })

  }

  scrolled(el) {
    if (el.target.scrollTop > 300)
      this.topbutton = true;
    else
      this.topbutton = false;

  }

  scrollToTop() {
    this.scroll.nativeElement.scrollTop = 0;
  }
  dragEnd(e) {
    this.imageReferenceComp.onResize(null)
    this.imageViewerComp.onResize(null)
  }

  // updateButtonPostion(e) {
  //   console.warn(e, e.sizes[0] - e.sizes[1])
  //   this.downloadButtonPosition = `${e.sizes[1] + 20}%`;
  // }
  removeUrlImage = (index) => {
    this.formImageList.splice(index, 1);
  }

  removeRenderImage = (index) => {
    this.formImageRender.splice(index, 1);
    this.formImageUpload.splice(index, 1);
  }


  hideDefectWizard() {
    console.log("hide defect wizard")
    this.defectWizardWindow = false
    this.imageReferenceComp.defectWizardWindow = false;
    setTimeout(() => {

      this.imageViewerComp.map.updateSize()
      this.imageReferenceComp.map.updateSize()

    }, 200);
  }


  // image select - open image viewer
  onImageSelect(image, index) {
    this.imageViewerData.show = true;
    this.imageViewerData.heading = image.file_name || `image ${index + 1}`;
    this.imageViewerData.image = image.url || image;
  }


  toggleFormControl() {
    let formControl = [
      'structure',
      'defect_type', 'defect_rating', 'repair_priority', 'specific_date', 'activity_required',
      'defect_image', 'uploaded_image', 'defect_desc', 'additinal_notes', 'main_saver'
    ]

    console.log('here', this.defectForm.controls['coordinates'].value)
    if (this.defectForm.controls['coordinates'].value == null || this.defectForm.controls['coordinates'].value == []) {
      formControl.forEach(key => {
        console.log('key', key)
        this.defectForm.get(key).disable();
      });
      this.disableForm = true;
    } else {

      this.disableForm = false;
      formControl.forEach(key => {
        this.defectForm.get(key).enable();
      })
    }
  }


  // ** GENERATE PDF IMAGE DOCUMENTS ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
  checkImages() {
    this.checkFlag = false;
    this.downFlag = false;
    setTimeout(() => {
      this.generateimagecount = 0;
      for (const image of this.imageList) {
        if (image["checked"]) {
          this.checkFlag = true;
          this.downFlag = true;
          this.generateimagecount++;
        }
      }
      this.generateimagecount = this.imageList.filter(s => s.checked == true).length;
    }, 0);

  }
  //  ** CheckAll Img :
  checkAllImages() {
    setTimeout(() => {
      this.generateimagecount = 0;
      for (const image of this.imageList) {
        if (this.checkAll) {
          image["checked"] = true;
          this.downFlag = true;
        } else {
          image["checked"] = false;
          this.downFlag = false;
        }
      }
      this.generateimagecount = this.imageList.filter(s => s.checked == true).length;
    }, 0);
  }


  // photo report
  async generatedreportpdf() {
    this.modalService.dismissAll()
    this.toastr.info('Generating pdf for Download')
    this.windowloader['loader'] = true;
    let details = this.portDetailsall.find(x => x.id === this.portDetails.id);

    var dataimage = {
      id: this.portDetails.id,
      datas: this.finalContentImg,
      portdetails: details,
      captureddate: this.captured_date
    }
    this.apiService._post('generate-report-image', dataimage).subscribe(response => {
      this.checkResetImages();
      window.open(response.report);
    })
  }


  // ** DownloadImg as PDF :
  //   async downloadPdfImages() {
  //     this.toastr.info('Generating pdf for Download')
  //     this.windowloader['loader'] = true;
  //     var generatePdfimages=[];
  //     let i = 0;
  //     for (let [index, images] of Object.entries(this.imageList)) {
  //       if (images["checked"]) {
  //         generatePdfimages.push(images)
  //         this.imageList[index]["imgBase"] = await this.getBase64ImageFromURL(images.url);
  //         this.finalContentImg.push(images);
  //         i++;
  //       }
  //     }
  //    console.log(this.finalContentImg)
  //     //** generate pdf documentation: */
  //     this.generatePdf(this.finalContentImg);
  //   }


  // ** DownloadImg as PDF :
  async downloadPdfImages() {

    await this.openpopupreport()
    this.finalContentImg = [];
    let i = 0;
    for (let [index, images] of Object.entries(this.imageList)) {
      if (images["checked"]) {
        this.finalContentImg.push(images);
        this.imageList[index]["imgBase"] = '';
        i++;
      }
    }
  }



  async generatePdf(event) {
    console.log("current val;;;;;;;;;;;", event);
    let titlePdfName = this.portDetails['name'];

    let a = event.filter((value, index) => index % 2 === 0).map(s => s.imgBase);
    //  console.log("a",a);
    this.tmpA = [];
    for (const value of a) {
      let objA = {
        image: value,
        width: 250,
        height: 275,
        margin: [0, 0, 0, 15]
      }
      this.tmpA.push(objA);
    }

    let b = event.filter((value, index) => index % 2 !== 0).map(s => s.imgBase);
    //  console.log("b",b);
    this.tmpB = [];
    for (const value of b) {
      let objB = {
        image: value,
        width: 250,
        height: 275,
        margin: [0, 0, 0, 15]
      }
      this.tmpB.push(objB);
    }

    var dd = {
      content: [
        {
          text: titlePdfName,
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 30]
        },
        // {
        //   text: 'Facade View',
        //   fontSize: 14,
        //   alignment: 'center',
        // },
        {
          columns: [
            {
              ul: [...this.tmpA]
            },
            {
              ul: [...this.tmpB]
            }
          ]
        }
      ]
    }
    let setDownloadTitle = "Report-" + titlePdfName + "facade-view-images.pdf";
    pdfMake.createPdf(dd).download(setDownloadTitle); //download
    // pdfMake.createPdf(dd).open();
    // pdfMake.createPdf(dd).print(); //print
    this.checkResetImages();
    return true;
  }

  // ** after pdf download - reset values:;;;;;;;;;;;;;
  checkResetImages() {
    setTimeout(() => {
      for (const image of this.imageList) {
        image["checked"] = false;
        this.downFlag = false;
      }
    }, 0);
    this.windowloader['loader'] = false;
    this.finalContentImg = [];
    this.checkAll = false;
  }

  // Base 64 conversion for PDF Priview ;;;;;;;;;;;;;;;;;;;;
  async getBase64ImageFromURL(url) {
    //  console.log("url",url);
    let newX = 250;
    let newY = 250;
    return new Promise((res, rej) => {
      var img = new Image();
      img.src = url;
      img.setAttribute("crossOrigin", "Anonymous");
      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = newX; //img.width //newX;
        canvas.height = newY; //img.height; // newY;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        var dataURL = canvas.toDataURL("image/png");
        res(dataURL);
      }
      img.onerror = error => {
        rej(error);
      };

      // img.src = url;
    })

  }

  // getVideoFiles(){}

  // switchFile() {
  //   this.getVideoFiles;
  // }

  getAssetsData() {
    this.apiService._get(`get-asset-attachment?asset=${this.assetDetails['id']}&type=video`).subscribe(response => {

      if (!response['status']) return this.toastr.error('Some error in getting data');
      if (response.status) {
        for (const video of response.data) {
          video.originalFileName = decodeURIComponent(video.originalFileName);
        }
        // console.log("videoViewUrl", response);
        this.videoData = response.data
      }

    })
  }

  loadMoreFunction(string){
    this.windowloader['loader'] = true;
    if(string == 'down'){
      this.loadMorenumberStart = this.loadMorenumberStart + 150
      this.loadMorenumberEnd = this.loadMorenumberEnd + 150

      this.imageList_dynamic = this.imageList.slice(this.loadMorenumberStart, this.loadMorenumberEnd);
      this.tagsname(this.imageList_dynamic);
    }
    else{
      this.loadMorenumberStart = this.loadMorenumberStart - 150
      this.loadMorenumberEnd = this.loadMorenumberEnd - 150

      this.imageList_dynamic = this.imageList.slice(this.loadMorenumberStart, this.loadMorenumberEnd);
      this.tagsname(this.imageList_dynamic);
    }
    this.scrollToTop();
    this.windowloader['loader'] = false;
  }

  //;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

}

