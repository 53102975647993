import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { filter, sample, retry } from 'rxjs/operators';
import { Breadcrumb } from './breadcrumb.interface';
import { ApiService } from 'src/app/core/services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FilterGroundViewService } from 'src/app/core/services/filter-ground-view.service';
import { ToastrService } from 'ngx-toastr';
import { error } from 'protractor';
import { TabHeadingDirective } from 'ngx-bootstrap';
import * as DownloadFile from "downloadjs";
import { saveAs } from 'file-saver'
import * as JSZip from 'jszip'
import * as JSZipUtils from 'jszip-utils'
import * as streamSaver from 'streamsaver'
import { HeaderService } from 'src/app/core/services/header.service';
import { parse, parseUrl } from 'query-string';
import { base64StringToBlob } from "blob-util";

export enum GroundReportConst {
  GROUND_VIEW = 'GROUND_VIEW',
  ACTION = 'DOWNLOAD'
}
export enum TopReportConst {
  TOP_VIEW = 'TOP_VIEW',
  ACTION = 'DOWNLOAD'
}

interface filterData {
  id: string;
  name: string;
  type: string;
  active: Boolean;
}
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})

export class BreadcrumbComponent implements OnInit {

  assetUrl: string = this.activeRoute.queryParams["_value"].asset_id;
  portUrl: string = this.activeRoute.queryParams["_value"].port_id;
  portname: string = this.activeRoute.queryParams["_value"].portname

  //reportsummary url
  reportUrl = null;
  reportSummary;

  // filter data

  severityFilter: any[] = []
  tagsFilter: any[] = []
  annotationTypesFilter: any[] = []
  activeFilters: filterData[] = []
  displayFilterChips: filterData[] = []
  tagsArray = [];

  clause = 'AND';
  currentFilters: any = {
    tags: [],
    severity: [],
    defect_type: [],
    clause: this.clause
  }

  allIssuesSelected: Boolean

  //
  // tagsArrayFinal: any[] = [];
  // annotationTypesFinal: any[] = [];
  // severityFilterFinal: any[] = [];
  displayFilter: Boolean
  displayFilterAsset: Boolean
  displaySearchFilter: Boolean
  displayPortFilter: Boolean

  enableAutoComplete: Boolean = true;

  tagCtrl = new FormControl();

  windowloader = {
    loader: false,
    name: ''
  }

  myForm = new FormGroup({
    tags: this.tagCtrl
  });

  tags = [];

  breadcrumb: any = [];

  // // for checkbox filter
  // expanded = false;
  checkboxes: any[] = []
  allValues: any[] = [];
  checkboxValues: any[] = [];

  // breadcrumb object hold label and nav link
  port: Object = { port_name: '', port_id: '', port_nav: '' };
  site: Object = { site_name: '', site_id: '', site_nav: '' };
  asset: Object = { asset_name: '', asset_id: '', asset_nav: '' };

  // select all

  isSelectAll: Boolean = false;
  portDetails: any = {
    id: ''
  };

  searchData: any
  searchDatasites: any;

  ObjectForTagsImageUrl = {};

  // download file url
  topViewFileUrl: String = null;
  groundViewFileUrl: String = null;
  panoViewFileUrl: String = null;
  landing_image: String = null;

  downloadUrls = [];

  displayUpload : boolean = false;
  fileType = "pdf";
  filePdf: Boolean = true;
  selectedValue: any;
  fileData: any;
  filterSeverity: any = null;
  filterTag: any = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private apiService: ApiService,
    private filterGroundViewService: FilterGroundViewService,
    private toastr: ToastrService,
    private headerService: HeaderService
  ) {
    //get asset id
    this.assetUrl = this.activatedRoute.queryParams["_value"].asset_id;
    this.portUrl = this.activatedRoute.queryParams["_value"].port_id;
    this.portname = this.activatedRoute.queryParams["_value"].port_name;
    activeRoute.queryParams.subscribe(p => {
      this.filterSeverity = p.filterSeverity;
      this.filterTag = p.filterTag;
    });
    
    this.apiService._get(`get-port/${this.portUrl}`).subscribe(response => {
      if (response) {
        this.downloadUrls=[];
        this.portDetails['id'] = response.id;
        this.topViewFileUrl = response['top_view_file_url'];
        this.groundViewFileUrl = response['ground_view_file_url'];
        this.panoViewFileUrl = response['pano_view_file_url'];
        this.landing_image = response['landing_image'];
      }
      console.log('downloadLinks', response)

      // call breadcrumb after api call is done.
      this.breadcrumb = this.creatBreadcrumb(this.router.url);
      this.getFilterData()

    }, error => {
      console.log("error", error);
    })
    this.getReport()

    // breadcrumb update on window reload
    // this.breadcrumb = this.creatBreadcrumb(this.router.url);
  }

  ngOnInit() {
    // breadcrumb update on url update
    this.router.events.subscribe(async e => {
      if (e instanceof NavigationEnd) {
        this.breadcrumb = await this.creatBreadcrumb(e.urlAfterRedirects);

        this.getReport()
        this.getPortId()
      }
    })

    this.listenToReloadFacadeReport()

  }
  listenToReloadFacadeReport() {
    this.headerService.dataObservable.subscribe(response => {
      if (response && response['action'] == 'reloadFacadeReport') {
        this.allValuesCheckbox()
        this.headerService.reloadFacadeReport('')
      }
    })
  }

  setAutoFiltersBySeverityAndTag(tagName, severityName) {
    this.activeFilters = []
    this.currentFilters = {
      tags: [],
      severity: [],
      defect_type: [],
      clause: this.clause
    };
    let filteredSeverity = this.severityFilter.find(x => x.name == severityName);
    let filteredTag = this.tagsFilter.find(x => x.tags == tagName);
    
    if (filteredSeverity) {
      filteredSeverity['active'] = false;
      this.addDataToActiveFilter(filteredSeverity);
    }
    if (filteredTag) {
      filteredTag['active'] = false;
      this.addDataToActiveFilter(filteredTag);
    }
    setTimeout(() => {
      this.selectedFilter(this.currentFilters)
    }, 5000);
  }

  async getPortId() {
    this.portUrl = this.activatedRoute.queryParams["_value"].port_id;
    this.portname = this.activatedRoute.queryParams["_value"].port_name;

    console.log(this.portUrl)
    if (this.portUrl) {
      this.apiService._get(`get-port/${this.portUrl}`).subscribe(response => {
        this.portDetails['id'] = response.id;
        this.downloadUrls=[];
        this.topViewFileUrl = response['top_view_file_url'];
        this.groundViewFileUrl = response['ground_view_file_url'];
        this.panoViewFileUrl = response['pano_view_file_url'];
        this.landing_image = response['landing_image'];

        this.getFilterData()
        console.log('downloadLinks', response)
      }, error => {
        console.log("error", error);
      })
    }

  }


  async getReport() {

    //get asset id
    let assetUrl = this.activatedRoute.queryParams["_value"].asset_id;
    let reportUrl = `get-report-by-user/${assetUrl}`;
    console.log('reportUrl', reportUrl)
    if (reportUrl) {
      // get approved inspection details
      this.apiService._get(reportUrl).subscribe(response => {
        console.log("get report summary", response);
        // validatte response status
        this.reportSummary = response
        if (!response.status)
          return this.toastr.warning(
            "Some Error in Fetching Approved Inspections"
          );
      });
    }

    // console.log("get report", this.reportSummary);

  }


  downloadReport() {
    if (this.displayFilter == true) {
      let reportDataGround = {
        type: GroundReportConst.GROUND_VIEW,
        action: GroundReportConst.ACTION
      }
      console.log(reportDataGround);
      this.filterGroundViewService.downloadReport(reportDataGround)
    } else if (this.displayFilterAsset == true) {
      // let reportDataTop = {
      //   type: TopReportConst.TOP_VIEW,
      //   action: TopReportConst.ACTION
      // }
      // console.log(reportDataTop);
      // this.filterGroundViewService.downloadReport(reportDataTop)
    }
  }
  creatBreadcrumb(url) {
    // split url and queryParamas
    let data = url.split("?");
    let _url = data[0];
    let _qParams = data[1];

    let _urlArr = _url.split("/");
    let _qParamsObj = {};

    let breacrumb: Array<Breadcrumb> = [];
    if (_qParams && _qParams.length > 0) {
      _qParamsObj = parseUrl(url).query;
    }

    console.log('_urlArr', _urlArr);
    if (_urlArr.length > 1) {
      for (let i = 1; i < _urlArr.length; i++) {
        const bc = this.getNameAndPath(_urlArr[i], _qParamsObj);
        breacrumb.push(bc);
      }
    } else {
      breacrumb.push({ name: 'home', path: '/home', queryParams: null });
    }

    return breacrumb;
  }

  // converte qp string to object
  convQP2Obj(qp) {
    // split by &
    if (qp) {
      let qpArr = qp.split("&");
      let qpObj = {};

      for (let i = 0; i < qpArr.length; i++) {
        if (qpArr[i]) {
          let _qpObjArr = qpArr[i].split("=");
          qpObj[_qpObjArr[0]] = (_qpObjArr[1]).replace(/%20/gi, " ").replace(/%2F/gi, "/");
        }
      }
      return qpObj;
    }

  }

  // get name and path
  getNameAndPath(type: string, qp: object): Breadcrumb {
    console.warn(type)
    let returnData = {
      name: '',
      path: '',
      queryParams: null
    };

    // home
    if (type == 'home') {
      returnData['name'] = 'home';
      returnData['path'] = '/home';
      // returnData['path'] = `/home/port`;
      // returnData['queryParams'] = { 'port_name': this.portDetails['name'], 'port_id': this.portDetails['id'] };
    }

    // home
    if (type == 'asset-management') {
      returnData['name'] = 'asset-management';
      returnData['path'] = '/asset-management';
      // returnData['path'] = `/home/port`;
      // returnData['queryParams'] = { 'port_name': this.portDetails['name'], 'port_id': this.portDetails['id'] };
    }


    // port
    if (type == 'port') {
      returnData['name'] = qp['port_name'];
      returnData['path'] = `/home/port`;
      returnData['queryParams'] = { 'port_name': qp['port_name'], 'port_id': qp['port_id'] };
      this.displayPortFilter = true;
    } else {
      this.displayPortFilter = false;
    }

    // asset / ground
    console.log("type", type)
    if (type == 'asset' || type == 'potree' || type == 'panoramic' || type == 'ground') {
      returnData['name'] = qp['asset_name'];
      returnData['path'] = `/home/port/asset`;
      returnData['queryParams'] = { 'port_name': qp['port_name'], 'port_id': qp['port_id'], 'asset_name': qp['asset_name'], 'asset_id': qp['asset_id'] };
    }
    if (type == 'ground') {
      this.displayFilter = true;
    } else {
      this.displayFilter = false;
    }
    if (type == 'asset') {
      this.displayFilterAsset = true;
     
    } else {
      this.displayFilterAsset = false;
    }
    if (type == 'home' || type == 'port') {
      this.displaySearchFilter = true;
    } else {
      this.displaySearchFilter = false;
    }
    return returnData;
  }


  async getFilterData() {
    try {
      if (this.portDetails['id']) {
        this.apiService._get(`get-filter-data/${this.portDetails['id']}`).subscribe(response => {
          console.warn('77777', response);
          this.severityFilter = response.severity
          this.tagsFilter = response.tags
          this.tagsFilter = this.tagsFilter.sort(function (a, b) {
            return a.tags.localeCompare(b.tags);
          })
          this.createDownloadImageList(this.tagsFilter)
          this.annotationTypesFilter = response.defect_type
          this.annotationTypesFilter = this.annotationTypesFilter.sort(function (a, b) {
            return a.type_name.localeCompare(b.type_name);
          })
          // for search tags
          for (const key in this.tagsFilter) {
            if (this.tagsFilter.hasOwnProperty(key)) {
              const element = this.tagsFilter[key];
              this.tags.push(element.tags)
            }
          }
          if (this.filterTag && this.filterSeverity) this.setAutoFiltersBySeverityAndTag(this.filterTag, this.filterSeverity)
        })
      }
    } catch (error) {
      console.log("error in get filter data", error)
    }
  }

  createDownloadImageList(tagsData) {
    // let ObjectForTagsImageUrl = {}
    for (const tagsDetail of tagsData) {
      this.ObjectForTagsImageUrl[tagsDetail.tags] = tagsDetail.image_list
    }
    console.log("ObjectForTagsImageUrl", this.ObjectForTagsImageUrl)
  }

  searchFilterData(data) {
    if (data !== "") {
      console.log(data);
      for (const key in this.tagsFilter) {
        if (this.tagsFilter.hasOwnProperty(key)) {
          const element = this.tagsFilter[key];
          if (element.tags === data) {
            // check whether name exists
            var index = this.tagsArray.findIndex(item => item.tags === element.tags);

            if (index > -1) {
              // check name to determin whether replace the exist one or not
              if (element.tags === this.tagsArray[index].tags) {
                return;
              } else {
                this.tagsArray = []
                this.getFilterData()
              }
            }
          }
        }
      }
    }
  }

  switchToggleData(data) {
    if (data == true) {
      this.clause = 'OR'
      if (this.activeFilters.length > 0) {
        this.redefineFilter()
      }
    } else {
      this.clause = 'AND'
      if (this.activeFilters.length > 0) {
        this.redefineFilter()
      }
    }
    console.log(this.clause);
  }

  changeActiveClass(data) {
    if (data.tags) {
      data.active = !data.active;
      var addData = {
        id: data.tags,
        name: data.tags,
        type: "Tags",
        active: data.active
      }
    } else if (data.type_name) {
      data.active = !data.active;
      addData = {
        id: data.id,
        name: data.type_name,
        type: "Annotation",
        active: data.active
      }
    } else {
      const test = data.active
      data.active = !test
      // const active =  !data.active
      addData = {
        id: data.id,
        name: data.name,
        type: "Filter_tags",
        active: test
      }

    }
    return [data, addData]
  }

  updateSeverityClass(data) {
    this.severityFilter.map(filter => {
      if (filter.id == data.id) {
        filter.active = data.active
      }
    })
  }


  activeFiltersCheck(addData) {
    console.log("active filers", addData)
    var index = this.activeFilters.findIndex(item => item.name === addData.name);
    console.log(index)
    if (index > -1) {
      // check name to determin whether replace the exist one or not
      if (addData.name === this.activeFilters[index].name) {
        if (addData.type == 'Tags') {
          this.activeFilters = this.activeFilters.filter(obj => {
            return obj.name !== addData.name;
          });
        } else if (addData.type == 'Annotation') {
          this.activeFilters = this.activeFilters.filter(obj => {
            return obj.name !== addData.name;
          });
        } else if (addData.type == 'Filter_tags') {
          this.activeFilters = this.activeFilters.filter(obj => {
            return obj.name !== addData.name;
          });
        } else {
          return;
        }
      } else {
        // update name for the exist one
        this.activeFilters[index].name = addData.name;
      }
    } else {
      // add the new item which dosen't exist
      this.activeFilters.push(addData);
      // this.selectedFilter(true)
    }
  }

  addDataToActiveFilter(data) {
    let [data1, addData] = this.changeActiveClass(data)
    data = data1
    // check whether name exists
    this.activeFiltersCheck(addData)
    this.redefineFilter()
  }

  removeElement(data) {
    // console.log("data", data);
    //this.updateActiveClass(data)
    console.log(this.activeFilters);
    this.activeFilters = this.activeFilters.filter(obj => {
      return obj.name !== data.name;
    });
    //this.updateDisplayFilter()
    this.redefineFilter()
  }

  // updateDisplayFilter() {
  //   if (this.activeFilters.length > 3) {
  //     this.displayFilterChips = this.activeFilters.slice(0, 2)
  //   }
  //   else {
  //     this.displayFilterChips = this.activeFilters
  //   }
  // }

  clearAll() {
    if (this.activeFilters.length > 0) {
      this.activeFilters = []
      this.displayFilterChips = []
      this.clearFilter()
      this.updateActiveClass();
    }

  }

  redefineFilter() {
    console.log(this.activeFilters)
    let tags = []
    let annotations = []
    let severity = []

    for (const filter of this.activeFilters) {
      if (filter.type == 'Tags') {
        tags.push(filter.id)
      }
      else if (filter.type == 'Filter_tags') {
        severity.push(filter.id)
      }
      else if (filter.type == "Annotation") {
        annotations.push(filter.id)
      }
    }

    let filterData = {
      clause: this.clause,
      defect_type: annotations,
      tags: tags,
      severity: severity
    }
    console.log("filter data", filterData)
    this.currentFilters = filterData
    this.selectedFilter(filterData)
  }

  updateActiveClass() {
    for (const tags of this.tagsFilter) {
      tags.active = false
    }
    for (const severity of this.annotationTypesFilter) {
      severity.active = false
    }
    for (const annotation of this.severityFilter) {
      annotation.active = false
    }
  }

  allIssues() {
    this.allIssuesSelected = !this.allIssuesSelected
    if (this.allIssuesSelected) {
      let severityId = []
      for (const severity of this.severityFilter) {
        severity.active = true
        severityId.push(severity.id)
        const [data, addData] = this.changeActiveClass(severity)
        this.activeFiltersCheck(addData)
        this.updateSeverityClass(addData)
      }
      this.currentFilters['severity'] = severityId

      this.selectedFilter(this.currentFilters)
    } else {
      let severityId = []
      for (const severity of this.severityFilter) {
        severity.active = false
        const [data, addData] = this.changeActiveClass(severity)
        this.activeFiltersCheck(addData)
        this.updateSeverityClass(addData)

      }
      this.currentFilters['severity'] = []
      this.selectedFilter(this.currentFilters)
    }
  }

  selectedFilter(filters) {
    // sample filter

    // getting defect type & annotation & severity ids
    // for (const key in this.activeFilters) {
    //   console.log("activeFilters", this.activeFilters);
    //   if (this.activeFilters.hasOwnProperty(key)) {
    //     const element = this.activeFilters[key];
    //     if (element.type == "Tags") {
    //       this.tagsArrayFinal.push(element.name)
    //     } else if (element.type == "Annotation") {
    //       this.annotationTypesFinal.push(element.id)
    //     } else {
    //       this.severityFilterFinal.push(element.id)
    //     }
    //   }
    // }

    // let sampleFilter = {
    //   clause: this.clause,
    //   defect_type: this.annotationTypesFinal,
    //   tags: this.tagsArrayFinal,
    //   severity: this.severityFilterFinal
    // }

    console.log("selected filter", filters);



    try {
      if (this.portDetails && this.portDetails['id']) {
        this.apiService._put(`ground-image-filter/${this.portDetails['id']}`, filters).subscribe(response => {
          console.log("selected filter", response)
          this.filterGroundViewService.setFilterData({ type: 'filter', data: response.data })
        })
      }
    } catch (error) {
      console.log("error in selected filter", error)

    }

  }


  clearFilter() {
    this.apiService._get(`get-all-ground-image/${this.portDetails['id']}`).subscribe(response => {
      console.log("response", response.data)
      this.filterGroundViewService.setFilterData({ type: 'filter', data: response.data })
    })
  }


  searchImageName(event) {
    this.filterGroundViewService.setFilterData({ type: 'search', data: event.target.value })
  }

  // // for toggling checkbox filter
  // showCheckboxes() {
  //   var checkboxes = document.getElementById("checkboxes");
  //   if (!this.expanded) {
  //     checkboxes.style.display = "block";
  //     this.expanded = true;
  //   } else {
  //     checkboxes.style.display = "none";
  //     this.expanded = false;
  //   }
  // }
  CheckAllOptions() {
    if (this.checkboxes.every(val => val.checked == true)) {
      this.checkboxes.forEach(val => { val.checked = false });
    }
    else {
      this.checkboxes.forEach(val => { val.checked = true });
      this.isSelectAll = true;
    }
  }

  selectTag = (checkbox) => {
    checkbox.checked = !checkbox.checked;
    if (this.checkboxes.every(val => val.checked == true)) {
      this.isSelectAll = true;
    } else {
      this.isSelectAll = false;
    }
  }

  async allValuesCheckbox() {
    await this.getFilterData()
    this.checkboxes = [];
    const tags = await this.tagsFilter.find(x => x.defects_count > 0);
    if (tags) {
      for (const tag of this.tagsFilter) {
        if (tag.defects_count)
          this.checkboxes.push({
            tag: tag.tags,
            count: tag.defects_count,
            checked: false
          })
      }
    }

  }

  sendDataToGenerateReport() {
    let _tempArray = [];
    this.checkboxes.forEach(item => {
      if (item.checked) {
        _tempArray.push(item.tag);
      }
    })
    if (_tempArray.length > 0) {
      this.generateReport(_tempArray)
    } else {
      return this.toastr.warning("Please Select any Tag");
    }
  }

  // Pagination for approvedInspection happens here
  paginateReportSummary() {
    var pervArray = this.reportSummary.data;

    const skip = pervArray.length
    const limit = 10;
    var url = "";


    url = `${this.reportUrl}&skip=${skip}&limit=${limit}`


    this.apiService
      ._get(url)
      .subscribe(response => {
        // validatte response status
        if (!response.status)
          return this.toastr.warning("Some Error in Fetching details");


        console.log("response", response)
        this.reportSummary.data = [
          ...pervArray,
          ...response.data
        ];
        console.log(this.reportSummary)
        //add skip
        this.reportSummary.isLoadMore = response["isLoadMore"];
      });
  }

  openReportSummary(data) {
    console.log(data)
    const _url = data['report_url'];
    window.open(`/view-report?document=${window.btoa(_url)}`, '_blank');
  }

  generateReport(facadelist) {
    console.log("facadelist", facadelist);
    const requestBody = {
      portId: this.portDetails['id'],
      tags: facadelist
    }
    this.windowloader = {
      loader: true,
      name: 'Generating Report'
    }
    this.apiService._post('facade-generate-report', requestBody).subscribe(response => {
      console.log("facade generate report", response)

      if (response['status']) {
        window.open(`/view-report?document=${window.btoa(response['data'])}`, '_blank');
        this.windowloader = {
          loader: false,
          name: 'Generating Report'
        }
      }
      else {
        this.toastr.warning(
          "Some Error in Fetching Report Data"
        );
        this.windowloader = {
          loader: false,
          name: 'Generating Report'
        }
      }
    }, error => {
      console.log(error);
      this.toastr.error(
        "Some Error in Fetching Report Data"
      );
    })
  }

  downloadRawImage(tagName) {
    let selImageList = []
    let finalImageList = []
    selImageList = this.ObjectForTagsImageUrl[tagName]
    for (const key in selImageList) {
      if (selImageList.hasOwnProperty(key)) {
        const element = selImageList[key];
        finalImageList.push(element.url)
        // DownloadFile(element.url)
      }
    }
    var zip = new JSZip();
    var count = 0;
    var urls = finalImageList;

    this.toastr.info(
      "Fetching Images for Download"
    );
    // this.windowloader = {
    //   loader: true,
    //   name: 'Downloading Images'
    // }
    for (const url of urls) {
      var filename = url.split('/').pop();
      // loading a file and add it in a zip file
      let data = JSZipUtils.getBinaryContent(url)
      zip.file(filename, data, { binary: true });
      count++;
      if (count == urls.length) {
        zip.generateAsync({
          type: 'blob'
        }).then(content => {
          // this.windowloader = {
          //   loader: false,
          //   name: ''
          // }
          saveAs(content, `${tagName} + ${new Date}` + '.zip');
        });
      }
    }
  }
  // search filter inspection
  searchInspection(value) {
    if (value != "") {
      const requestBody = {
        text: value
      }
      if (this.displayPortFilter) requestBody['portId'] = this.portUrl
      this.enableAutoComplete = true;
      this.apiService._post('search-inspection', requestBody).subscribe(response => {
        console.warn("searchData", response);
        this.searchData = response['data'];
        this.searchDatasites = response['sites'];

      })
    } else {
      this.enableAutoComplete = false;
    }
  }

  getInspectionData(data, name) {
    this.enableAutoComplete = false;
    if (name == 'sites') {
      this.router.navigate(["home/port"], {
        queryParams: { port_name: data.name, port_id: data.id }
      });
    } else {
      this.router.navigate(["home/port/asset"], {
        queryParams: {
          port_name: data['portName'],
          port_id: data['portId'],
          asset_name: data['assetName'],
          asset_id: data['assetId'],
          inspection_name: data['inspectionName'],
          inspection_id: data['inspectionId'],
          showInspection: 'show'
        }, queryParamsHandling: "merge"
      });
    }

  }

  // File url download
  onSelectDownload(e, type) {
    if (e.target.checked) {
      this.downloadUrls.push({ type: type, url: e.target.value });
    } else {
      let index = this.downloadUrls.findIndex(x => x.type == type);
      this.downloadUrls.splice(index, 1);
    }
  }

  fetchImageAndDownload(url, name) {
    const downloadName = name;
    const img = document.createElement("img");
    img.addEventListener("load", () => {
      const a = document.createElement("a");
      a.href = img.src;
      a.download = downloadName;
      a.target = "_blank";
      a.click();
    });
    img.src = url;

  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }


  async downloadFiles() {
    this.toastr.info('Download starting...');

    for (const obj of this.downloadUrls) {
      // saveAs(obj.url, obj.type);
      if (obj.type == 'landing') {
        // let myRequest = new Request(obj.url);
        // var _this = this;
        // fetch(myRequest, { method : "GET",
        // mode: 'cors',
        // headers: headers})
        // .then(response => {
        //   return response.blob()
        // })
        // .then(function(myBlob) {
        //   console.log("blob>>>",myBlob);
        //   saveAs(myBlob, `${_this.portname}-landing-page.png`);
        // });
        this.windowloader = {
          loader: true,
          name: ''
        };
        (window as any).global = window;
        // @ts-ignore
        window.Buffer = window.Buffer || require('buffer').Buffer;
        this.apiService._post('landing-buffer', { url: obj.url,name:this.portname }).subscribe(response => {
          if (response.status) {
            // let blob = response.buffer;
            // let img = new Buffer(JSON.parse(response.buffer));
            // let edited = "data:image/jpeg;base64," + img.toString("base64");
            // let matches = edited.match(/^data:.+\/(.+);base64,(.*)$/);
            // let ext = matches[1];
            // let data = matches[2];
            // let blob = base64StringToBlob(data, "image/png");
            this.windowloader = {
              loader: false,
              name: ''
            }

            saveAs(response.buffer,this.portname);
          } else {
            this.toastr.error(
              "Some Error in Fetching Report Data"
            );
            this.windowloader = {
              loader: false,
              name: ''
            }
          }


        }, error => {
          console.log(error);
          this.toastr.error(
            "Some Error in Fetching Report Data"
          );
          this.windowloader = {
            loader: false,
            name: ''
          }
        })
      } else {
        const url = obj.url
        const fileStream = streamSaver.createWriteStream(obj.url.split('/').pop())
        fetch(url).then(res => {
          const readableStream = res.body
          // more optimized
          if (readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream)
              .then(() => console.log('done writing'))
          }
          const writer = fileStream.getWriter()

          const reader = res.body.getReader()
          const pump = () => reader.read()
            .then(res => res.done
              ? writer.close()
              : writer.write(res.value).then(pump))

          pump()
        })
      }
    }
  }



}
