// Modules
import { NgModule } from '@angular/core';
import { ReactiveFormsModule , FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { assetManagementRouteModule } from './asset-management-routing.module';
import { PortListComponent } from './port-list/port-list.component';
import { PortViewComponent } from './port-view/port-view.component';
import { AssetManagementComponent } from './asset-management/asset-management.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TopViewComponent } from './top-view/top-view.component';
import { PanoViewComponent } from './pano-view/pano-view.component';
import { GroundViewComponent } from './ground-view/ground-view.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [PortListComponent, PortViewComponent, AssetManagementComponent, TopViewComponent, PanoViewComponent, GroundViewComponent],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    assetManagementRouteModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule
  ]
})
export class AssetManagementModule { }
