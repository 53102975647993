import { Component, OnInit, Output, EventEmitter } from '@angular/core';

// Service
import { ApiService, JwtService, UserService } from 'src/app/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'asset-mang-port-list',
  templateUrl: './port-list.component.html',
  styleUrls: ['./port-list.component.css']
})
export class PortListComponent implements OnInit {

  // Ports
  ports: Array<Object> = [];

  // add button toggle
  addButtonClicked = false;

  deleteFunctionCalled = false;

  @Output() outAddButtonClicked = new EventEmitter<boolean>();
  @Output() outDeleteFunctionCalled = new EventEmitter<boolean>();
  @Output() selectedPort = new EventEmitter<boolean>();

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private userService : UserService
  ) { }

  ngOnInit() {
    this.getPorts()
  }

  // get ports
  getPorts() {

    this.apiService._get('get-ports').subscribe(response => {
      this.ports = response.data;
      this.ports.forEach(item => item["active"]= false);
    })

  }

  // add button toggle and emit
  addButtonClick() {
    this.addButtonClicked = !this.addButtonClicked

    //emit
    this.outAddButtonClicked.emit(this.addButtonClicked)
  }

  // delete port
  deletePort(id, portList) {
    this.modalService
      .open(portList, { centered: true, ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          let _body = {
            ids: []
          };
          _body["ids"].push(id);
      
          this.apiService._put("delete-port", _body).subscribe(response => {
            // validate reponse
            if (!response["status"]) return this.toastr.error(response["message"]);
      
            // reload the list
            this.getPorts();
            // alert the user
            this.toastr.success(response["message"]);
            // emit for reset form
            this.deleteFunctionCalled = true;
            //emit
            this.outDeleteFunctionCalled.emit(this.deleteFunctionCalled)
          }, error => {
            console.log(error);
          });
        }, reason => {
          console.log('false');
        })
        this.deleteFunctionCalled = false;
  }

  // toggle active class and port emit
  selectPort(port) {
    console.log('123',port)
    this.ports.map(x => x['active'] = false)
    port.active = !port.active;
    this.selectedPort.emit(port);
  }

}
