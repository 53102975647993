import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


import { ApiService } from 'src/app/core';

import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import * as DownloadFile from "downloadjs";
import * as streamSaver from 'streamsaver'


@Component({
  selector: 'app-progress-highlights',
  templateUrl: './progress-highlights.component.html',
  styleUrls: ['./progress-highlights.component.css']
})
export class ProgressHighlightsComponent implements OnInit {

  //get ports list data
  portsList: any = [];

  //card toggle
  showCardBody: Boolean = true;

  // download url array
  downloadUrls = [];
  topCheck = false;
  groundCheck = false;
  panoCheck = false;

  // url from parent component to fetch data for progress highlights
  @Input() fetchUrl: string

  constructor(private apiService: ApiService, private toastr: ToastrService, public http: HttpClient,
  ) { }

  ngOnInit() {
    this.getPorts()
  }

  getPorts = async () => {
    if (this.fetchUrl) {
      await this.apiService
        ._get(this.fetchUrl)
        .subscribe(response => {
          this.portsList = response.data;
          console.log("this.portsList", this.portsList);
        })
    }
  }

  clearDropDoown() {
    this.downloadUrls = [];
    this.topCheck = false;
    this.groundCheck = false;
    this.panoCheck = false;
  }
  onSelectDownload(e, type) {
    if (e.target.checked) {
      this.downloadUrls.push({ type: type, url: e.target.value });
    } else {
      let index = this.downloadUrls.findIndex(x => x.type == type);
      this.downloadUrls.splice(index, 1);
    }
  }

  async downloadFiles() {
    this.toastr.info('Download starting...')
    console.log("download url", this.downloadUrls)
    for (const obj of this.downloadUrls) {
      // console.log(obj)
      // window.open(obj.url)
      // const blob = new Blob([obj.url])
      // const fileStream = streamSaver.createWriteStream(obj.url,{
      //   size:blob.size
      // })
      // await new Response('StreamSaver is awesome').body
      //   .pipeTo(fileStream)


      const url = obj.url

      const fileStream = streamSaver.createWriteStream(obj.url.split('/').pop())

      fetch(url).then(res => {
        const readableStream = res.body
        // more optimized
        if (readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream)
            .then(() => console.log('done writing'))
        }
        const writer = fileStream.getWriter()

        const reader = res.body.getReader()
        const pump = () => reader.read()
          .then(res => res.done
            ? writer.close()
            : writer.write(res.value).then(pump))

        pump()
      })
    }
  }
}
