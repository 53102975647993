import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';
import { iPanoramic } from '../models/port.class';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
// import sanitizer for parse safeurl.
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-panoramic',
  templateUrl: './panoramic.component.html',
  styleUrls: ['./panoramic.component.css']
})
export class PanoramicComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private sanitizer: DomSanitizer, private router: Router) { }
  assetId: string
  url: SafeResourceUrl; // safe url check Angular does not allow to use dynamic url to load iframe.
  panoramic: iPanoramic // model of panoramic
  portname: string
  portid: string
  ngOnInit() {
    // get current asset id
    this.assetId = this.activatedRoute.queryParams["_value"].asset_id;
    this.portname = this.activatedRoute.queryParams["_value"].port_name;
    this.portid = this.activatedRoute.queryParams["_value"].port_id;
    this.getPanoramicData();
  }
  // get panoramic data
  getPanoramicData = () => {
    this.apiService._get(`get-panoramic-id/${this.assetId}`).subscribe(response => {
      this.panoramic = response.data;
      // make as a safe url you can use the url only in [SRC] tag .
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.panoramic.url_file);
      console.log(this.panoramic.url_file);
    }, error => {
      // error log
      console.error(error)
    })
  }

  backpage() {
    this.router.navigate(["home/port"], {
      queryParams: {
        port_name: this.portname,
        port_id: this.portid
      }, queryParamsHandling: "merge"
    });
  }

}
