import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PortViewComponent } from '../port-view/port-view.component';
import { TopViewComponent } from '../top-view/top-view.component';
import { PanoViewComponent } from '../pano-view/pano-view.component';
import { GroundViewComponent } from '../ground-view/ground-view.component';
import { PortListComponent } from '../port-list/port-list.component';
import { Router, NavigationEnd } from '@angular/router';

import { userConfig } from "../../map/components/models/userConfig";
import { UserService } from 'src/app/core';

@Component({
  selector: 'asset-mang-asset-management',
  templateUrl: './asset-management.component.html',
  styleUrls: ['./asset-management.component.css']
})
export class AssetManagementComponent implements OnInit {

  @ViewChild(PortViewComponent, null) portViewComponent: PortViewComponent;
  @ViewChild(TopViewComponent, null) topViewComponent: TopViewComponent;
  @ViewChild(PanoViewComponent, null) panoViewComponent: PanoViewComponent;
  @ViewChild(GroundViewComponent, null) groundViewComponent: GroundViewComponent;
  @ViewChild(PortListComponent, null) portListComponent: PortListComponent;

  // toggle disable for tabs
  disableTabView: Boolean = false;

  // port Id created from port view
  createdIdFromPortView: string = ""

  USER_CONFIG;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    this.disableTabView = true;

    let _userConfigModal = this.userService.getSession();
    this.USER_CONFIG = _userConfigModal["userConfig"];
    if (!this.USER_CONFIG['adminMenu']) {
      this.router.navigate(['/home'])
    }
    console.log('userconfig', this.USER_CONFIG)
  }

  ngOnInit() {


  }

  toggleTabView(data) {
    // disabling tab view if add button clicked
    if (data == true) {
      this.disableTabView = data;
      this.portViewComponent.isDisabled = false;
      this.portViewComponent.isDisableButton = false;
    } else {
      this.portViewComponent.isDisabled = true;
    }
    this.portListComponent.getPorts()

    if (data) {
      // reset port view form
      this.portViewComponent.portForm.reset()

      // reset top view form
      this.topViewComponent.topForm.controls['port'].reset()

      // reset pano view form
      this.panoViewComponent.panoForm.controls['port'].reset()

      // reset ground view form
      this.groundViewComponent.groundForm.controls['port'].reset()
    }
  }

  // getting port Id and enabling the tab view
  getPortFromPortView(id) {
    // set port view port id
    this.portViewComponent.portForm.controls['id'].setValue(id);

    // set top view port id
    this.topViewComponent.portId = id
    this.topViewComponent.topForm.controls['port'].setValue(id);
    this.topViewComponent.getTopData();

    // set pano view port id
    this.panoViewComponent.portId = id
    this.panoViewComponent.panoForm.controls['port'].setValue(id);
    this.panoViewComponent.getPanoData();

    // set port id in ground view form
    this.groundViewComponent.portId = id;
    this.groundViewComponent.groundForm.controls['port'].setValue(id);
    this.groundViewComponent.getGroundData();
   

    this.createdIdFromPortView = id;
    if (id != "") {
      this.disableTabView = false;
      this.portViewComponent.isDisabled = false;
      this.topViewComponent.isDisabled = false;
      this.panoViewComponent.isDisabled = false;
      this.portListComponent.getPorts()
    }
  }

  // Port selected in list
  selectedPort(port) {
    this.portListComponent.addButtonClicked = false;
    // enabling tab view if port from list is selected
    this.disableTabView = false;
    // patch port form
    this.portViewComponent.portForm.patchValue(port);

    // set port view port id
    this.portViewComponent.portForm.controls['id'].setValue(port.id);

    // disabling all forms if port is selected
    this.portViewComponent.isDisabled = true;
    this.portViewComponent.isDisableButton = false;
    this.topViewComponent.isDisabled = true;
    this.panoViewComponent.isDisabled = true;

    // set top view port id
    this.topViewComponent.portId = port.id
    this.topViewComponent.topForm.controls['port'].setValue(port.id);
    this.topViewComponent.getTopData();

    // set pano view port id
    this.panoViewComponent.portId = port.id
    this.panoViewComponent.panoForm.controls['port'].setValue(port.id);
    this.panoViewComponent.getPanoData();

    // set port id in ground view form
    this.groundViewComponent.portId = port.id;
    this.groundViewComponent.groundForm.controls['port'].setValue(port.id);
    this.groundViewComponent.getGroundData();
    // this.groundViewComponent.getAssetsData();
    this.groundViewComponent.getmainAssetsData();
  }

  // reset form if port delete is clicked
  portDeleteClicked(data) {
    if (data)
      // reset port view form
      this.portViewComponent.portForm.reset()

    // reset top view form
    this.topViewComponent.topForm.reset()

    // reset pano view form
    this.panoViewComponent.panoForm.reset()

    // reset ground view form
    this.groundViewComponent.groundForm.reset()
    this.groundViewComponent.getGroundData()

    // disabling tabs if port is deleted
    this.disableTabView = true;
  }
}
