import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// interceptors
import { HttpTokenInterceptor } from 'src/app/core/interceptors/http.token.interceptors';

// modules
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { SharedModule } from './shared/shared.module';

// toaster
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


import 'hammerjs';
import { ApprovedAssessmentComponent } from './modules/map/components/left-sidebar/approved-assessment/approved-assessment.component';
import { MapModule } from './modules/map/map.module';


import { AssetManagementModule } from './modules/asset-management/asset-management.module';
import { CustomFormsModule } from 'ng2-validation'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    SharedModule,
    MapModule,
    AssetManagementModule,
    ToastrModule.forRoot({
      progressBar: true
    }),
    BsDatepickerModule.forRoot(),
    CustomFormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    ApprovedAssessmentComponent
  ]
})
export class AppModule { }
