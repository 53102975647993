import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule ,FormsModule} from '@angular/forms';

// modules
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbDatepickerModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSplitModule } from 'angular-split';
import { DeferLoadModule } from '@trademe/ng-defer-load';

// routes
import { mapRoutesModule } from './map-routing.module';


// components
import { HomeComponent } from './components/home/home.component';
import { PortComponent } from './components/port/port.component';
import { AssetComponent } from './components/asset/asset.component';
import { PotreeComponent } from './components/potree/potree.component';
import { PanoramicComponent } from './components/panoramic/panoramic.component';
import { GroundComponent } from './components/ground/ground.component';
import { GroundReferenceComponent } from './components/ground/ground-reference/ground-reference.component';
import { GroundImageViewerComponent } from './components/ground/ground-image-viewer/ground-image-viewer.component';
import { GroundDefectListComponent } from './components/ground/ground-defect-list/ground-defect-list.component';
import { LeftSidebarLayoutComponent } from './components/left-sidebar/left-sidebar-layout/left-sidebar-layout.component';
import { RightSidebarLayoutComponent } from './components/right-sidebar/right-sidebar-layout/right-sidebar-layout.component';
import { BottomBarLayoutComponent } from './components/bottom-bar/bottom-bar-layout/bottom-bar-layout.component';
import { MyNotificationsComponent } from './components/left-sidebar/my-notifications/my-notifications.component';
import { RecentUploadsComponent } from './components/left-sidebar/recent-uploads/recent-uploads.component';
import { ApprovedAssessmentComponent } from './components/left-sidebar/approved-assessment/approved-assessment.component';
import { AssessmentHighlightsComponent } from './components/left-sidebar/assessment-highlights/assessment-highlights.component';
import { ProgressHighlightsComponent } from './components/left-sidebar/progress-highlights/progress-highlights.component';
import { SiteInformationComponent } from './components/left-sidebar/site-information/site-information.component';
import { RecentAssessmentComponent } from './components/left-sidebar/recent-assessment/recent-assessment.component';
import { OverallSiteViewComponent } from './components/left-sidebar/overall-site-view/overall-site-view.component';
import { ToolsComponent } from './components/left-sidebar/tools/tools.component';
import { DefectListComponent } from './components/bottom-bar/bottom-bar-layout/defect-list/defect-list.component';
import { DefectWizardComponent } from './components/right-sidebar/defect-wizard/defect-wizard.component';
import { ImageListComponent } from './components/right-sidebar/image-list/image-list.component';
import { ScreenshotComponent } from './components/right-sidebar/screenshot/screenshot.component';

import { PopoverModule } from 'ngx-bootstrap/popover';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportSummaryComponent } from './components/left-sidebar/report-summary/report-summary.component';
import { DataTablesModule } from 'angular-datatables';
// import { BrowserModule } from '@angular/platform-browser';

// import { ImageLazyLoadModule } from '../../core/services/images-lazyload/images-lazyload.module';

import { LazyLoadImageModule } from 'ng-lazyload-image'; 




@NgModule({
  declarations: [
    HomeComponent,
    PortComponent,
    AssetComponent,
    PotreeComponent,
    PanoramicComponent,
    GroundComponent,
    GroundReferenceComponent,
    GroundImageViewerComponent,
    GroundDefectListComponent,
    LeftSidebarLayoutComponent,
    RightSidebarLayoutComponent,
    BottomBarLayoutComponent,
    MyNotificationsComponent,
    RecentUploadsComponent,
    ApprovedAssessmentComponent,
    AssessmentHighlightsComponent,
    ProgressHighlightsComponent,
    SiteInformationComponent,
    RecentAssessmentComponent,   
    OverallSiteViewComponent,
    ToolsComponent ,
    DefectListComponent  ,
    DefectWizardComponent,
    ImageListComponent,
    ScreenshotComponent,
    ReportSummaryComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    mapRoutesModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    AngularSplitModule.forRoot(),
    DeferLoadModule,
    TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgbModule,
    NgbPopoverModule,
    DataTablesModule,
    // ImageLazyLoadModule,
    LazyLoadImageModule
    // BrowserModule

  ],
  exports: [
    ApprovedAssessmentComponent
  ]
})
export class MapModule { }
