import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'asset-mang-port-view',
  templateUrl: './port-view.component.html',
  styleUrls: ['./port-view.component.css']
})
export class PortViewComponent implements OnInit {

  // Date pattern
  // usDatePattern = ;

  minDate: Date;
  maxDate: Date;

  // Port form
  portForm: FormGroup;
  submitted: Boolean = false;

  // toggle enable / disable fields
  isDisabled: Boolean = false;

  // toggle enable / disable buttons
  isDisableButton: Boolean = false;

  editButtonClicked: Boolean = false;

  // port Id after creating port
  createdPortId: string = "";

  // emitting created portId
  @Output() outCreatedPortId = new EventEmitter<string>();

  // to hold previous value
  previousValue: Object = {};

  constructor(private apiService: ApiService,
    private toastr: ToastrService) {
    this.isDisabled = true;
    this.isDisableButton = true;
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit() {

    // Form init
    this.portForm = new FormGroup({
      id: new FormControl(null, []),
      name: new FormControl(null, [Validators.required]),
      latitude: new FormControl(null, [Validators.required, Validators.pattern(/^[-+]?[0-9]+\.[0-9]+$/)]),
      longitude: new FormControl(null, [Validators.required, Validators.pattern(/^[-+]?[0-9]+\.[0-9]+$/)]),
      site_owner: new FormControl(null, [Validators.pattern(/^(?! )[A-Za-z0-9 ]*(?<! )$/)]),
      audit_date: new FormControl(null, []),
      marshaCode: new FormControl(null, [Validators.pattern(/^(?! )[A-Za-z0-9 ]*(?<! )$/)]),
      status: new FormControl(null, []),
      pilot: new FormControl(null),
      weather: new FormControl(null, [Validators.pattern(/^(?! )[A-Za-z0-9 ]*(?<! )$/)]),
      landing_image: new FormControl(null, [Validators.required, CustomValidators.url]),
      overall_view: new FormControl(null, [CustomValidators.url]),
      top_view_file_url: new FormControl(null, [CustomValidators.url]),
      pano_view_file_url: new FormControl(null, [CustomValidators.url]),
      ground_view_file_url: new FormControl(null, [CustomValidators.url]),
      facility_address: new FormControl(null)
    })

  }


  // retun form control
  get portFormControl() {
    return this.portForm.controls;
  }

  log() {
    console.log(this.portForm)
  }

  async submitPortView() {
    this.submitted = true;
    this.portForm.value['status'] = 'INSPECTED'
    if (this.portForm.controls['audit_date'].value) this.portForm.value['audit_date'] = this.formatdate(this.portForm.controls['audit_date'].value)

    console.log('portForm', this.portForm.value,this.portForm);
    // return;

    // validate form
    if (!this.portForm.valid) return this.toastr.error('Invalid form, some details missing');

    // Post form
    this.apiService._post(`create-port`, this.portForm.value).subscribe(response => {
      console.warn("response", response);

      // validatte response status
      if (!response['status']) return this.toastr.error('Error in saving form');

      // getting created portId
      this.createdPortId = response['port']['id'];
      console.warn("createdPortId", this.createdPortId);

      //emit port Id
      this.outCreatedPortId.emit(this.createdPortId)

      this.toastr.success('Successfully saved Port details');
      this.editButtonClicked = false;
      this.isDisabled = true;

      this.submitted = false;

    }, error => {
      console.log("Port Error", error);
    });
  }

  // format data mm/dd/yyyy format
  formatdate(data) {
    if (typeof (data) === 'object') {
      var today = data;
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }
      today = mm + '/' + dd + '/' + yyyy;
      return today;
    }
    return;
  }

  updatePortView() {
    this.editButtonClicked = !this.editButtonClicked;
    this.isDisabled = !this.isDisabled;

    if (this.editButtonClicked) this.previousValue = this.portForm.value;
  }

  cancelEdit() {
    this.portForm.reset();
    this.portForm.patchValue(this.previousValue);
  }
}
