import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';





export interface imageViewerI {
  type: string;
  url: string;
  show: boolean;
  headerLabel : string
}

@Injectable({
  providedIn: 'root'
})
export class ImageViewerService {

  // source for imageViewer
  imageViewerSource = new BehaviorSubject({})


  //data for imageViewer

  imageViewerData = this.imageViewerSource.asObservable()


  constructor() { }


  //setImageViewer
  setImageViewerData(imageViewerBody: imageViewerI) {
    this.imageViewerData['imageViewer'] = imageViewerBody
    this.imageViewerSource.next(this.imageViewerData)
  }

  //removeImageViewer

  removeImageViewer() { }





}
