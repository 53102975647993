import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationTrackingService {

  private coordinateSource = new BehaviorSubject({})
  private assetDataSource = new BehaviorSubject({})
  private actionSource = new BehaviorSubject({})
  private screenShotSource = new BehaviorSubject({})
  private targetElemetSource = new BehaviorSubject({})




  coordinateData = this.coordinateSource.asObservable()
  assetData = this.assetDataSource.asObservable()
  actionData = this.actionSource.asObservable()
  screenshotData = this.screenShotSource.asObservable()
  targetElementData = this.targetElemetSource.asObservable()







  //set coordinate data when mouse pointer moves
  setCoordinate(coordinate) {
    this.coordinateData['coordinate'] = coordinate
    this.coordinateSource.next(this.coordinateData)
  }

  // getCoordinate data

  getCoordinate() {
    return this.coordinateData
  }


  setAssetData(data) {
    this.assetData['asset'] = data
    this.assetDataSource.next(this.assetData)
  }


  destroyRunningContainer() {
    this.actionData['action'] = 'destroy'
    this.actionSource.next(this.actionData)
  }


  loadAssetDetails() {
    this.actionData['action'] = 'reloadAsset'
    this.actionSource.next(this.actionData)
  }

  clearContainerKey() {
    this.actionData['action'] = ''
  }

  async takeMiniMapScreenShot(action) {
    console.log("takeminimapscreenshot", action)
    this.screenshotData['action'] = action
    this.screenShotSource.next(this.screenshotData)
  }

  async setTargetElementMiniMap(targetElement) {
    this.targetElementData['data'] = targetElement
  }

  async getTargetElemetData() {
    return this.targetElementData['data']
  }

}


