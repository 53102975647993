import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Auth gaurd
import { AuthGuard } from 'src/app/core/services/auth-gaurd.service';

const routes: Routes = [
  // auth module
  {
    path: 'auth', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  // map module
  {
    path: '', loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule),
    canActivate: [AuthGuard]
  },

  // map module
  {
    path: 'asset-management', loadChildren: () => import('./modules/asset-management/asset-management.module').then(m => m.AssetManagementModule),
    canActivate: [AuthGuard]
  },
  // redirect path
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
