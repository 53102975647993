import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons, NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-recent-assessment',
  templateUrl: './recent-assessment.component.html',
  styleUrls: ['./recent-assessment.component.css']
})
export class RecentAssessmentComponent implements OnInit {

  constructor(private apiService: ApiService,

    private modalService: NgbModal,
    private toastr: ToastrService) { }

  // event 
  @Output() outInspectionData = new EventEmitter();
  //contains all notification data
  recentAssessmentData: any = []

  modalData;

  //card toggle
  showCardBody: Boolean = true;

  // url from parent component to fetch data for recentassessment
  @Input() fetchUrl: string

  @Output() hideBottomandSideBar = new EventEmitter()

  ngOnInit() {
    // get recentassessment 
    this.getRecentAssessmentData()
  }

  // get recentassessment
  // called initially on ngonit
  getRecentAssessmentData() {
    if (this.fetchUrl) {
      // get My Notifications details
      console.clear()
      console.log("fetch", this.fetchUrl)
      this.apiService._get(this.fetchUrl).subscribe(response => {
        console.warn("7777", response);
        // validatte response status
        // if (!response.status)
        //   return this.toastr.warning(
        //     "Some Error in Fetching recentassessment details"
        //   );
        this.recentAssessmentData = response.data
      });
    }
  }

  // Recent Assessment
  paginateRecentAssessment() {
    const pervArray = this.recentAssessmentData.data
    const skip = pervArray.length;
    const limit = 10;

    // get Recent uploads details
    this.apiService
      ._get(
        `${this.fetchUrl}?skip=${skip}&limit=${limit}`
      )
      .subscribe(response => {
        // validatte response status
        // if (!response.status) return this.toastr.warning('Some Error in Fetching details');
        console.warn(response);

        // set notification datails
        this.recentAssessmentData.data = [
          ...pervArray,
          ...response.data
        ];
        this.recentAssessmentData.data.isLoadMore = response["isLoadMore"];
      });
  }

  selectInspection = (data) => {
    this.outInspectionData.emit(data);
  }

  deleteInspection = (data, assessment) => {
    this.modalService
      .open(assessment, { centered: true, ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          let reqData = { ids: [data.id] };
          console.log(reqData)
          this.apiService._put("delete-inspection", reqData).subscribe(
            response => {
              if (response["status"]) {
                this.toastr.success("Inspection Deleted successfully");
                this.hideBottomandSideBar.emit(null)
                this.getRecentAssessmentData()
              }
              else {
                this.toastr.success("Report not deleted. Try again later");
              }
            }, error => {
              console.log(error);
            })
        }, reason => {
          console.log('false');
        })
  }
}
