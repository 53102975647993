import { Component, OnInit, Input } from '@angular/core';

import { InternalCommService } from 'src/app/core/services';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {
  fileUrl: SafeResourceUrl;

  // window loader
  loaded: Boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private activeRoute: ActivatedRoute
  ) {

    this.activeRoute.queryParamMap.subscribe(params => {
      const orderObj = { ...params['params'] };
      let _url = window.atob(orderObj.document);
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(_url);
      this.loaded = false;
    })
  }

  ngOnInit() {
    // this.interCommService.currentData.subscribe(response => {
    //   this.loaded = false;
    //   if (response['pdfUrl']) this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response['pdfUrl']);
    // })
  }

  // closeViewer() {
  //   this.interCommService.deleteData('pdfUrl');
  //   this.fileUrl = null;
  // }
  // updateSrc(url) {
  //   let _src = document.getElementById("pdfEmbed").src;
  // }

}
