import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private dataSource = new BehaviorSubject({});

  dataObservable = this.dataSource.asObservable();



  constructor() { }

  reloadFacadeReport(data) {
    this.dataObservable['action'] = data
    this.dataSource.next(this.dataSource)
  }
}
