import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterGroundViewService {

  constructor() { }
  private groundViewFilterDataSource = new BehaviorSubject({});
  private downloadReportSource = new BehaviorSubject({});


  groundViewdata = this.groundViewFilterDataSource.asObservable();
  reportData = this.downloadReportSource.asObservable();



  setFilterData(data) {
    this.groundViewdata['filterData'] = data
    this.groundViewFilterDataSource.next(this.groundViewdata)
  }


  downloadReport(status){
    this.reportData['download']  = status
    this.downloadReportSource.next(this.reportData)
  }

}
