import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Auth gaurd
import { AuthGuard } from 'src/app/core/services/auth-gaurd.service';

// Components
import { HomeComponent } from './components/home/home.component';
import { PortComponent } from './components/port/port.component';
import { AssetComponent } from './components/asset/asset.component';
import { PotreeComponent } from './components/potree/potree.component';
import { PanoramicComponent } from './components/panoramic/panoramic.component';
import { PdfViewerComponent } from '../../shared/components/pdf-viewer/pdf-viewer.component';
import { GroundComponent } from './components/ground/ground.component';

const mapRoutes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'home/port', component: PortComponent, canActivate: [AuthGuard] },
    { path: 'home/port/ground', component: GroundComponent, canActivate: [AuthGuard] },
    { path: 'home/port/asset', component: AssetComponent, canActivate: [AuthGuard] },
    { path: 'home/port/potree', component: PotreeComponent, canActivate: [AuthGuard] },
    { path: 'home/port/panoramic', component: PanoramicComponent, canActivate: [AuthGuard] },
    { path: 'view-report', component: PdfViewerComponent, canActivate: [AuthGuard] },
]

@NgModule({
    imports: [
        RouterModule.forChild(mapRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class mapRoutesModule { }