import { Component, OnInit } from '@angular/core';
import { UserService, ApiService, SocketService, InternalCommService } from 'src/app/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { iOrthoMosaic, iRealityCapture, iPanoramic } from '../../../modules/map/components/models/port.class';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // user details
  userDetails: Object;
  portId: string;
  orthomosaiImage: iOrthoMosaic[]
  realityCapture: iRealityCapture[]
  panoramic: iPanoramic[]
  groundView: any;
  activeParams;
  activePath;
  // hamburger hide/show status
  hamburgerStatus = false;
  visibleStatus: Boolean = false;

  //port details
  portDetails: any
  // form
  changePasswordForm: FormGroup;
  formSubmit: Boolean = false;
  disableFormButton: Boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private apiService: ApiService,
    private socketService: SocketService,
    private toastr: ToastrService,

  ) {
    // get user details form session
    this.userDetails = this.userService.getSession();
    console.warn('USER', this.userDetails)
    this.listenHeader();

   
  }

  ngOnInit() {

    this.changePasswordForm = new FormGroup({
      old_password: new FormControl(null, [Validators.required]),
      new_password: new FormControl(null, [Validators.required]),
      re_new_password: new FormControl(null, [Validators.required])
    }, {
      validators: this.checkPasswords
    })

    this.router.events.subscribe(e => {

      if (e instanceof NavigationEnd) {
        this.listenHeader();
        this.getAssets();

      }
    })
    // this.activeRoute.queryParamMap.subscribe(params => {
    //   this.activeParams = { ...params['params'] };
    // })
    this.getports();
  }


  checkPasswords(group: FormGroup) {
    let pass = group.get('new_password').value;
    let confirmPass = group.get('re_new_password').value;

    return pass === confirmPass ? null : { notSame: true }
  }

  // listen header
  listenHeader() {
    this.activeRoute.queryParamMap.subscribe(params => {
      this.activeParams = { ...params['params'] };
      let data = this.router.url.split("?");
      this.activePath = data[0];
      console.log("this.router.url", this.router.url);
      if (this.router.url == '/home') {
        this.visibleStatus = false;
      } else {
        this.portId = this.activeRoute.queryParams["_value"].port_id
        this.visibleStatus = true;
      }
    });
  }
  //get assets by port for drop down menus.
  getAssets = () => {
    this.apiService._get(`get-assets/${this.portId}`).subscribe(response => {
      console.log("response => apiService", response)
      this.orthomosaiImage = response["ortho"]
      this.realityCapture = response["rc"]
      this.panoramic = response["panoramic"]
      this.groundView = response["ground"]

    })
  }


  // redirect to asset component
  routeAsset(name, id) {
    let _qp = this.activeParams;
    if (_qp['inspection_id']) {
      delete _qp['asset_id'];
      delete _qp['asset_name'];
      delete _qp['inspection_id'];
      delete _qp['inspection_name'];
    }
    _qp['asset_id'] = id;
    // _qp['asset_id'] = id;
    _qp['asset_name'] = name;
    console.log(_qp, name, id)


    this.router.navigate(['home/port/asset'], { queryParams: _qp })
  }
  //redirect to Potree component
  routeRc(name, id) {
    this.router.navigate(['home/port/potree'], { queryParams: { asset_name: name, asset_id: id }, queryParamsHandling: "merge" })
  }

  routePano(name, id) {
    this.router.navigate(['home/port/panoramic'], { queryParams: { asset_name: name, asset_id: id }, queryParamsHandling: "merge" })
  }

  routeGround(name, id) {
    this.router.navigate(['home/port/ground'], { queryParams: { asset_name: name, asset_id: id }, queryParamsHandling: "merge" })
  }

  getports = () => {
    this.apiService._get('get-ports').subscribe(response => {
      this.portDetails = response.data[0];
    }, error => {
      console.log("error", error);
    })
  }
  routeHome() {
    if (this.activePath !== '/view-report') {
      let _qp = this.activeParams;

      let queryPort = {
        port_id: this.portDetails["id"],
        port_name: this.portDetails["name"],
        isGround: true,
      }

      this.router.navigate(['home'])
    }

  }

  logout() {
    window.location.href = "/auth";
  }

  openChangePwdModal() {
    this.changePasswordForm.reset();
    this.changePasswordForm.updateValueAndValidity();
    this.disableFormButton = false;
    this.formSubmit = false;

    $("#changePasswordModal").modal("show");

  }

  closeModal() {
    $("#changePasswordModal").modal("hide");
  }
  submit() {

    this.formSubmit = true;
    this.disableFormButton = true;

   // validate form
    if (!this.changePasswordForm.valid) {
      this.disableFormButton = false;
      return this.toastr.error('invalid form');
    }

    // if (this.changePasswordForm.value.new_password != this.changePasswordForm.value.re_new_password) {
    //   return this.toastr.warning('Passwords does not match');
    // }
    
    this.apiService._post('change-password', this.changePasswordForm.value).subscribe(response => {


      // disable form loader
      this.formSubmit = false;
      this.disableFormButton = false;
      
      // validatte response status
      if (!response.status) return this.toastr.warning(response.message);

      // this.router.navigate(['/home'])
      this.changePasswordForm.reset();
      this.changePasswordForm.updateValueAndValidity();
      $("#changePasswordModal").modal("hide");
      this.toastr.success(response.message);

    });


  }

}


