import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { } from 'googlemaps';

import { ApiService, JwtService, UserService } from 'src/app/core';
import * as mapStyle from "src/assets/json/mapStyle.json";

//marker with label --  used for marker
var MarkerWithLabel = require('markerwithlabel')(google.maps);
import { PortField } from './port.class';
import { ToastrService } from 'ngx-toastr';
import MarkerClustererPlus from "@google/markerclustererplus"


// declare var MarkerClustererPlus: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('map', null) mapElement: any;
  map: google.maps.Map;
  portDetails: PortField[]
  activeParams;

  //notification url
  notificationUrl: string = "get-notifications?"
  //recentUpload url
  recentUploadsUrl: string = "recent-uploads?"
  //reportsummary url
  reportSummaryUrl: string = "report-summary?"
  //assessmentHighlight url
  assessmentHighlightsUrl: string = "inspection-details?"
  //progressHighlight url
  progressHighlightsUrl: string = "get-ports?"

  showLeftSideBar: Boolean = true

  constructor(
    private router: Router,
    private apiService: ApiService,
    private jwtService: JwtService,
    private userService: UserService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.activeParams = { ...params['params'] };
    })
  }

  ngOnInit() {

    // get coordinates from json file
    var coordinates: any = mapStyle['homeCoordinates']
    let mapProperties = {
      center: new google.maps.LatLng(coordinates.latitude, coordinates.longitude),
      zoom: 4,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      streetViewControl: false,
    };


    //providing styles for map
    var styledMapType: any = new google.maps.StyledMapType(mapStyle['homeMapStyle'])




    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);


    //setting up styles
    this.map.mapTypes.set('styled_map', styledMapType);

    //add styles to map
    // this.map.setMapTypeId('styled_map');

    this.apiService._get('get-ports').subscribe(response => {
      this.portDetails = response.data;
      this.populatemarker();
    })
  }



  routeSomeWhere(name, id) {
    this.router.navigate(['home/port'], { queryParams: { port_name: name, port_id: id } })
  }



  populatemarker() {
    var markerCluster = null;
    var that = this;
    var bounds = new google.maps.LatLngBounds();
    console.log("marker", this.portDetails)
    let markers = []
    this.portDetails.map(port => {
      var labelT = '';
      port.status === 'INSPECTED' ? labelT = `<div><div ${mapStyle['johore']}><img src=assets/images/m-yes.png><div  ${mapStyle['homeMarkerOverlayStyle']}>${port.name}</div></div></div>` : labelT = `<div><div ${mapStyle['johore']}><img src=assets/images/yellow.png><div ${mapStyle['homeMarkerOverlayStyle']}>${port.name}</div></div></div>`
      var image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
      var marker = new MarkerWithLabel({
        icon: {
          labelOrigin: new google.maps.Point(90, 48),
          url: "www.google.com"
        },
        position: new google.maps.LatLng(port.latitude, port.longitude),
        // raiseOnDrag: true,
        labelContent: labelT,
        labelAnchor: new google.maps.Point(48, 32),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1 },
        zIndex: 999999,
      })
      bounds.extend(marker.position);

      marker.id = port.id

      // marker.setMap(this.map)
      markers.push(marker)


      google.maps.event.addListener(marker, "click", function (e) {
        if (port.is_visible) {

          that.routeSomeWhere(port.name, port.id)
        }
        else {
          that.toastr.warning('UnAuthorized.')
        }
      });

    })

    markerCluster = new MarkerClustererPlus(this.map, markers, {
      styles: [{
        textColor: 'black',
        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
        height: 50,
        width: 50,
        anchorText: [22, 3]
      },
      {
        textColor: 'black',
        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
        height: 50,
        width: 50,
        anchorText: [22, 3]
      },
      {
        textColor: 'black',
        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
        height: 50,
        width: 50,
        anchorText: [22, 3]
      }]
    });

    // this.map.fitBounds(bounds);

    // Info window for marker cluster icon hover
    var infoWindow = new google.maps.InfoWindow();
    var _this = this;
    google.maps.event.addListener(markerCluster, 'mouseover', function (cluster) {

      let labelData = []
      for (const clust of cluster.markers_) {
        var str = clust.labelContent
        var mySubString = str.substring(
          str.lastIndexOf('">') + 1,
          str.lastIndexOf("</")
        );
        mySubString = mySubString.replace('>', '<br/>\u2022 ')
        mySubString = `<a id=${clust.id} class='infowindowCluster' > ${mySubString} </a>`
        labelData.push(mySubString)
      }
      var labels = labelData.toString().replace(/,/g, '');
      var info = new google.maps.MVCObject;
      info.set('position', cluster.center_);
      infoWindow.close();
      infoWindow.setContent(labels);
      infoWindow.open(this.map, info);

      google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
        for (const clust of cluster.markers_) {
          document.getElementById(clust.id).addEventListener('click', () => {
            var str = clust.labelContent
            var mySubString = str.substring(
              str.lastIndexOf('">') + 1,
              str.lastIndexOf("</")
            );
            mySubString = mySubString.replace('>', '')
            mySubString = mySubString.replace('</div></div>', '')
            _this.routeSomeWhere(mySubString, clust.id);
          });
        }
      });
    });

    // google.maps.event.addListener(markerCluster, 'mouseout', function() {
    //   infoWindow.close();
    // });

    google.maps.event.addListener(markerCluster, 'clusterclick', function () {
      infoWindow.close();
    });
  }
}