import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'asset-mang-pano-view',
  templateUrl: './pano-view.component.html',
  styleUrls: ['./pano-view.component.css']
})
export class PanoViewComponent implements OnInit {

  // Pano form
  panoForm: FormGroup;
  submitted: Boolean = false;

  // toggle enable / disable fields
  isDisabled: Boolean = false;

  editButtonClicked: Boolean = false;

  // contains all pano data based on port
  panoData: any;

  portId: null

  // Ports
  ports: Array<Object> = [];

  // to hold previous value
  previousValue: Object = {};


  constructor( private apiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit() {

    // Form init
    this.panoForm = new FormGroup({
      id: new FormControl(null),
      port: new FormControl(null, []),
      name: new FormControl({value: null, disabled: true}),
      // latitude: new FormControl(null, [Validators.required]),
      // longitude: new FormControl(null, [Validators.required]),
      altitude: new FormControl(null, [Validators.pattern(/^[-+]?[0-9]+\.[0-9]+$/)]),
      url_file: new FormControl(null, [CustomValidators.url])
    })
  }

  // retun form control
  get panoFormControl() {
    return this.panoForm.controls;
  }

  async submitPanoView() {
    this.submitted = true; 
    
    this.panoForm.value['port'] = this.portId;
    this.panoForm.value['name'] = 'Pano View';
    console.log('panoForm', this.panoForm.value);

    // validate form
    if (!this.panoForm.valid) return this.toastr.error('Invalid form, some details missing');

    // Post form
    this.apiService._post(`create-panoramic`, this.panoForm.value).subscribe(response => {

      // validatte response status
      if (!response['status']) return this.toastr.error('Error in saving form');
      
      this.toastr.success('Successfully saved Pano details');
      this.editButtonClicked = false;
      this.isDisabled = true;

      this.submitted = false;
       
    }, error => {
      console.log("Pano Error", error);
    });
  }

  // get pano view data based on port
  getPanoData() {
    this.apiService._get(`get-pano-by-port/${this.portId}`).subscribe(response => {

      if (!response['status']) return this.toastr.error('Some error in getting data');
      console.warn("pano", response);
      this.panoData = response['asset']
      if (!this.panoData) this.panoForm.reset()
      else this.panoForm.patchValue(this.panoData)
    })
  }

  updatePanoView() {
    this.editButtonClicked = !this.editButtonClicked;
    this.isDisabled = !this.isDisabled;

    if (this.editButtonClicked) this.previousValue = this.panoForm.value;

  }
  cancelEdit() {
    this.panoForm.reset();
    this.panoForm.patchValue(this.previousValue);
  }
}
