import { Component, OnInit, SimpleChanges, Input } from '@angular/core';

// mini map
import OlMap from 'ol/Map';
import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import OlLayerVector from 'ol/layer/Vector';
import * as OlControl from "ol/control";
import OlOSM from "ol/source/OSM";
import OlVector from "ol/source/Vector";
import OlView from 'ol/View';
import * as OlProj from 'ol/proj';
import * as Interaction from "ol/interaction";
import * as ol from "ol";
import Point from 'ol/geom/Point';
import { Style, Fill, Stroke, RegularShape } from "ol/style";
import { LocationTrackingService } from 'src/app/core/services/location-tracking.service';

interface IAssetDetail {
  assetUrl: string,
  maptilerCoordinate: Array<any>,
  zoom: Number,
  coordinate: Array<any>
}

@Component({
  selector: 'app-overall-site-view',
  templateUrl: './overall-site-view.component.html',
  styleUrls: ['./overall-site-view.component.css']
})
export class OverallSiteViewComponent implements OnInit {

  // input coordinate
  @Input() incomingCoordinate: Array<any>;
  @Input() incomingAssetDetail: Object;

  // toggle card body
  showCardBody: Boolean = true;

  // mini map
  map: OlMap;
  sourceXYZ: OlXYZ;
  layer: OlTileLayer;
  view: OlView;
  draw: any;
  sourceOne: any;

  assetDetail: IAssetDetail = {
    assetUrl: '',
    maptilerCoordinate: [],
    zoom: 0,
    coordinate: []
  }

  constructor() { }

  async ngOnInit() {
    setTimeout(() => {
      if(this.map) this.map.updateSize()
    }, 500);
   }

  async ngOnChanges(changes: SimpleChanges) {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (changes['incomingAssetDetail']) {
      if (changes['incomingAssetDetail'].currentValue && changes['incomingAssetDetail'].currentValue['maptiler_url']) {
        this.assetDetail.assetUrl = changes['incomingAssetDetail'].currentValue['maptiler_url']
        this.assetDetail.maptilerCoordinate = changes['incomingAssetDetail'].currentValue['maptiler_coordinates']
        this.assetDetail.zoom = changes['incomingAssetDetail'].currentValue['default_zoom'] - 1
        this.assetDetail.coordinate = changes['incomingAssetDetail'].currentValue['maptiler_lat_lng']

        // load maptailer
        await this.loadMapTiler();
      }
      // init point
      await this.drawPoint();

    }

    if (changes['incomingCoordinate'] && this.map && this.map.getLayers().getArray() && this.map.getLayers().getArray().length > 0) {
      // this.map.getLayers().getArray()[3].getSource().getFeatures()[0].getGeometry().setCoordinates(OlProj.transform([changes['incomingCoordinate'].currentValue[0], changes['incomingCoordinate'].currentValue[1]], 'EPSG:4326', 'EPSG:3857'))
      this.changePointCoordinate(changes['incomingCoordinate'].currentValue)
    }
  }

  async changePointCoordinate(incomingCoordinate) {
    this.map.getLayers().getArray()[3].getSource().getFeatures()[0].getGeometry().setCoordinates(OlProj.transform([incomingCoordinate[0], incomingCoordinate[1]], 'EPSG:4326', 'EPSG:3857'))
  }

  
  // check DOM
  checkDomElementAndDeleteExtra() {
    const elements = document.getElementById('map-track').getElementsByClassName('ol-viewport')
    for (let i = 0; i < elements.length - 1; i++) {
      elements[i].remove()
    }
  }

  // get dom - map
  getDom(): any {
    return this.map.getTargetElement();
  }

  // load map
  loadMapTiler = async () => {
    try {

      console.log("load map tiler", this.assetDetail)
      var mapExtent = OlProj.transformExtent(this.assetDetail.maptilerCoordinate, 'EPSG:4326', 'EPSG:3857');

      //overlay setup
      var source = new OlXYZ({
        url: `${this.assetDetail.assetUrl}{z}/{x}/{y}.png`,
        // url: this.assetDetails.maptiler_url,
        tilePixelRatio: 1.00000000,
        minZoom: this.assetDetail.zoom,
        maxZoom: this.assetDetail.zoom,
        crossOrigin: "Anonymous"

      })

      //instance for source vector
      this.sourceOne = new OlVector({
        wrapX: false,
      });


      //instance for layer vector
      var vectorLayer = new OlLayerVector({
        source: this.sourceOne
      });

      // instance for tile layer, to be included in map
      var layer = new OlTileLayer({
        extent: mapExtent,
        source: source,
        crossOrigin: "Anonymous"
      });

      // layer for Openstreetmap
      var osm = new OlTileLayer({
        source: new OlOSM()
      });


      //creating instance for map, and add all vector layer and source layer
      this.map = new OlMap({
        target: 'map-track',
        interactions: Interaction.defaults({ mouseWheelZoom: false }),
        controls: OlControl.defaults({ attribution: false }),
        layers: [
          osm,
          layer,
          vectorLayer
        ],
        view: new OlView({
          //  map center coordinates
          center: OlProj.fromLonLat(this.assetDetail.coordinate),
          zoom: this.assetDetail.zoom
        })
      });

      console.log("map track", this.map)
      this.checkDomElementAndDeleteExtra()
      // await this.drawPoint()
    } catch (error) {
      //console.log("404 on maptiles")
    }
    console.warn(this.map)

  }


  // set point
  async drawPoint() {

    if (this.map) {
      var point = new Point(
        OlProj.transform([-3.8091610674591068, 51.58145326945865], 'EPSG:4326', 'EPSG:3857')
      );
      var pointFeature = new ol.Feature(point);
      pointFeature.setStyle(await this.styleFunctionPoint(''))

      var vectorSource = new OlVector({
        projection: 'EPSG:4326'
      });
      vectorSource.addFeatures([pointFeature]);
      var vectorLayer = new OlLayerVector({
        source: vectorSource
      });
      console.log("vector", vectorLayer)
      this.map.addLayer(vectorLayer)
    }

  }


  // style
  async styleFunctionPoint(textToDisplay) {
    return [
      new Style({
        image: new RegularShape({
          fill: new Fill({ color: 'red' }),
          stroke: new Stroke({ color: 'red', width: 2 }),
          points: 4,
          radius: 10,
          radius2: 0,
          angle: Math.PI / 2
        })

      })
    ];
  }

}
