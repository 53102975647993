import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'ng2-validation';
import { GroundViewComponent } from '../ground-view/ground-view.component';

@Component({
  selector: 'asset-mang-top-view',
  templateUrl: './top-view.component.html',
  styleUrls: ['./top-view.component.css']
})
export class TopViewComponent implements OnInit {

  // Top form
  topForm: FormGroup;
  submitted: Boolean = false;

  // toggle enable / disable fields
  isDisabled: Boolean = false;

  editButtonClicked: Boolean = false;

  // contains all data based on port Id
  topData: any

  portId: null

  // user details
  userDetails: Object;


  // to hold previous value
  previousValue: Object = {};

  @ViewChild(GroundViewComponent, null) groundViewComponent: GroundViewComponent;

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private toastr: ToastrService) {
    // get user details form session
    this.userDetails = this.userService.getSession();
    console.warn('user', this.userDetails)
  }

  ngOnInit() {

    // Form init
    this.topForm = new FormGroup({
      id: new FormControl(null),
      port: new FormControl(null, []),
      name: new FormControl({ value: null, disabled: true }),
      // latitude: new FormControl(null, [Validators.required]),
      // longitude: new FormControl(null, [Validators.required]),
      // assetUrl: new FormControl(null, [Validators.required]),
      // imageUrl: new FormControl(null, [Validators.required]),
      // image_coordinates: new FormControl([], [Validators.required]),
      added_by: new FormControl(null, []),
      maptiler_url: new FormControl(null, [Validators.required, CustomValidators.url]),
      maptiler_coordinates: new FormControl([], [Validators.required, Validators.pattern(/^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*$/)]),
      maptiler_lat_lng: new FormControl([], [Validators.required, Validators.pattern(/^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*$/)]),
      maptiler_min_zoom: new FormControl(null, [Validators.required, CustomValidators.digits]),
      maptiler_max_zoom: new FormControl(null, [Validators.required, CustomValidators.digits]),
      // overall_asset_view_image: new FormControl(null, [Validators.required]),
      gsd: new FormControl(null, [Validators.required, Validators.pattern(/^[-+]?[0-9]+\.[0-9]+$/)]),
      captured_date: new FormControl(null, []),
      default_zoom: new FormControl(null, [Validators.required, CustomValidators.digits]),
      stand_shots: new FormControl([], [])
    })

  }

  // retun form control
  get topFormControl() {
    return this.topForm.controls;
  }

  log() {
    console.log(this.topForm)
  }

  async submitTopView() {
    console.warn("submit");
    
    this.topForm.value['port'] = this.portId;
    this.topForm.value['name'] = 'Top View';
    this.topForm.value['stand_shots'] = [];

    if (this.topForm.controls['captured_date'].value) this.topForm.value['captured_date'] = this.formatdate(this.topForm.controls['captured_date'].value)

    this.submitted = true;

    this.topForm.value['maptiler_lat_lng'] = this.splitArray(this.topForm.value['maptiler_lat_lng'])
    this.topForm.value['maptiler_coordinates'] = this.splitArray(this.topForm.value['maptiler_coordinates'])

    this.topForm.value['added_by'] = this.userDetails['id']

    console.warn("result", this.topForm.value);
    console.warn("res", this.topForm);

    // validate form
    if (!this.topForm.valid) return this.toastr.error('Invalid form, some details missing');

    // Post form
    this.apiService._post(`create-assets`, this.topForm.value).subscribe(response => {

      // validatte response status
      if (!response['status']) return this.toastr.error('Error in saving form');

      this.toastr.success('Successfully saved Asset details');
      this.editButtonClicked = false;
      this.isDisabled = true;

      this.submitted = false;

    }, error => {
      console.log("Asset Error", error);
    });
  }

  // removing string from values
  splitArray(data) {
    console.warn("splitArray", data);
    if (typeof (data) === 'string') {
      var resultArray = []
      let values = data.split(',')
      for (const value of values) {
        resultArray.push(parseFloat(value))
      }
      return resultArray;
    }
    return;
  }

  // format data mm/dd/yyyy format
  formatdate(data) {
    if (typeof (data) === 'object') {
      var today = data;
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }
      today = mm + '/' + dd + '/' + yyyy;
      return today;
    }
    return;
  }

  // get top view data based on port
  getTopData() {
    this.apiService._get(`get-asset-by-port/${this.portId}`).subscribe(response => {

      if (!response['status']) return this.toastr.error('Some error in getting data');
      console.warn("top", response);

      this.topData = response['asset']
      if (!this.topData) this.topForm.reset()
      else this.topForm.patchValue(this.topData)
    })
  }

  updateTopView() {
    this.editButtonClicked = !this.editButtonClicked;
    this.isDisabled = !this.isDisabled;

    if (this.editButtonClicked) this.previousValue = this.topForm.value;

  }

  cancelEdit() {
    this.topForm.reset();
    this.topForm.patchValue(this.previousValue);
  }

}
