import { Component, OnInit, Input } from "@angular/core";
// import interact from 'interactjs';

// service
import { InternalCommService } from "src/app/core/services";
import { ImageViewerService } from 'src/app/core/services/image-viewer.service';
import * as validateUrl from 'valid-url';

@Component({
  selector: "app-image-view",
  templateUrl: "./image-view.component.html",
  styleUrls: ["./image-view.component.css"]
})
export class ImageViewComponent implements OnInit {
  // image data
  imageData: Object = {};

  // image type
  imageListConfig;
  imageAsUrl: Boolean = true;


  imageViewerConfig;
  // image loader
  loader: Boolean = false;
  title;
  // toogle fullscreen on window
  toogleFullScreen: Boolean = false;



  //Objects
  @Input() imageViewerData: Object;
  
  // imageViewerShow: boolean = false
  // imageViewerData: string = null
  // imageViewerHeader: string = null
  // imageViewerType: string = null
  // imageViewerIndex: any = null


  constructor(private internalCommService: InternalCommService, private imageViewerService: ImageViewerService) {
    this.imageViewerConfig = {
      zoomFactor: 0.1,
      containerBackgroundColor: '#00000000',
      wheelZoom: true,
      btnIcons: {
        zoomIn: "fa fa-plus",
        zoomOut: "fa fa-minus",
        rotateClockwise: "fa fa-repeat",
        rotateCounterClockwise: "fa fa-undo",
        next: "fa fa-arrow-right",
        prev: "fa fa-arrow-left",
        fullscreen: "fa fa-expand"
      },
      btnShow: {
        zoomIn: true,
        zoomOut: true,
        rotateClockwise: false,
        rotateCounterClockwise: true
      }
    };
    this.title = 'Image Viewer';
  }

  ngOnInit() {

    // get image data from service
    // this.internalCommService.currentData.subscribe(data => {

    //   console.log("image view data",data);
    //   this.loader = true;
    //   this.imageData = data["imageView"];
    //   if (this.imageData) {
    //     if (this.imageData && this.imageData['rawImage']) { this.imageData['url'] = this.imageData['rawImage']; this.title = 'Raw Image Viewer' }

    //     if (this.imageData) {
    //       this.imageListConfig = this.internalCommService.getdata(
    //         "imageListConfig"
    //       );
    //       this.imageAsUrl =
    //         data["imageView"] && data["imageView"]["url"] ? true : false;
    //     }
    //   }
    // });


    // this.imageViewerService.imageViewerData.subscribe(response => {
    //   console.log(response, "data")
    //   if (response && response['imageViewer'] && response['imageViewer']['show']) {
    //     console.log("response", response)
    //     this.imageViewerShow = response['imageViewer']['show']
    //     this.imageViewerData = this.validateImageUrl(response['imageViewer']['url'])
    //     console.log("image viewer index", this.imageViewerIndex)
    //     console.log("image viewer index", this.internalCommService.getdata('defectWizardForm'))
    //     const defectWizardForm = this.internalCommService.getdata('defectWizardForm')

    //     if (defectWizardForm) {
    //       this.imageViewerHeader = `Defect number - ${defectWizardForm['defectIndex'] + 1}`
    //     } else {

    //       this.imageViewerHeader = response['imageViewer']['headerLabel']
    //     }

    //     console.log(this.imageViewerData, this.imageViewerShow, this.imageViewerHeader)
    //   }
      
    // })
  }

  onDragStart(evt) {
    console.log(evt);
    if (evt.dataTransfer && evt.dataTransfer.setDragImage) {
      let img = new Image();
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
      evt.dataTransfer.setDragImage(img, 0, 0);
    }

  }
  onloadedData(evt) {

    console.log(evt);
  }

  clearImage() {
    this.imageViewerData['image'] = null;
    this.imageViewerData['show'] = false;
    this.imageViewerData['header'] = ""
    // this.imageViewerIndex = null
    // this.internalCommService.deleteData("imageView");
  }

  ngOnDestroy() {
    this.clearImage()
  }

  handleEvent(event) {
    console.log(event)
  }

  validateImageUrl(url) {
    let imgSrc = '../../../../assets/images/no-image.jpg';
    if (validateUrl.isUri(url)) {
      imgSrc = url;
    }
    return imgSrc;
  }


}
